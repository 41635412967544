import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody } from "botbit-ui-components";
import Icons from "./Icons/Icons";
import { useSelector } from "react-redux";
import { getBackgroundProcess } from "../../../../api/googleBusinessProfileApi";
import { MDBProgress } from "mdbreact";
import withEventTracking, { trackers } from "../../../../config/withEventTracking";
import "../../styles.css";

const ModalLocalInsignia = ({
  isOpen,
  toggle,
  toggleFinish,
  applyInsignia,
  bonus,
  bonus2,
  bonus3,
  t,
  totalNumberOfPlaces,
  setSummary,
  summary,
  setBonusPoints,
  trackEvent,
  setScoreBeforeApply,
  setScoreAfterApply
}) => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const selectedCompany = useSelector(
    (state) => state.application.selectedCompany
  );

  const checkBackgroundProcess = async (processId) => {
    try {
      const response = await getBackgroundProcess(processId);
      console.log("Background process response:", response);

      if (response.data.data) {
        const status = response.data.data.state;
        const currentProgress = response.data.data.progress || 0;

        setProgress((prev) => Math.max(prev, currentProgress));
        console.log("Status:", status);

        if (status === "FINISHED_OK") {
          if (response.data.data.results && response.data.data.results.logInsigniaApplied) {
            const scoreBeforeApply = response.data.data.results.logInsigniaApplied.scoreBeforeApply;
            const scoreAfterApply = response.data.data.results.logInsigniaApplied.scoreAfterApply;
            const difference = scoreAfterApply - scoreBeforeApply;
            console.log("Difference in points:", difference);
            setBonusPoints(difference);
            setScoreBeforeApply(scoreBeforeApply);
            setScoreAfterApply(scoreAfterApply);
          }
          setSummary({ ...summary, insignia: false });
          setProgress(100);
          setTimeout(() => {
            console.log("Insignia was applied: ", true);
            setLoading(false);
            setProgress(0);
            toggle();
            toggleFinish();
            // Evento de mixpanel GBP_audit_local_insignia_clone
            trackEvent("GBP_audit_local_insignia_clone", {}, false, [
              trackers.MIXPANEL,
            ]);
          }, 1000);
        } else if (status === "RUNNING") {
          setTimeout(() => checkBackgroundProcess(processId), 2000);
        } else if (status === "FINISHED_ERROR") {
          setProgress(100);
          setTimeout(() => {
            setLoading(false);
            toggle();
          }, 1000);
        }
      }
    } catch (error) {
      console.error("Error checking process status:", error);
      setLoading(false);
    }
  };

  const handleFinish = async () => {
    if (totalNumberOfPlaces === 0) {
      return;
    }

    setLoading(true);
    try {
      const response = await applyInsignia(selectedCompany.id);
      console.log("Apply insignia response:", response);
      
      if (response.data.data && response.data.data.id) {
        checkBackgroundProcess(response.data.data.id);
      }
    } catch (error) {
      console.error("Error applying insignia:", error);
      setLoading(false);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} className="modal-insignia">
        <ModalHeader toggle={toggle} className="pb-0"></ModalHeader>
        <ModalBody className="modal-insignia-content pt-0">
          {loading ? (
            <div className="modal-insignia-container-spinner-content mb-2">
              <h3 className="modal-insignia-spinner-title">
                <span className="font-bold">
                  {t("modalLocalInsignia.loadingTextBold")}
                </span>
                {t("modalLocalInsignia.loadingText")}
                <span className="font-bold">
                  {t("modalLocalInsignia.loadingTextBold2")}
                </span>
              </h3>
               <div className="gbp-container p-0 w-100 d-flex justify-content-center mt-4">
              <MDBProgress
                value={progress}
                min={0}
                max={100}
                className="w-75 progress-bar-container"
                animated={true}
                preloader={true}
                barClassName="color-progress-bar"
              >
                <span className="p-1 font-bold">{progress}%
                </span>
                </MDBProgress>
              </div>
            </div>
          ) : (
            <>
              <div className="modal-insignia-content-row mb-2">
                <div className="modal-insignia-container-icon">
                  {Icons.svgLocalInsigniaIcon({ width: 20, height: 20 })}
                </div>
                <h3 className="modal-insignia-title">
                  {t("modalLocalInsignia.title")}
                </h3>
              </div>
              <p className="modal-insignia-subtitle mb-4">
                {t("modalLocalInsignia.subtitle")}
                <span className="font-bold">
                  {`${t("modalLocalInsignia.subtitleBold")} (${totalNumberOfPlaces}) `}
                </span>
                {t("modalLocalInsignia.subtitle2")}
              </p>
              <div className="modal-insignia-column mb-4">
                <div className="modal-insignia-row">
                  <p className="modal-insignia-text">
                    {t("modalLocalInsignia.text")}
                  </p>
                  <div className="modal-insignia-bonus-row">
                    <p className="modal-insignia-bonus">{bonus}</p>
                    <div className="mb-1">
                      {Icons.svgModalFinish({ width: 20, height: 20 })}
                    </div>
                  </div>
                </div>
                <div className="modal-insignia-row">
                  <p className="modal-insignia-text">
                    {t("modalLocalInsignia.text2")}
                  </p>
                  <div className="modal-insignia-bonus-row">
                    <p className="modal-insignia-bonus">{bonus2}</p>
                    <div className="mb-1">
                      {Icons.svgModalFinish({ width: 20, height: 20 })}
                    </div>
                  </div>
                </div>
                <div className="modal-insignia-row">
                  <p className="modal-insignia-text">
                    {t("modalLocalInsignia.text3")}
                  </p>
                  <div className="modal-insignia-bonus-row">
                    <p className="modal-insignia-bonus">{bonus3}</p>
                    <div className="mb-1">
                      {Icons.svgModalFinish({ width: 20, height: 20 })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-group-buttons">
                <button
                  onClick={toggle}
                  className="modal-insignia-outline-button"
                >
                  {t("modalLocalInsignia.cancelTextBtn")}
                </button>
                <button
                  onClick={handleFinish}
                  className="modal-insignia-button"
                >
                  {t("modalLocalInsignia.applyTextBtn")}
                </button>
              </div>
              <p className="modal-danger-message">
                {t("modalLocalInsignia.warningTextNormal")}
                <span className="modal-danger-message-bold">
                  {t("modalLocalInsignia.warningTextBold")}
                </span>
                {t("modalLocalInsignia.warningTextNormal2")}
              </p>
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default withEventTracking(ModalLocalInsignia);
import React, { useState, useEffect } from 'react';
import './LocalSeoStoreChart.css';
import { Line } from 'react-chartjs-2';
import { getLocalSeoStoreChartData } from '../../../api/dashboardV2Api';
import translate from "../../../config/translations/translate";

const LocalSeoStoreChart = ({store, selectedPeriod, t}) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [{
      label: t('noDataAvailable'),
      data: [],
      fill: false,
      borderColor: '#cccccc',
      tension: 0.1
    }]
  });
  


  useEffect(() => {
    if (!store) return;
    
    const fetchData = async () => {
      try {
        const response = await getLocalSeoStoreChartData(store.id, selectedPeriod);
        
        // Si la respuesta es exitosa pero no hay datos, o llegan vacios
        if (!response || !response.data || !response.data.data || !response.data.data.rankDates || response.data.data.rankDates.length === 0) {
          setChartData({
            labels: [],
            datasets: [{
              label: t('noDataAvailable'),
              data: [],
              fill: false,
              borderColor: '#cccccc',
              tension: 0.1
            }]
          });
          return;
        }

        const { rankDates, keywordsRankingByTimePeriod, intervalPeriod } = response.data.data;
        
        // si es mayor a 1 mes devuelvo el mes y el año en el label del eje x
        const formatDate = (timestamp) => {
          const date = new Date(timestamp);
          if (['LAST_3_MONTHS', 'LAST_6_MONTHS', 'LAST_12_MONTHS', 'THIS_YEAR'].includes(intervalPeriod)) {
            const monthNames = [
              'february', 'march', 'april', 'may', 'june', 'july',
              'august', 'september', 'october', 'november', 'december', 'january'
            ];
            const monthKey = monthNames[date.getMonth()];
            const month = t(`months.${monthKey}`);
            const year = date.getFullYear();
            
            return month.charAt(0).toUpperCase() + month.slice(1) + ' ' + year;
          }
          
          // Para fechas cortas
          return date.toLocaleDateString(undefined, { 
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          });
        };

        const formattedData = {
          labels: rankDates.map(timestamp => formatDate(timestamp)),
          datasets: Object.entries(keywordsRankingByTimePeriod).map(([keyword, rankings], index) => ({
            label: keyword === "-1" ? t('averageRanking') : keyword,
            data: rankings,
            fill: false,
            borderColor: `hsl(${index * 50}, 70%, 50%)`,
            tension: 0.1
          }))
        };

        setChartData(formattedData);
        console.log('Datos formateados:', formattedData);
      } catch (error) {
        console.error('Error fetching chart data:', error.message);
        //en caso de error, tambien grafico vacio.
        setChartData({
          labels: [],
          datasets: [{
            label: t('noDataAvailable'),
            data: [],
            fill: false,
            borderColor: '#cccccc',
            tension: 0.1
          }]
        });
      }
    };

    fetchData();
  }, [store, selectedPeriod, t]);

  if (!store) {
    return (
      <div className="local-seo-chart-wrapper">
        <h2>{t('noLocationSelected')}</h2>
      </div>
    );
  }

  const options = {
    responsive: true,
    elements: {
      point: {
        radius: 0,
      },
      line: {
        tension: 0.1
      }
    },
    scales: {
      yAxes: [{
        gridLines: {
          display: false
        },
        scaleLabel: {
          display: true,
          fontStyle: 'bold',
          fontSize: 12,
          fontColor: '#000000',
          fontFamily: 'Open Sans'
        },
        ticks: {
          reverse: true,
          min: 1,
          max: 21,
          stepSize: 3,
          fontSize: 12,
          fontColor: '#000000',
          fontFamily: 'Open Sans'
        }
      }],
      xAxes: [{
        gridLines: {
          display: false
        },
        scaleLabel: {
          display: true,
          fontStyle: 'bold',
          fontSize: 12,
          fontColor: '#000000',
          fontFamily: 'Open Sans'
        },
        ticks: {
          fontStyle: 'bold',
          fontSize: 12,
          fontColor: '#000000',
          fontFamily: 'Open Sans'
        }
      }]
    },
    legend: {
      position: 'top',
      labels: {
        boxWidth: 15,      
        padding: 15,        
        fontSize: 14,         
        fontColor: '#969696',   
        fontFamily: 'Open Sans',
        
      }
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          boxWidth: 15,      
          padding: 15,        
          fontSize: 14,         
          fontColor: '#969696',   
          fontFamily: 'Open Sans',
        }
      },
      // Agregar configuración para cuando no hay datos
      noData: {
        text: t('noDataAvailable'),
        align: 'center',
        verticalAlign: 'middle',
        style: {
          fontSize: '16px',
          color: '#666666'
        }
      }
    },
  };

  return (
    <div className="local-seo-chart-wrapper">
      <div className="local-seo-chart-container">
        <Line options={options} data={chartData} />
      </div>
    </div>
  );
};

LocalSeoStoreChart.translations = {
  noLocationSelected: {
    
    es: "Por favor, seleccione una ubicación para ver el gráfico",
    en: "Please select a location to view the chart",
 
  },
  averageRanking: {
    es: "ranking promedio",
    en: "average ranking",
  },
  months: {
    december: {
    
      es: "diciembre",
      en: "December",
     
    },
    november: {
    
      es: "noviembre",
      en: "November",
 
    },
    october: {
 
      es: "octubre",
      en: "October",
 
    },
    september: {
      
      es: "septiembre",
      en: "September",
      
    },
    august: {
      
      es: "agosto",
      en: "August",
    
    },
    july: {
     
      es: "julio",
      en: "July",
   
    },
    june: {

      es: "junio",
      en: "June",
    
    },
    may: {

      es: "mayo",
      en: "May",

    },
    april: {
   
      es: "abril",
      en: "April",
   
    },
    march: {
    
      es: "marzo",
      en: "March",
     
    },
    february: {
  
      es: "febrero",
      en: "February",
     
    },
    january: {
      
      es: "enero",
      en: "January",
     
    },
    noDataAvailable: {
      es: "No hay datos disponibles para esta ubicación",
      en: "No data available for this location",
    },
  }
};

const TranslatedLocalSeoStoreChart = translate(LocalSeoStoreChart);
export { TranslatedLocalSeoStoreChart as LocalSeoStoreChartComponent };
export default TranslatedLocalSeoStoreChart;



import React from 'react';
import { LocalSeoStoreChartComponent } from '../localSeoStoreChart/LocalSeoStoreChart';
import './LocalSeoStoreView.scss';
import { 
    GoogleMapsIcon 
} from '../localSeoStoreChart/LocalSeoStoreChartIcons';
import translate from '../../../config/translations/translate';

const LocalSeoStoreViewComponent = ({store, selectedPeriod, t}) => {
    return (
        <div className="local-seo-store-view-container">
            <div className="local-seo-chart-header-container">
                <div className="local-seo-icon-and-subtitle">
                    <div className="google-maps-icon">
                        <GoogleMapsIcon />
                    </div>
                    <h4 className="google-maps-subtitle">{t('googleMapsRanking')}</h4>
                </div>
            </div>
            <LocalSeoStoreChartComponent store={store} selectedPeriod={selectedPeriod} />
        </div>
    )
}

LocalSeoStoreViewComponent.translations = {
    googleMapsRanking: {
        es: "Ranking en Google Maps",
        en: "Google Maps Ranking"
    }
};

export const LocalSeoStoreView = translate(LocalSeoStoreViewComponent);
import React from 'react';
import ReputationCharts from '../reputationGraph/ReputationCharts';
import './ReputationStoreView.scss';
import translate from '../../../config/translations/translate';

const ReputationStoreViewComponent = ({store, selectedPeriod, t}) => {
    return (
        <div className="local-seo-store-view-container">
            {store && <ReputationCharts storeId={store.id} selectedPeriod={selectedPeriod}/>}
        </div>
    )
}


export const ReputationStoreView = translate(ReputationStoreViewComponent);
import axiosClient from "./axiosClient";
import { processParameters } from "./utils/utils";

export const getReviewsRankingData = (companyId, timePeriod = 'LAST_7_DAYS', reputationOrderType = 'TOTAL_REVIEWS_DESC', pageNumber = 0, pageSize = 6) => {
    const params = processParameters(
        [timePeriod, "timePeriod"],
        [reputationOrderType, "reputationOrderType"],
        [pageNumber, "pageNumber"],
        [pageSize, "pageSize"]
    );
    return axiosClient.get(`/dashboard-v2/reviews/company/${companyId}?${params}`);
}

export const getGlobalMetricsKpisByCompanyId = (companyId, intervalPeriod = 'LAST_7_DAYS') => {
    const params = processParameters(
        [intervalPeriod, "period"]
    );
    return axiosClient.get(`/metrics/gbp/global/companies/${companyId}?${params}`);
}

export const getLocalSEOTableData = (companyId, pageNumber = 0, timePeriod = 'LAST_7_DAYS', pageSize = 5) => {
    const params = processParameters(
        [pageNumber, "page"],
        [timePeriod, "period"],
        [pageSize, "size"]
    );
    return axiosClient.get(`/metrics/gbp/ranking/companies/${companyId}?${params}`)
}

export const getReviewsInsightsEvolutionGoogle = (storeId = 2833, intervalPeriod = 'LAST_6_MONTHS') => {
    return axiosClient.get(`/feedback/reviews/insights/evolution/google?storeId=${storeId}&intervalPeriod=${intervalPeriod}`);
}

export const getReviewsDataByStore = (storeId, timePeriod = 'LAST_7_DAYS') => {
    return axiosClient.get(`/dashboard-v2/reviews/store/${storeId}?timePeriod=${timePeriod}`);
}

export const getLocalSeoStoreChartData = (socialLocationId, timePeriod = 'LAST_7_DAYS') => {
    const params = processParameters(
        [timePeriod, "timePeriod"]
    );
    return axiosClient.get(`/dashboard-v2/google-maps/location/${socialLocationId}/ranking-list-by-period?${params}`);
}

export const getGlobalMetricsKpisByStoreId = (storeId, intervalPeriod = 'LAST_7_DAYS') => {
    const params = processParameters(
        [intervalPeriod, "period"]
    );
    return axiosClient.get(`/metrics/gbp/global/stores/${storeId}?${params}`);
}

export const getActionablesSideBarData = (companyId, locale) => {
    const params = processParameters(
        [locale, "locale"]
    );
    return axiosClient.get(`/dashboard/actionables/companies/${companyId}?${params}`);
}

import React, { useState, useEffect } from 'react';
import { UxGlobalMetrics } from 'botbit-ui-components'
import { getGlobalMetricsKpisByCompanyId, getGlobalMetricsKpisByStoreId } from '../../../api/dashboardV2Api';
import { GoogleKpiIcon } from './globalMetricsIcons';
import { formatNumber } from '../../../commons/utils/formats';

const iconExampleGoogle = (<svg width="16" height="33" viewBox="0 0 16 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7292 7.95207L2.64133 22.0898H6.57075C7.34503 22.0898 7.92844 22.7808 7.78451 23.5274L6.897 28.131L13.2836 17.9143H9.3304C8.50217 17.9143 7.90857 17.1302 8.14772 16.352L10.7292 7.95207ZM12.1145 1.67858C12.8371 0.415381 14.8005 1.23513 14.3743 2.62204L10.2542 16.0286H14.5097C15.4753 16.0286 16.0675 17.067 15.5626 17.8747L6.58103 32.2427C5.86356 33.3905 4.05924 32.7066 4.3143 31.3835L5.74246 23.9755H1.46902C0.524689 23.9755 -0.0704143 22.9779 0.391866 22.1698L12.1145 1.67858Z" fill="#1276E6"/>
</svg>)



// const metricsExample = [
// 	{
// 		title: 'Ranking Promedio Google Maps',
// 		score: '3.5',
// 		icon: iconExampleGoogle,
// 		tooltipContent: (
// 			<div>
// 				<p className='mb-0'>Mide la <span className='font-bold'>posición promedio</span> de todos tus locales, cuando los consumidores buscan a 1 km de radio de cada sucursal en Google Maps</p>
// 			</div>
// 		),
// 	},
// 	{
// 		title: 'Promedio Score Reviews',
// 		score: '4.3',
// 		icon: iconExampleGoogle,
// 		tooltipContent: (
// 			<div>
// 				<p className='mb-0'>Mide el <span className='font-bold'>promedio de estrellas de los Reviews</span> de todos los locales en el período seleccionado</p>
// 			</div>
// 		),
// 	},
// 	{
// 		title: 'Engagement Rate Google Business Profile',
// 		score: '57%',
// 		icon: iconExampleGoogle,
// 		tooltipContent: (
// 			<div>
// 				<p className='mb-0'>Porcentaje de interacciones (clicks, llamadas, mensajes) sobre el total de vistas del perfil</p>
// 			</div>
// 		),
// 	},
// 	{
// 		title: 'Satisfacción de consumidores (NPS)',
// 		score: '60%',
// 		icon: iconExampleGoogle,
// 		tooltipContent: (
// 			<div>
// 				<p className='mb-0'>Net Promoter Score: Mide la lealtad de los clientes basándose en recomendaciones</p>
// 			</div>
// 		),
// 	},
// 	{
// 		title: 'Sentimiento Positivo',
// 		score: '66%',
// 		icon: iconExampleGoogle,
// 		tooltipContent: (
// 			<div>
// 				<p className='mb-0'>Porcentaje de menciones positivas sobre el total de menciones analizadas</p>
// 			</div>
// 		),
// 	},
// ];

const GlobalMetrics = ({ companyId, storeId, period }) => {
    const [metrics, setMetrics] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        setError(null);

        const fetchData = () => {
            if (companyId) {
                return getGlobalMetricsKpisByCompanyId(companyId, period);
            } else if (storeId) {
                return getGlobalMetricsKpisByStoreId(storeId, period);
            } else {
                throw new Error('Either companyId or storeId must be provided');
            }
        };

        fetchData()
            .then(response => {
                const responseData = response.data.data;
                console.log(responseData);
                const transformedMetrics = [
                    {
                        title: 'Ranking Promedio Google Maps',
                        score: responseData.averageRankingGoogleCompared && responseData.averageRankingGoogleCompared.value !== null ? 
                               responseData.averageRankingGoogleCompared.value.toFixed(2) : 'N/A',
                        // variation: response.data.averageRankingGoogleCompared && response.data.averageRankingGoogleCompared.variationPercentage !== null ? 
                        //           response.data.averageRankingGoogleCompared.variationPercentage : null,
                        icon: <GoogleKpiIcon />,
                        tooltipContent: (
                            <div>
                                <p className='mb-0'>Mide la posición promedio de todos tus locales, cuando los consumidores buscan a 1 km de radio de cada sucursal en Google Maps.</p>
                            </div>
                        ),
                    },
                    {
                        title: 'Promedio Score Reviews',
                        score: responseData.averageRatingCompared && responseData.averageRatingCompared.value !== null ? 
                               formatNumber(responseData.averageRatingCompared.value) : 'N/A',
                        // variation: response.data.averageRatingCompared && response.data.averageRatingCompared.variationPercentage !== null ? 
                        //           response.data.averageRatingCompared.variationPercentage : null,
                        icon: <GoogleKpiIcon />,
                        tooltipContent: (
                            <div>
                                <p className='mb-0'>Mide el promedio de estrellas de los Reviews de todos los locales en el período de tiempo seleccionado</p>
                            </div>
                        ),
                    },
                    {
                        title: 'Engagement Rate Google Business Profile',
                        score: responseData.engagementRateCompared && responseData.engagementRateCompared.value !== null ? 
                               (responseData.engagementRateCompared.value.toFixed(2) + '%') : 'N/A',
                        // variation: response.data.engagementRateCompared && response.data.engagementRateCompared.variationPercentage !== null ? 
                        //           response.data.engagementRateCompared.variationPercentage : null,
                        icon: <GoogleKpiIcon />,
                        tooltipContent: (
                            <div>
                                <p className='mb-0'>Mide en porcentaje, cuanto interactúan con los perfiles de Google Maps los consumidores que los visitan</p>
                            </div>
                        ),
                    },
                    {
                        title: 'Satisfacción de consumidores (NPS)',
                        score: responseData.npsScoreCompared && responseData.npsScoreCompared.value !== null ? 
                               (responseData.npsScoreCompared.value.toFixed(2) + '%') : 'N/A',
                        // variation: response.data.npsScoreCompared && response.data.npsScoreCompared.variationPercentage !== null ? 
                        //           response.data.npsScoreCompared.variationPercentage : null,
                        icon: iconExampleGoogle,
                        tooltipContent: (
                            <div>
                                <p className='mb-0'>Mide la satisfacción de los consumidores del 1 al 10 utilizando Net Promoter Score (NPS)</p>
                            </div>
                        ),
                    },
                    {
                        title: 'Sentimiento Positivo',
                        score: responseData.positiveSentimentCompared && responseData.positiveSentimentCompared.value !== null ? 
                               (responseData.positiveSentimentCompared.value.toFixed(2) + '%') : 'N/A',
                        // variation: response.data.positiveSentimentCompared && response.data.positiveSentimentCompared.variationPercentage !== null ? 
                        //           response.data.positiveSentimentCompared.variationPercentage : null,
                        icon: iconExampleGoogle,
                        tooltipContent: (
                            <div>
                                <p className='mb-0'>Mide en porcentaje las respuestas categorizadas como positivas con AI, analizando solo texto de respuestas en Reviews y Encuestas.</p>
                            </div>
                        ),
                    },
                ];
                
                setMetrics(transformedMetrics);
            })
            .catch(err => {
                console.error('Error fetching global metrics:', err);
                setError(err);
                setMetrics([]);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [companyId, storeId, period]);

    return (
        <div>
            <UxGlobalMetrics 
                metrics={loading ? [] : metrics}
                loading={loading}
                error={error ? true : false}
            />
        </div>
    );
};

export default GlobalMetrics;
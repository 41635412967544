import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { BigRayIcon } from '../actionablesSideBar/ActionablesSideBarIcons';

const ActionablesSideBarChart = ({localSEOPercentage, reputationPercentage, }) => {
 
    const data = {
        datasets: [
          {
            data: [localSEOPercentage, reputationPercentage, 100 - (reputationPercentage + localSEOPercentage)],
            backgroundColor: [
              '#23B3BB',
              '#DF5492',
              '#D6D6DD',  
            ],
            borderWidth: 1,
            hoverOffset: 15,
            borderRadius: 100, 
          },
        ],
        
    };

    const options = {
        responsive:true,
        maintainAspectRatio: false,
        legend: {
            display: false,
          },
        tooltips: {
            enabled: false,  
        },
        cutoutPercentage: 83,
        rotation: 1.6,
        
    };

  
    return (
      
        <div style={{ position: 'relative', width: "100%", height: "100%" }}>
            <Doughnut data={data} options={options} />
            <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',zIndex: 0, }}>
                <BigRayIcon />
            </div>
        </div>
    )
}

export default ActionablesSideBarChart;
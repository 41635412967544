export const topicTypesTranslations = {
  BOOK: { es: "Reservar", en: "Book" },
  ORDER: { es: "Ordenar", en: "Order online" },
  SHOP: { es: "Comprar", en: "Buy" },
  LEARN_MORE: { es: "Más información", en: "Learn more" },
  SIGN_UP: { es: "Registrarse", en: "Sign up" },
  CALL: { es: "Llamar", en: "Call" },
  ACTION_TYPE_UNSPECIFIED: { es: "No especificado", en: "Unspecified" },
  STANDARD: { es: "Estándar", en: "Standard" },
  utmInfo: {
    es: "Con el fin de medir los clicks en esta publicación, agregaremos los parámetros utm en el link de manera automática. \n Ej. https://www.google.com/?utm_source=google&utm_medium=organic&utm_campaign=gmb-post&utm_content=<store_id>",
    en: "In order to measure clicks on this post, we will add the utm parameters to the link automatically.  e.g. https://www.google.com/?utm_source=google&utm_medium=organic&utm_campaign=gmb-post&utm_content=<store_id>",
  },
};

export const offerFieldsTranslations = {
  couponCode: { es: "Código de cupón", en: "Voucher code" },
  termsConditions: {
    es: "Términos y condiciones",
    en: "Terms and conditions",
  },
  redeemOnlineUrl: {
    es: "Link para redimir",
    en: "Link to redeem offer",
  },
};


const callToActionErrorsTranslations = {
  actionType: {
    es: "Tipo de Acción es requerido",
    en: "Action Type is required",
  },
  url: { es: "URL es requerido", en: "URL is required" },
  urlNotValid: {
    es: "URL no es válida",
    en: "URL is not valid",
  },
};

const mediaErrorTranslations = {
  sourceUrl: { es: "Imagen es requerida", en: "Image is required" },
  maxImages: { es: "Solo puedes agregar 10 imagenes", en: "You can only add 10 images" },
};
const titles = {
  addImage: { es: "Añade una foto y la información del posteo:", en: "Add a photo and post information:" },
  postDetails: { es: "Configura los detalles del post:", en: "Configure the details of the post:" },
  eventDate: { es: "¿En que fecha se realizará el evento?", en: "On what date will the event take place?" },
  offerDate: { es: "¿En que fecha se realizará la oferta?", en: "On what date will the offer take place?" },
  redemLink: { es: "Link para redimir", en: "Link to redeem" },
  termsConditions: { es: "Describe los términos y condiciones de la oferta...", en: "Describe the terms and conditions of the offer..." },
  couponCode: { es: "¿Cuál es el código asignado?", en: "What is the assigned code?" },
};
export const updateFormTtranslations = {
  panels: {
    socialLocationPost: {
      form: {
        titles:titles,
        label: {
          summary: { es: "Agregar una descripción", en: "Add a description" },
          topicType: { es: "Novedad", en: "Add update" },
          callToAction: {
            actionType: { es: "Botón", en: "Add a button" },
            url: { es: "Link para tu botón", en: "Link for your button" },
          },
          media: {
            sourceUrl: { es: "Agregar imágen", en: "Add photos" },
          },
        },
        errors: {
          summary: { es: "Resumen es requerido", en: "Summary is required" },
          topicType: {
            es: "Tipo de Tema es requerido",
            en: "Topic Type is required",
          },
          callToAction: callToActionErrorsTranslations,
          media: mediaErrorTranslations,
        },
        submit: { es: "Crear Post", en: "Post" },
        errors: {
          imageValidation: {
            title: {
              es: "Error de imagen",
              en: "Image Error"
            },
            message: {
              es: "La imagen debe tener un tamaño mínimo de 400x300 píxeles",
              en: "Image must be at least 400x300 pixels"
            },
            confirm: {
              es: "Aceptar",
              en: "OK"
            }
          }
        }
      },
    },
  },
  ...topicTypesTranslations,
};

export const offerFormTtranslations = {
  panels: {
    socialLocationPost: {
      form: {
        titles:titles,
        label: {
          startDate: { es: "Fecha de inicio", en: "Start date" },
          startTime: { es: "Hora de inicio", en: "Start time" },
          endDate: { es: "Fecha de finalización", en: "End date" },
          endTime: { es: "Hora de finalización", en: "End time" },
          title: { es: "Título de la oferta", en: "Offer title" },
          summary: { es: "Detalle de la oferta", en: "Offer details" },
          topicType: { es: "Novedad", en: "Add update" },
          callToAction: {
            actionType: { es: "Botón", en: "Add a button" },
            url: { es: "Link para tu botón", en: "Link for your button" },
          },
          media: {
            sourceUrl: { es: "Agregar imágen", en: "Add photos" },
          },
          ...offerFieldsTranslations,
        },
        errors: {
          event: {
            schedule: {
              startDate: {
                es: "Fecha de inicio es requerida",
                en: "Start date is required",
              },
              startTime: {
                es: "Hora de inicio es requerida",
                en: "Start time is required",
              },
              endDate: {
                es: "Fecha de finalización es requerida",
                en: "End date is required",
              },
              endTime: {
                es: "Hora de finalización es requerida",
                en: "End time is required",
              },
              endDateBeforeStart: {
                es: "La fecha de finalización no puede ser anterior a la fecha de inicio",
                en: "End date cannot be before start date"
              },
            },
            title: {
              es: "Título del evento es requerido",
              en: "Event title is required",
            },
            titleMax: {
              es: "Título del evento debe ser menor a 58 caracteres",
              en: "Event title must be less than 58 characters",
            },
          },
          summary: { es: "Resumen es requerido", en: "Summary is required" },
          topicType: {
            es: "Tipo de Tema es requerido",
            en: "Topic Type is required",
          },
          callToAction: callToActionErrorsTranslations,
          media: mediaErrorTranslations,
          offer: {
            couponCode: {
              es: "Código de cupón es requerido",
              en: "Voucher code is required",
            },
            termsConditions: {
              es: "Términos y condiciones es requerido",
              en: "Terms and conditions is required",
            },
            redeemOnlineUrl: {
              es: "Link para redimir es requerido",
              en: "Link to redeem offer is required",
            },
          },
          imageValidation: {
            title: {
              es: "Error de imagen",
              en: "Image Error"
            },
            message: {
              es: "La imagen debe tener un tamaño mínimo de 400x300 píxeles",
              en: "Image must be at least 400x300 pixels"
            },
            confirm: {
              es: "Aceptar",
              en: "OK"
            }
          }
        },
        submit: { es: "Crear Post", en: "Post" },
      },
    },
  },
  ...topicTypesTranslations,
};

export const eventFormTtranslations = {
  panels: {
    socialLocationPost: {
      form: {
        titles:titles,
        label: {
          startDate: { es: "Fecha de inicio", en: "Start date" },
          startTime: { es: "Hora de inicio", en: "Start time" },
          endDate: { es: "Fecha de finalización", en: "End date" },
          endTime: { es: "Hora de finalización", en: "End time" },
          title: { es: "Título del evento", en: "Event title" },
          summary: { es: "Detalle del evento", en: "Event details" },
          topicType: { es: "Novedad", en: "Add update" },
          callToAction: {
            actionType: { es: "Botón", en: "Add a button" },
            url: { es: "Link para tu botón", en: "Link for your button" },
          },
          media: {
            sourceUrl: { es: "Agregar imágen", en: "Add photos" },
          },
        },
        errors: {
          event: {
            schedule: {
              startDate: {
                es: "Fecha de inicio es requerida",
                en: "Start date is required",
              },
              startTime: {
                es: "Hora de inicio es requerida",
                en: "Start time is required",
              },
              endDate: {
                es: "Fecha de finalización es requerida",
                en: "End date is required",
              },
              endTime: {
                es: "Hora de finalización es requerida",
                en: "End time is required",
              },
            },
            title: {
              es: "Título del evento es requerido",
              en: "Event title is required",
            },
            titleMax: {
              es: "Título del evento debe ser menor a 58 caracteres",
              en: "Event title must be less than 58 characters",
            },
          },
          summary: { es: "Resumen es requerido", en: "Summary is required" },
          topicType: {
            es: "Tipo de Tema es requerido",
            en: "Topic Type is required",
          },
          callToAction: callToActionErrorsTranslations,
          media: mediaErrorTranslations,
        },
        submit: { es: "Crear Post", en: "Post" },
        errors: {
          imageValidation: {
            title: {
              es: "Error de imagen",
              en: "Image Error"
            },
            message: {
              es: "La imagen debe tener un tamaño mínimo de 400x300 píxeles",
              en: "Image must be at least 400x300 pixels"
            },
            confirm: {
              es: "Aceptar",
              en: "OK"
            }
          }
        }
      },
    },
  },
  ...topicTypesTranslations,
};


export const PreviewTranslations = {
  ...updateFormTtranslations,
  ...offerFormTtranslations,
  ...eventFormTtranslations,
  ...offerFieldsTranslations,
};

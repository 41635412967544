import React from 'react';
import ReactDOM from 'react-dom';
import AppRouter from "./commons/components/application/AppRouter";
//import * as serviceWorker from './serviceWorker';

import { store, persistor } from './commons/components/application/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'

import i18next from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';
import { I18nextProvider } from 'react-i18next';

import { initApiClient, setTokenApiClient } from "./api/axiosClient"
import { initialize } from "./commons/components/utils/trackers";

// translations
import esTranslations from './config/translations/es.json';
import enTranslations from './config/translations/en.json';

// Import mixpanel requirements
import mixpanel from 'mixpanel-browser';
import { MixpanelProvider } from 'react-mixpanel';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { envConfig } from './config/config';

import { QueryClientProvider } from 'react-query';
import { queryClient } from './config/reactQuery';

// Add all icons to the library so you can use it in your page
library.add(fas, fab)

// inicializacion i18n
i18next
    .use(LngDetector)
    .init({
        fallbackLng: 'en',
        interpolation: { escapeValue: false },  // React already does escaping
        detection: {
            order: ['navigator']
        },
        resources: {
            es: {
                common: esTranslations
            },
            en: {
                common: enTranslations
            }
        },
        react: {
            bindStore: false
        }
    });

// Inicialización de Mixpanel
mixpanel.init(envConfig.mixpanelToken, { batch_requests: true })

function beforeLift() {
    const token = (store.getState() && store.getState().login && store.getState().login.token) ? store.getState().login.token : null;
    setTokenApiClient(token);
}

initApiClient(store);

const Root = (
    <Provider store={store}>
        <PersistGate persistor={persistor} onBeforeLift={beforeLift}>
            <I18nextProvider i18n={i18next}>
                <QueryClientProvider client={queryClient}>
                    <MixpanelProvider mixpanel={mixpanel}>
                        <AppRouter loggedIn={store.getState().login.loggedIn} />
                    </MixpanelProvider>
                </QueryClientProvider>
            </I18nextProvider>
        </PersistGate>
    </Provider>
);

ReactDOM.render(Root, document.getElementById('wrapper'));

// If you want your home to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

//TODO Comentar para que funcionen los workers de OneSignal
//serviceWorker.unregister();

// Commented by adrian because it leads conflicts with
// the OneSignal's service workers.
//amp serviceWorker.unregister();

import React from 'react';
import { LocalSeoStoreChartComponent } from '../../localSeoStoreChart/LocalSeoStoreChart';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "botbit-ui-components";
import './LocalSeoStoreChartModal.scss';
import { GoogleMapsIcon } from '../../localSeoStoreChart/LocalSeoStoreChartIcons';
import translate from '../../../../config/translations/translate';

const LocalSeoStoreChartModalComponent = ({store, isOpen, toggle, selectedPeriod, t }) => {
    // Add console log to debug store object
    console.log('Store in modal:', store);
    
    return (
        <div className={isOpen ? "local-seo-chart-modal-container" : ""}>
            <Modal isOpen={isOpen} toggle={toggle} size="lg">
                <ModalHeader>
                    <h2 className="local-seo-chart-modal-header-title">
                        {store && (store.storeName || store.name) || t('noStoreSelected')}
                    </h2>
                </ModalHeader>
                <ModalBody>
                    <div className="local-seo-chart-header-container-modal">
                        <div className="local-seo-icon-and-subtitle">
                            <div className="google-maps-icon">
                                <GoogleMapsIcon />
                            </div>
                            <h4 className="google-maps-subtitle-modal">
                                {t('googleMapsRanking')}
                            </h4>
                        </div>
                    </div>
                    <LocalSeoStoreChartComponent store={store} selectedPeriod={selectedPeriod}/>
                </ModalBody>
                <ModalFooter>
                    <button className="close-modal-button-local-seo-chart-modal" onClick={toggle}>
                        {t('close')}
                    </button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

LocalSeoStoreChartModalComponent.translations = {
    noStoreSelected: {
        es: "No hay tienda seleccionada",
        en: "No store selected"
    },
    googleMapsRanking: {
        es: "Ranking en Google Maps",
        en: "Google Maps Ranking"
    },
    close: {
        es: "Cerrar",
        en: "Close"
    }
};

export const LocalSeoStoreChartModal = translate(LocalSeoStoreChartModalComponent);
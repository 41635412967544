import React from 'react';
import ReputationCharts from '../../../reputationGraph/ReputationCharts';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "botbit-ui-components";
import './ReputationChartModal.scss';
import translate from "../../../../../config/translations/translate";


const ReputationChartModalComponent = ({store, isOpen, toggle, selectedPeriod, t }) => {
    return (
        <div className={isOpen ? "local-seo-chart-modal-container" : ""}>
            <Modal isOpen={isOpen} toggle={toggle} size="lg">
                <ModalHeader>
                    <h2 className="local-seo-chart-modal-header-title">
                        {store ? store.name : t('noStoreSelected')}
                    </h2>
                </ModalHeader>
                <ModalBody>
                    {store && <ReputationCharts storeId={store.id} selectedPeriod={selectedPeriod}/>}
                </ModalBody>
                <ModalFooter>
                    <button className="close-modal-button-local-seo-chart-modal" onClick={toggle}>
                        {t('close')}
                    </button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

ReputationChartModalComponent.translations = {
    noStoreSelected: {
        es: "No hay tienda seleccionada",
        en: "No store selected"
    },

    close: {
        es: "Cerrar",
        en: "Close"
    }
};

export const ReputationChartModal = translate(ReputationChartModalComponent);
import React from 'react';
import { connect } from 'react-redux';
import { logoutRequest } from './loginActions';
import { envConfig } from '../../config/config.js';
import { getCookieFromBrowser, removeCookie } from '../../commons/utils/cookie';
import axiosClient from '../../api/axiosClient';
import { setLoading } from '../../commons/components/application/miscActions'
import { withRouter } from 'react-router-dom'
import { clearCache } from '../../api/cacheManager';

class LogoutContainer extends React.Component {

    shouldComponentUpdate() {
        return !this.props.loggedIn;
    }

    handleLogout = async () => {
        try {
            // Limpiar la URL actual antes de cualquier operación
            window.history.replaceState({}, '', '/');

            // Limpiar OneSignal para evitar notificaciones después del logout
            var OneSignal = window.OneSignal || [];
            OneSignal.push(function() {
                OneSignal.removeExternalUserId();
            });
            
            // Limpiar el caché de peticiones para evitar datos stale
            clearCache();
            
            // Limpiar credenciales y sesión local
            this.props.logoutRequest();
            removeCookie("rememberMeUsernameCookie");
            axiosClient.defaults.headers.common['Authorization'] = "";

            // Intentar hacer logout en el servidor PHP
            // Si falla, continuamos con la limpieza local para asegurar el logout
            try {
                const url = envConfig.frontendPhpUrl + "/externalLogout.php";
                const sessionParam = this.props.phpSession ? `?session=${this.props.phpSession}` : '';
                await axiosClient.get(url + sessionParam);
            } catch (serverError) {
                // No mostramos el error al usuario ya que continuamos con la limpieza local
                console.debug('Logout en servidor PHP no disponible, continuando con limpieza local');
            }

            // Redirigir a la raíz
            this.props.setLoading(false);
            this.props.history.push("/");
        } catch (error) {
            // Aseguramos la redirección incluso si algo falla
            this.props.setLoading(false);
            this.props.history.push("/");
        }
    }

    componentDidMount() {
        this.props.setLoading(true);
        this.handleLogout();
    }

    render() {
        return null;
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.login.loggedIn,
        phpSession: getCookieFromBrowser("PHPSESSID")
    };
}

export default withRouter(connect(mapStateToProps, { logoutRequest, setLoading })(LogoutContainer))
import axios from "axios";
import { envConfig } from '../config/config.js';
import { logoutRequest } from "../components/login/loginActions";
import { setError, setLoading } from "../commons/components/application/miscActions";
import { getCachedResponse, cacheResponse, clearCache, clearCacheForUrl, updateConfig } from './cacheManager';

/**
 * URLs base para las diferentes APIs
 * - servicesUrl: API principal de servicios
 * - frontendPhpUrl: API PHP para el frontend
 */
export const baseURL = envConfig.servicesUrl;
export const phpBaseUrl = envConfig.frontendPhpUrl;

// Store global de Redux para manejar estados y acciones
let globalStore = null;

/**
 * Configuración base para los clientes HTTP
 * - timeout: Tiempo máximo de espera para las peticiones
 * - headers: Headers por defecto para todas las peticiones
 */
const defaultConfig = {
  timeout: 120000,
  headers: {
    'Content-Type': 'application/json',
  },
};

// Cliente principal para la API de servicios
const client = axios.create({
  baseURL,
  ...defaultConfig,
});

// Cliente específico para la API PHP
export const phpClient = axios.create({
  baseURL: phpBaseUrl,
  ...defaultConfig,
});

/**
 * Interceptor de peticiones
 * - Verifica si existe una respuesta en caché
 * - Si existe, rechaza la petición y usa los datos cacheados
 */
client.interceptors.request.use(
  (config) => {
    const cachedData = getCachedResponse(config, baseURL);
    if (cachedData) {
      config.cached = true;
      config.cachedData = cachedData.data;
      return Promise.reject(config);
    }
    return config;
  },
  (error) => Promise.reject(error)
);

/**
 * Interceptor de respuestas
 * - Guarda las respuestas en caché si corresponde
 * - Maneja los errores de caché
 * - Procesa los errores HTTP y los maneja según su tipo
 */
client.interceptors.response.use(
  (response) => {
    cacheResponse(response.config, response, baseURL);
    return response;
  },
  (error) => {
    // Si es un error de caché, devolver los datos cacheados
    if (error.cached) {
      return Promise.resolve({ data: error.cachedData });
    }
    
    // Procesar errores HTTP
    let errorResponse;
    
    // Manejar errores personalizados si existen
    if (error.config && error.config.errorHandler) {
      errorResponse = error.config.errorHandler(error);
    } else {
      const defaultMessage = 'Por favor reintente nuevamente en unos minutos';
      const errorMessage = (error.response && error.response.data && error.response.data.message) || defaultMessage;
      
      // Manejar error de red específicamente y solo en dev para evitar que se muestre un error al usuario pero sin afectar el funcionamiento de la aplicación en prod
      if(error.message === "Network Error" && process.env.NODE_ENV === "development") {
        return Promise.reject({
          message: "No se pudo conectar con el servidor. Por favor, verifique su conexión a internet y vuelva a intentarlo.",
          status: 500, 
          ignore: true // No notificar al usuario ya que es un error de red provocado por que en dev no se puede acceder a la api PHP
        });
      }
      
      errorResponse = {
        message: errorMessage,
        status: error.status || 500,
        ignore: false // Por defecto, todos los errores notifican al usuario
      };

      // Manejar casos específicos de error HTTP
      if (error.response) {
        switch (error.response.status) {
          case 401:
            client.defaults.headers.common['Authorization'] = "";
            if (globalStore) {
              globalStore.dispatch(logoutRequest());
            }
            break;
          case 403:
            errorResponse.message = 'Acceso denegado';
            break;
          case 404:
            errorResponse.message = 'Recurso no encontrado';
            break;
          case 429:
            errorResponse.message = 'Demasiadas peticiones. Por favor espere un momento.';
            break;
          case 500:
            errorResponse.message = 'Error interno del servidor';
            break;
          case 504:
            // Verificar si la petición timeout fue hecha desde la misma URL actual
            const currentPath = window.location.pathname;
            const requestPath = new URL(error.config.url, window.location.origin).pathname;
            
            if (currentPath === requestPath) {
              // Si estamos en la misma URL, mostrar el error
              console.error('Gateway Timeout - La petición excedió el tiempo de espera:', {
                url: error.config.url,
                method: error.config.method,
                timeout: error.config.timeout,
                timestamp: new Date().toISOString(),
                ignore: false
              });
            } else {
              // Si estamos en una URL diferente, no mostrar el error
              errorResponse.ignore = true;
              console.debug('Gateway Timeout ignorado - La petición fue hecha desde una URL diferente:', {
                currentPath,
                requestPath,
                url: error.config.url
              });
            }
            break;
          default:
            console.error('Error no manejado:', error);
        }
      }
    }

    // Actualizar el estado de la aplicación con el error solo si debe notificarse
    if (errorResponse.ignore !== true && globalStore) {
      globalStore.dispatch(setError(errorResponse));
    }
    if (globalStore) {
      globalStore.dispatch(setLoading(false));
    }

    return Promise.reject(error);
  }
);

// Exportar funciones de caché para uso externo
export const clearRequestCache = clearCache;
export const clearRequestCacheForUrl = clearCacheForUrl;
export const updateCacheConfig = updateConfig;

/**
 * Establece el token de autenticación para las peticiones
 * - Si se proporciona un token, lo agrega al header Authorization
 * - Si no se proporciona token, elimina el header
 */
export const setTokenApiClient = (token) => {
  if (token) {
    client.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete client.defaults.headers.common['Authorization'];
  }
};

/**
 * Inicializa el cliente con el store de Redux
 * - Guarda una referencia al store para poder despachar acciones
 * - Retorna el cliente configurado
 */
export const initApiClient = (store) => {
  globalStore = store;
  return client;
};

export default client;
import React from "react";
import { Modal, ModalHeader, ModalBody } from "botbit-ui-components";
import Icons from "../Icons/Icons";
import { useHistory } from "react-router-dom";

const ModalFinish = ({ isOpen, toggle, title, subtitle, titleApplyInsignia, subtitleApplyInsignia, bonus, type, t }) => {
  const history = useHistory();
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-finish">
      <ModalHeader toggle={toggle} className="pb-0"></ModalHeader>
      <ModalBody className="modal-finish-header pt-0">
        <div>{Icons.svgModalFinish({ width: 135, height: 133 })}</div>
        <div>
            <p className="modal-finish-bonus">+{bonus}%</p>
        </div>
        {type === "insigniaApplied" ? (
          <>
            <h3 className="modal-finish-title mb-0 w-75">{titleApplyInsignia}</h3>
            <p className="modal-finish-subtitle w-75">{subtitleApplyInsignia}</p>
            <button className="save-and-return" onClick={() => history.push(`/localseo/gbp`)}>{t("modalFinishApplyInsignia.buttonBack")}</button>
          </>
        ) : (
          <>
            <h3 className="modal-finish-title mb-0">{title}</h3>
            <p className="modal-finish-subtitle w-75">{subtitle}</p>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ModalFinish;

import React, { useState, useEffect } from "react";
import translate from "../../../config/translations/translate";
import { UxCallToActionList, UxSectionHeaderH4, UxTooltip } from 'botbit-ui-components';
import { LocalSEOIcon, LittleRayIcon, ReputationIcon, QuestionMarkBoldIcon } from './ActionablesSideBarIcons';
import './ActionablesSideBar.css';
import ActionablesSideBarChart from '../actionablesSideBarChart/ActionablesSideBarChart';
import Loading from "../../../commons/components/utils/Loading";
import SpinnerV2 from "../../../commons/components/utils/spinner/SpinnerV2";
import Spinner from "../../../commons/components/utils/spinner/Spinner";

const ActionablesSideBar = ({ companyId, storeId, localSEOScoreAchieved, reputationScoreAchieved, localSEOActionablesList, 
    reputationActionablesList, icon, title, t }) => {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
    }, []);
    

    const getLocalSEOActionablesList = () => {
        return localSEOActionablesList;
    }

    const getReputationActioinablesList = () => {
        return reputationActionablesList;
    }

    return (
        <div className='actionable-side-bar-container'>
            <div className='score-and-actionable-container'>
                <div className='title-and-board-container'>
                    <h3 className='side-bar-title'> {t('title')} </h3>
                    <div className='score-graphic-and-helpmsg-container'>
                        <div className='score-and-graphic-container'>
                            <div className='chart-container'>   
                                <ActionablesSideBarChart 
                                    localSEOPercentage={localSEOScoreAchieved} 
                                    reputationPercentage={reputationScoreAchieved}
                                />
                            </div>
                            <div className='score-container'>
                                <p className='score-above-p'> {t('score.yourScore')} </p>
                                <span className='score-span'>{Math.trunc(localSEOScoreAchieved + reputationScoreAchieved)}%</span>
                                <p className='score-below-p'> {t('score.completedActions')} </p>
                            </div>
                        
                        </div>
                        <p className='help-message'>
                            {t('helpMessage.firstPart')} 
                            <span className='help-message-span'> {t('helpMessage.secondPart')} </span>
                        </p>
                    </div>
                </div>

                <div className='todo-actionable-container'>
                    <LittleRayIcon/>
                    <div className='text-and-tooltip-container'>
                        <p className='todo-actionable-text'> 
                            {localSEOActionablesList.length + reputationActionablesList.length} {t('actionablesToDo')}
                        </p>
                        <div className='tooltip-container'>
                            <UxTooltip tooltipTitle={<QuestionMarkBoldIcon/>} tooltipContent="tooltip content" />    
                        </div>
                        

                    </div>
                </div>
               
            </div>
            <div className='actionable-list-container'>
                <div className='local-seo-list-container'>
                    <UxSectionHeaderH4 icon={<LocalSEOIcon/>} title={t('localSEOActionables')} />
                    { !loading && < UxCallToActionList fetchData={getLocalSEOActionablesList} numberOfItems={localSEOActionablesList.length} />}
                    { localSEOActionablesList.length === 0 && !loading && <div className="empty-actionables-message-container">
                        <span className="empty-actionables-message-span">{t('emptyActionablesMessage.span')}</span>
                        <p className="empty-actionables-message"> 
                           
                            {t('emptyActionablesMessage.p')}
                        

                        </p>
                    </div> }  
                    { loading &&  <div className="loading-spinner-container">
                        <p className="loading-actionables-message">{t('loadingActionables')}</p>
                        <Spinner
                            color="grey-spinner"
                            big = {false}
                            small = {true}
                            crazy = {true}
                        />
                    </div>}
                </div>
                <div className='reputation-list-container'>
                    <UxSectionHeaderH4 icon={<ReputationIcon/> } title={t('reputationActionables')}/>
                    < UxCallToActionList fetchData={getReputationActioinablesList} numberOfItems={reputationActionablesList.length} />
                    { reputationActionablesList.length === 0 && !loading && <div className="empty-actionables-message-container">
                        <span className="empty-actionables-message-span">{t('emptyActionablesMessage.span')}</span>
                        <p className="empty-actionables-message">    
                            {t('emptyActionablesMessage.p')}
                        </p>
                    </div> } 
                    { loading && <div className="loading-spinner-container">
                        <p className="loading-actionables-message">{t('loadingActionables')}</p>
                        <Spinner
                            color="grey-spinner"
                            big = {false}
                            small = {true}
                            crazy = {true}
                        />
                    </div>}
                </div>
                
            </div>
        </div>
    )
}

ActionablesSideBar.translations = {
    title: {
        es: "SCORE Y ACCIONABLES",
        en: "SCORE AND ACTIONABLES"
    },

    score: {
        yourScore: {
            es: "Tu score en Ray",
            en: "Your score in Ray"
        },
        completedActions: {
            es: "Acciones realizadas",
            en: "Completed actions"
        }
    },

    helpMessage: {
        firstPart: {
            es: "Necesitas ayuda?",
            en: "Do you need help?"
        },
        secondPart: {
            es: "Ingresa aquí",
            en: "Enter here"
        }
       
    },

    actionablesToDo: {
        es: "accionables por hacer", 
        en: "actionables to do"
    },

    localSEOActionables: {
        es: "Accionables Local SEO",
        en: "Local SEO Actionables"
    },

    reputationActionables: {
        es: "Accionables Reputación",
        en: "Reputation Actionables"
    },

    emptyActionablesMessage: {
        span: {
            es: "¡Felicitaciones!",
            en: "Congratulations!"
        },
        p: {
            es:  "No tienes accionabes",
            en: "You have no actionables"
        }
    },

    loadingActionables: {
        es: "Cargando accionables",
        en: "Loading actionables"
    }
}


export default translate(ActionablesSideBar);

import React from "react";
import { useHistory } from "react-router-dom";
import SocialLocationPostUpdateForm from "./SocialLocationPostUpdateForm";
import { getImagesForCompany } from "../../api/companyApi";
import { useDispatch, useSelector } from "react-redux";
import SocialLocationPostEventAndOfferForm from "./SocialLocationPostEventAndOfferForm";
import SocialLocationPostOfferForm from "./SocialLocationPostOfferForm";
import { setLoading } from "../../commons/components/application/miscActions";
import withEventTracking, { trackers } from "../../config/withEventTracking";
import {
  SocialLocationPostCallToActionType,
  SocialLocationPostTopicType,
} from "./SocialLocationPostCallToActionType";
import translate from "../../config/translations/translate";
import { createSocialLocationPost } from "../../api/socialLocationPostsApi";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { LocationSelectAndSubmitWrapper } from "./LocationSelectAndSubmitWrapper";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UxRadioButton,
} from "botbit-ui-components";
import { updateIcon, offerIcon, eventIcon } from "./icons";

const MySwal = withReactContent(Swal);

const PostModal = ({ isOpen, toggle, title, body, footer }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <div style={{ maxHeight: "55vh" }}>{body}</div>
      </ModalBody>
      <ModalFooter>{footer}</ModalFooter>
    </Modal>
  );
};

export function SocialLocationPostContainer({
  trackEvent,
  t,
  modal,
  socialLocationId,
  handleApply
}) {
  // const path = "/";
  const history = useHistory();
  // const [postData, setPostData] = React.useState();
  const dispatch = useDispatch();
  const [images, setImages] = React.useState();
  const [selectedType, setSelectedType] = React.useState("update");
  const formRef = React.useRef();
  const [selectedLocations, setSelectedLocations] = React.useState([]);
  const publicHandleApply = handleApply;

  const company = useSelector((state) => state.application.selectedCompany);
  const emmit = (action) =>
    trackEvent(action.toLowerCase(), {}, false, [
      trackers.BACKEND,
      trackers.MIXPANEL,
    ]);

  React.useEffect(() => {
    if (!images) {
      dispatch(setLoading(true));
      getImagesForCompany(company.id, 1024).then((res) => {
        setImages(res.data.data);
        dispatch(setLoading(false));
      });
    }
    if(modal === undefined) {
      emmit("localpost_viewed");
    }
  }, []);

  React.useEffect(() => {
    if (modal && modal.isOpen) {
      setSelectedType("update");
      emmit("localpost_viewed");
    }
  }, [modal !== undefined && modal.isOpen]);

  const submitHandlerBuilder = (eventName) => (values) => {
    // setPostData(values);
    // history.push(`${path}/selectlocations`);
    emmit(eventName);
    createPost(values);
  };

  // Función para trackear el tipo de post seleccionado
  const handleTypeChange = (type) => {
    setSelectedType(type);
    
    // Trackeamos el evento según el tipo seleccionado
    if (type === 'update') {
      trackEvent("localseo_gbppost_update_viewed", {}, false, [trackers.MIXPANEL]);
    } else if (type === 'event') {
      trackEvent("localseo_gbppost_event_viewed", {}, false, [trackers.MIXPANEL]);
    } else if (type === 'offer') {
      trackEvent("localseo_gbppost_offer_viewed", {}, false, [trackers.MIXPANEL]);
    }
  };

  const renderForm = () => {
    const typeButtonGroupStyle =
      modal === undefined
        ? {
            justifyContent: "center",
            display: "flex",
          }
        : {};
    return (
      <div className="row">
        <div className="col-12" style={typeButtonGroupStyle}>
          <UxRadioButton
            options={[
              {
                icon: updateIcon,
                title: t("update"),
                value: "update",
              },
              {
                icon: eventIcon,
                title: t("event"),
                value: "event",
              },
              {
                icon: offerIcon,
                title: t("offer"),
                value: "offer",
              },
            ]}
            onChange={(v) => handleTypeChange(v)}  // Cambiamos setSelectedType por handleTypeChange
            initialValue={"update"}
          />
        </div>
        <div className="col-12">{postFormSwitch()}</div>
      </div>
    );
  };

  // View pantalla de selección de Locations antes de postear
  const postFormSwitch = () => {
    switch (selectedType) {
      case "update":
        return (
          <SocialLocationPostUpdateForm
            socialLocationPost={{
              callToAction: {
                actionType:
                  SocialLocationPostCallToActionType.ACTION_TYPE_UNSPECIFIED,
              },
            }}
            images={images}
            onSubmit={submitHandlerBuilder(
              "localseo_gbppost_selectlocations_viewed"
            )}
            formRef={formRef}
          />
        );

      case "event":
        return (
          <SocialLocationPostEventAndOfferForm
            socialLocationPost={{
              topicType: SocialLocationPostTopicType.EVENT,
              callToAction: {
                actionType:
                  SocialLocationPostCallToActionType.ACTION_TYPE_UNSPECIFIED,
              },
            }}
            images={images}
            onSubmit={submitHandlerBuilder(
              "localseo_gbppost_selectlocations_viewed"
            )}
            formRef={formRef}
          />
        );

      case "offer":
        return (
          <SocialLocationPostOfferForm
            socialLocationPost={{
              topicType: SocialLocationPostTopicType.OFFER,
              callToAction: {
                actionType:
                  SocialLocationPostCallToActionType.ACTION_TYPE_UNSPECIFIED,
              },
            }}
            images={images}
            onSubmit={submitHandlerBuilder(
              "localseo_gbppost_selectlocations_viewed"
            )}
            formRef={formRef}
          />
        );

      default:
        return null;
    }
  };

  const resetAndToggleModal = () => {
    formRef.current = undefined;
    setSelectedType(null);
    setSelectedLocations(null);
    if (modal && modal.toggle) modal.toggle();
  };

  const formatLocationList = (locations) => {
    return locations.map(loc => `<div style="margin: 8px 0;">${loc.name}</div>`).join('');
  };

  const createPost = (values) => {
    const postServiceBody = {
      ...values,
      socialAccountWithSocialLocations: selectedLocations,
    };

    dispatch(setLoading(true));
    
    createSocialLocationPost(postServiceBody).then((res) => {
      dispatch(setLoading(false));
      const allOk = res.data.data.every((x) => x.success === true);

      const successLocations = res.data.data
        .filter(x => x.success)
        .map(x => ({
          id: x.locationId,
          name: x.locationName
        }));
      
      const failedLocations = res.data.data
        .filter(x => !x.success)
        .map(x => ({
          id: x.locationId,
          name: x.locationName
        }));

      let alertText = '';
      alertText += `<div style="text-align: center; max-width: 500px; margin: 0 auto;">
        <div style="max-height: 300px; overflow-y: auto; padding: 0 10px;">`;

      if (successLocations.length > 0) {
        alertText += `
          <div style="margin-bottom: 15px;">
            <p style="font-weight: bold; margin-bottom: 8px; color: #28a745;">${t("successLocations")}:</p>
            <div style="font-size: 16px; line-height: 1.4;">
              ${formatLocationList(successLocations)}
            </div>
          </div>`;
      }

      if (failedLocations.length > 0) {
        alertText += `
          <div style="margin-top: 8px;">
            <p style="font-weight: bold; margin-bottom: 8px; color: #dc3545;">${t("failedLocations")}:</p>
            <div style="font-size: 16px; line-height: 1.4;">
              ${formatLocationList(failedLocations)}
            </div>
          </div>`;
      }

      alertText += `
        </div>
      </div>`;

      if (res.status === 200) {
        if(publicHandleApply !== null && publicHandleApply !==undefined){
          publicHandleApply("postHistoryCount", "", null, null);
        }

        if (allOk) {
          trackEvent("localseo_gbppost_confirmation_submited", {}, false, [trackers.MIXPANEL]);
          MySwal.fire({
            type: "success",
            title: t("swalText"),
            html: alertText,
            confirmButtonText: t("ok"),
            showCloseButton: true
          }).then(() => {
            resetAndToggleModal();
          });
        } else {
          setSelectedLocations(
            res.data.data
              .filter((x) => x.success === false)
              .map((x) => x.locationId)
          );
          MySwal.fire({
            type: "error",
            title: t("swalErrorText"),
            html: alertText,
            confirmButtonText: t("ok"),
            showCloseButton: true
          });
        }
      } else {
        history.push("/localseo/posts");
      }
    });
  };

  if (modal) {
    return (
      <PostModal
        isOpen={modal.isOpen}
        toggle={() => {
          resetAndToggleModal();
        }}
        title={
          <>
            <h3>{t("modalTitle")}</h3>
            <h6>{t("modalSubtitle")}</h6>
          </>
        }
        body={renderForm()}
        footer={
          <LocationSelectAndSubmitWrapper
            setSelectedLocations={setSelectedLocations}
            formRef={formRef}
            t={t}
            socialLocationId={socialLocationId}
            selectedLocations={selectedLocations}
          />
        }
      />
    );
  }

  return (
    <div
      className="m-auto p-5 bg-white gbp-container"
      style={{ maxWidth: "900px" }}
    >
      <>
        {renderForm()}
        <hr />
        {selectedType && (
          <LocationSelectAndSubmitWrapper
            setSelectedLocations={setSelectedLocations}
            formRef={formRef}
            selectedLocations={selectedLocations}
            t={t}
          />
        )}
      </>
    </div>
  );
}

SocialLocationPostContainer.translations = {
  submit: {
    en: "Publish",
    es: "Publicar",
  },
  update: {
    en: "Update",
    es: "Novedad",
  },
  event: {
    en: "Event",
    es: "Evento",
  },
  offer: {
    en: "Offer",
    es: "Oferta",
  },
  modalTitle: {
    en: "Create a post",
    es: "Crea un post",
  },
  swalText: {
    en: "Post created",
    es: "Publicación creada",
  },
  swalErrorText: {
    en: "There was an error creating the post",
    es: "Hubo un error creando la publicación",
  },
  ok: {
    en: "Ok",
    es: "Aceptar",
  },
  selectLocations: {
    en: "Select locations",
    es: "Selecciona los locales",
  },
  modalSubtitle: {
    es: "¡Realiza un nuevo post en tus perfiles de Google My Business!",
    en: "Create a new post in your Google My Business profiles!",
  },
  successLocations: {
    en: "Successfully posted to locations",
    es: "Publicado exitosamente en las ubicaciones"
  },
  failedLocations: {
    en: "Failed to post to locations",
    es: "Falló la publicación en las ubicaciones"
  },
};

export default withEventTracking(translate(SocialLocationPostContainer));

import React from 'react'
import './DashboardStoreSelector.scss'

export const DashboardStoreSelector = ({ 
  stores, 
  selectedStore, 
  handleStoreChange, 
  t 
}) => {
  return (
    <div className="location-selector">
      <div className="location-selector-label-container">
        <label className="location-selector-label">{t('locationSelector.label')}</label>
        <select 
          className="location-selector-select" 
          onChange={handleStoreChange}
          value={selectedStore && selectedStore.id ? selectedStore.id : ''}
        >
          {stores.map(store => (
            <option key={store.id} value={store.id}>
              {store.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}
import React from 'react';

export const LocalSEOTableIcon = () => {
  return (
    <span role="img" aria-label="icon"><svg width="47" height="52" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.5 9.54076V23.4592C0.5 24.3819 1.02152 25.2344 1.86699 25.6947L14.633 32.654C15.4785 33.1153 16.5215 33.1153 17.367 32.654L30.133 25.6947C30.9785 25.2334 31.5 24.3809 31.5 23.4592V9.54076C31.5 8.61808 30.9785 7.76559 30.133 7.30525L17.367 0.346007C16.5215 -0.115336 15.4785 -0.115336 14.633 0.346007L1.86699 7.30525C1.02152 7.76659 0.5 8.61908 0.5 9.54076Z" fill="#23B3BB"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M17.0403 11.9167C17.0403 11.6003 17.3096 11.3438 17.6418 11.3438H22.4535C22.7857 11.3438 23.055 11.6003 23.055 11.9167V16.5C23.055 16.8164 22.7857 17.0729 22.4535 17.0729C22.1213 17.0729 21.8521 16.8164 21.8521 16.5V13.5216L17.5058 18.5815C17.3982 18.7068 17.24 18.7829 17.0703 18.7909C16.9006 18.799 16.7352 18.7383 16.615 18.6238L13.504 15.6604L9.10625 21.4203C8.91087 21.6762 8.5347 21.7327 8.26605 21.5466C7.99741 21.3605 7.93801 21.0022 8.13339 20.7463L12.9451 14.4443C13.0486 14.3088 13.2091 14.2232 13.3845 14.2101C13.5599 14.197 13.7325 14.2576 13.8568 14.3761L16.9957 17.366L21.1843 12.4896H17.6418C17.3096 12.4896 17.0403 12.2331 17.0403 11.9167Z" fill="white"/>
                    </svg></span>
                    )
}

export const ReputationTableIcon = () => {
  return (
    <svg width="46" height="47" viewBox="0 0 46 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 4.5C0 2.29086 1.79086 0.5 4 0.5H28C37.9411 0.5 46 8.55888 46 18.5V42.5C46 44.7091 44.2091 46.5 42 46.5H18C8.05888 46.5 0 38.4411 0 28.5V4.5Z" fill="#DF5492"/>
        <path d="M19.2632 23.5C22.0972 23.5 24.3947 21.2614 24.3947 18.5C24.3947 15.7386 22.0972 13.5 19.2632 13.5C16.4291 13.5 14.1316 15.7386 14.1316 18.5C14.1316 21.2614 16.4291 23.5 19.2632 23.5Z" fill="white"/>
        <path d="M10.7105 33.5C10.7105 33.5 9 33.5 9 31.8333C9 30.1667 10.7105 25.1667 19.2632 25.1667C27.8158 25.1667 29.5263 30.1667 29.5263 31.8333C29.5263 33.5 27.8158 33.5 27.8158 33.5H10.7105Z" fill="white"/>
        <path d="M27.8158 16C27.8158 15.5398 28.1987 15.1667 28.6711 15.1667H35.5132C35.9855 15.1667 36.3684 15.5398 36.3684 16C36.3684 16.4602 35.9855 16.8333 35.5132 16.8333H28.6711C28.1987 16.8333 27.8158 16.4602 27.8158 16Z" fill="white"/>
        <path d="M28.6711 20.1667C28.1987 20.1667 27.8158 20.5398 27.8158 21C27.8158 21.4602 28.1987 21.8333 28.6711 21.8333H35.5132C35.9855 21.8333 36.3684 21.4602 36.3684 21C36.3684 20.5398 35.9855 20.1667 35.5132 20.1667H28.6711Z" fill="white"/>
        <path d="M32.0921 25.1667C31.6198 25.1667 31.2368 25.5398 31.2368 26C31.2368 26.4602 31.6198 26.8333 32.0921 26.8333H35.5132C35.9855 26.8333 36.3684 26.4602 36.3684 26C36.3684 25.5398 35.9855 25.1667 35.5132 25.1667H32.0921Z" fill="white"/>
        <path d="M32.0921 30.1667C31.6198 30.1667 31.2368 30.5398 31.2368 31C31.2368 31.4602 31.6198 31.8333 32.0921 31.8333H35.5132C35.9855 31.8333 36.3684 31.4602 36.3684 31C36.3684 30.5398 35.9855 30.1667 35.5132 30.1667H32.0921Z" fill="white"/>
    </svg>
  )
}


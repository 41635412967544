import React from 'react';

export const ChartArrowIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9294_2622)">
        <rect width="20" height="20" rx="3" fill="#13589D"/>
        <path 
          fillRule="evenodd" 
          clipRule="evenodd" 
          d="M5 10C5 10.4142 5.33579 10.75 5.75 10.75H12.3879L10.2302 12.7094C9.93159 12.9965 9.92228 13.4713 10.2094 13.7698C10.4965 14.0684 10.9713 14.0777 11.2698 13.7906L14.7698 10.5406C14.9169 10.3992 15 10.204 15 10C15 9.79599 14.9169 9.60078 14.7698 9.45938L11.2698 6.20938C10.9713 5.92228 10.4965 5.93159 10.2094 6.23017C9.92228 6.52875 9.93159 7.00353 10.2302 7.29063L12.3879 9.25L5.75 9.25C5.33579 9.25 5 9.58579 5 10Z" 
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_9294_2622">
          <rect width="20" height="20" rx="3" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export const StarIcon = () => {
  return (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path 
        d="M3.83753 13.5124C3.49939 13.6862 3.11652 13.3824 3.1846 12.9944L3.91061 8.8561L0.828568 5.91904C0.540535 5.64456 0.689635 5.14269 1.0755 5.08788L5.36167 4.47899L7.27277 0.693267C7.44493 0.352244 7.91127 0.352244 8.08343 0.693267L9.99453 4.47899L14.2807 5.08788C14.6666 5.14269 14.8157 5.64456 14.5276 5.91904L11.4456 8.8561L12.1716 12.9944C12.2397 13.3824 11.8568 13.6862 11.5187 13.5124L7.6781 11.5384L3.83753 13.5124Z" 
        fill="#F0B747"
      />
    </svg>
  );
};

export const QuestionMarkIcon = () => {
  return (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.6781 11.25C3.77861 11.25 1.4281 8.8995 1.4281 6C1.4281 3.10051 3.77861 0.75 6.6781 0.75C9.5776 0.75 11.9281 3.10051 11.9281 6C11.9281 8.8995 9.5776 11.25 6.6781 11.25ZM6.6781 12C9.99181 12 12.6781 9.31371 12.6781 6C12.6781 2.68629 9.99181 0 6.6781 0C3.36439 0 0.678101 2.68629 0.678101 6C0.678101 9.31371 3.36439 12 6.6781 12Z" fill="#9291A5"/>
      <path d="M4.61943 4.33961C4.61374 4.44177 4.69803 4.52454 4.80035 4.52454H5.41872C5.52227 4.52454 5.60461 4.4401 5.61852 4.33748C5.68524 3.84539 6.02298 3.48669 6.62502 3.48669C7.13918 3.48669 7.61049 3.74377 7.61049 4.36267C7.61049 4.83875 7.32961 5.05774 6.88686 5.39099C6.38222 5.75757 5.98232 6.18604 6.01088 6.8811L6.01311 7.04371C6.01451 7.14625 6.09804 7.22864 6.20059 7.22864H6.80885C6.91241 7.22864 6.99635 7.14469 6.99635 7.04114V6.96204C6.99635 6.42407 7.20107 6.26697 7.75331 5.84802C8.21034 5.50049 8.68642 5.11487 8.68642 4.30554C8.68642 3.17249 7.72951 2.625 6.68214 2.625C5.73183 2.625 4.69033 3.06796 4.61943 4.33961ZM5.78712 8.66162C5.78712 9.06152 6.10609 9.35669 6.54408 9.35669C7.00111 9.35669 7.31532 9.06152 7.31532 8.66162C7.31532 8.24744 7.00111 7.95703 6.54408 7.95703C6.10609 7.95703 5.78712 8.24744 5.78712 8.66162Z" fill="#9291A5"/>
    </svg>
  );
};

export const QuestionMarkBoldIcon = () => {
    return (<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z" fill="#1A1A1A"/>
        <path d="M5.25511 5.78615C5.24752 5.92237 5.3599 6.03271 5.49634 6.03271H6.32082C6.45889 6.03271 6.56868 5.92013 6.58723 5.78331C6.67618 5.12718 7.1265 4.64893 7.92922 4.64893C8.61477 4.64893 9.24318 4.9917 9.24318 5.81689C9.24318 6.45166 8.86867 6.74365 8.27834 7.18799C7.60549 7.67676 7.07229 8.24805 7.11037 9.1748L7.11334 9.39161C7.11521 9.52833 7.22658 9.63818 7.36332 9.63818H8.17434C8.31241 9.63818 8.42434 9.52625 8.42434 9.38818V9.28271C8.42434 8.56543 8.69729 8.35596 9.43361 7.79736C10.043 7.33398 10.6778 6.81982 10.6778 5.74072C10.6778 4.22998 9.40188 3.5 8.00539 3.5C6.73831 3.5 5.34964 4.09061 5.25511 5.78615ZM6.81203 11.5488C6.81203 12.082 7.23732 12.4756 7.82131 12.4756C8.43068 12.4756 8.84963 12.082 8.84963 11.5488C8.84963 10.9966 8.43068 10.6094 7.82131 10.6094C7.23732 10.6094 6.81203 10.9966 6.81203 11.5488Z" fill="#1A1A1A"/>
    </svg>
  );
};

export const createStarImage = () => {
  const svgString = `<svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.83753 13.5124C3.49939 13.6862 3.11652 13.3824 3.1846 12.9944L3.91061 8.8561L0.828568 5.91904C0.540535 5.64456 0.689635 5.14269 1.0755 5.08788L5.36167 4.47899L7.27277 0.693267C7.44493 0.352244 7.91127 0.352244 8.08343 0.693267L9.99453 4.47899L14.2807 5.08788C14.6666 5.14269 14.8157 5.64456 14.5276 5.91904L11.4456 8.8561L12.1716 12.9944C12.2397 13.3824 11.8568 13.6862 11.5187 13.5124L7.6781 11.5384L3.83753 13.5124Z" fill="#F0B747"/>
  </svg>`;
  
  const blob = new Blob([svgString], { type: 'image/svg+xml' });
  return URL.createObjectURL(blob);
};

/**
 * Configuración global del sistema de caché
 * - enabled: Activa/desactiva el caché globalmente
 * - maxSize: Número máximo de peticiones que se pueden almacenar en caché
 * - defaultTTL: Tiempo de vida por defecto para las peticiones cacheadas (5 minutos)
 * - urls: Configuración específica por ruta de API
 */
const cacheConfig = {
  enabled: true,
  maxSize: 100,
  defaultTTL: 5 * 60 * 1000, // 5 minutos por defecto
  urls: {
    '/dashboard/reviews': {
      ttl: 5 * 60 * 1000, // 5 minutos para las reseñas del dashboard
      enabled: true
    },
    '/feedback/review-request': {
      ttl: 10 * 60 * 1000, // 10 minutos para las solicitudes de reseñas
      enabled: true
    }
  }
};

// Almacenamiento en memoria de las peticiones cacheadas
const requestCache = new Map();

/**
 * Obtiene la configuración específica de caché para una URL
 * Si no existe configuración específica, devuelve la configuración global
 */
const getCacheConfig = (url) => {
  // Buscar si existe una configuración específica para la URL
  const urlConfig = Object.entries(cacheConfig.urls).find(([key]) => url.startsWith(key));
  
  if (urlConfig) {
    // Si existe configuración específica, combinar con la global
    return {
      ...cacheConfig,
      ...urlConfig[1]
    };
  }
  
  // Si no existe configuración específica, usar la global
  return {
    enabled: cacheConfig.enabled,
    maxSize: cacheConfig.maxSize,
    ttl: cacheConfig.defaultTTL
  };
};

/**
 * Limpia el caché cuando excede el tamaño máximo permitido
 * Elimina las entradas más antiguas para mantener el tamaño dentro del límite
 */
const cleanupCache = () => {
  if (requestCache.size > cacheConfig.maxSize) {
    const entries = Array.from(requestCache.entries());
    entries.sort((a, b) => a[1].timestamp - b[1].timestamp);
    const entriesToRemove = entries.slice(0, entries.length - cacheConfig.maxSize);
    entriesToRemove.forEach(([key]) => requestCache.delete(key));
  }
};

/**
 * Normaliza la URL y sus parámetros para crear una clave única de caché
 * - Remueve el baseURL si está presente
 * - Extrae los parámetros de la URL si existen
 * - Ordena los parámetros para asegurar consistencia
 */
const normalizeRequestKey = (config, baseURL) => {
  let url = config.url;
  let params = config.params || {};

  // Remover baseURL si está presente
  if (url.startsWith(baseURL)) {
    url = url.substring(baseURL.length);
  }

  // Extraer parámetros de la URL si existen
  if (url.includes('?')) {
    const [baseUrl, queryString] = url.split('?');
    url = baseUrl;
    
    // Convertir query string a objeto y combinar con params existentes
    const urlParams = new URLSearchParams(queryString);
    params = {
      ...params,
      ...Object.fromEntries(urlParams)
    };
  }
  
  // Ordenar parámetros para asegurar consistencia en la clave
  const sortedParams = Object.keys(params)
    .sort()
    .reduce((acc, key) => {
      acc[key] = params[key];
      return acc;
    }, {});
  
  return `${url}-${JSON.stringify(sortedParams)}`;
};

/**
 * Verifica si existe una respuesta válida en caché para la petición actual
 * - Solo verifica peticiones GET
 * - Comprueba si el caché está habilitado globalmente
 * - Verifica que el caché no haya expirado
 */
const getCachedResponse = (config, baseURL) => {
  if (config.method === 'get' && cacheConfig.enabled) {
    const url = config.url.startsWith(baseURL) ? config.url.substring(baseURL.length) : config.url;
    const urlConfig = getCacheConfig(url);
    
    if (urlConfig.enabled) {
      const cacheKey = normalizeRequestKey(config, baseURL);
      const cachedResponse = requestCache.get(cacheKey);
      
      if (cachedResponse && Date.now() - cachedResponse.timestamp < urlConfig.ttl) {
        return {
          cached: true,
          data: cachedResponse.data
        };
      }
    }
  }
  return null;
};

/**
 * Guarda una respuesta en caché
 * - Solo cachea peticiones GET
 * - Verifica que el caché esté habilitado
 * - Limpia el caché si excede el tamaño máximo
 */
const cacheResponse = (config, response, baseURL) => {
  if (config.method === 'get' && cacheConfig.enabled) {
    const url = config.url.startsWith(baseURL) ? config.url.substring(baseURL.length) : config.url;
    const urlConfig = getCacheConfig(url);
    
    if (urlConfig.enabled) {
      const cacheKey = normalizeRequestKey(config, baseURL);
      requestCache.set(cacheKey, {
        data: response.data,
        timestamp: Date.now(),
      });
      cleanupCache();
    }
  }
};

/**
 * Limpia todo el caché
 */
const clearCache = () => {
  requestCache.clear();
};

/**
 * Limpia el caché para una URL específica
 * Útil cuando se necesita invalidar el caché de una ruta en particular
 */
const clearCacheForUrl = (url) => {
  for (const [key] of requestCache) {
    if (key.startsWith(url)) {
      requestCache.delete(key);
    }
  }
};

/**
 * Actualiza la configuración del caché
 * Permite modificar la configuración en tiempo de ejecución
 */
const updateConfig = (newConfig) => {
  Object.assign(cacheConfig, newConfig);
};

export {
  getCachedResponse,
  cacheResponse,
  clearCache,
  clearCacheForUrl,
  updateConfig
}; 
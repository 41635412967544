import axiosClient from './axiosClient';
import { dateTOYYYYMMDD } from '../commons/utils/formatDate';

export const getEmailStatus = (companyId) =>{
    var url = `/company-email-pack/${companyId}`;
    return axiosClient.get(url);
}

export const getImagesForCompany = (companyId, minSize, extension) =>{
	const minSizeParam = minSize ? `?minSize=${minSize}` : '';
	const extensionParam = extension ? `&extension=${extension}` : '';
    var url = `/companies/${companyId}/images${minSizeParam}${extensionParam}`;
    return axiosClient.get(url);
}

export const getDailyInformationForCompanyByDateRange = (companyId, range) => {
    const {dateTo, dateFrom} = range;
    var firstDay = dateTOYYYYMMDD(dateFrom);
    var lastDay = dateTOYYYYMMDD(dateTo);
    var url = `/companies/analytics/daily-accumulations/${companyId}?dateFrom=${firstDay}&dateTo=${lastDay}`;
    return axiosClient.get(url);
}

export const getCompanies = () => {
    return axiosClient.get('/companies')
}

export const getCompany = (companyId) => {
	return axiosClient.get('/companies/'+ companyId);
}

export const getCompanyHotBotId = (companyId) => {
	return axiosClient.get(`/companies/${companyId}/hotbot`);
}

export const updateCompany = (company) => {
	return axiosClient.post('/companies', company);
}

export const deleteCompany = (companyId) => {
	return axiosClient.delete('/companies/'+companyId);
}

export const getProductCategoriesByCompany = (companyId) => {
    return new Promise(
		resolve=> {
			axiosClient.get('/companies/' + companyId + '/productCategories')
				.then(
					res => resolve(res.data.data)
				)
		}
	);
}

export const getCategories = () => {
    return axiosClient.get(`company-category`);
}

export const getCompanyQuestions = (companyIds) => {
	let companyIdsParam = (companyIds && companyIds.length > 0) ? ("?companyIds=" + companyIds.join(",")) : "";
	return axiosClient.get('/companies/questions' + companyIdsParam);
}

export const uploadFile = (file, companyId, fileType = 'IMAGE') => {
	console.log('uploadFile', file, companyId, fileType);
	const formData = new FormData();
	formData.append('file[0]', file);
	return axiosClient.post(`/upload?companyId=${companyId}&fileType=${fileType}`, formData);
}
import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "botbit-ui-components";
import { translations } from "../../../../../config/translations/GoogleBusinessProfile/GoogleBusinessProfile";
import {
  getPlaceImprovementsSuggestions,
} from "../../../../../api/googleBusinessProfileApi";
import Spinner from "../../../../../commons/components/utils/spinner/Spinner";
import withEventTracking, { trackers } from "../../../../../config/withEventTracking";
import "../styles.scss";

const ModalCategories = ({
  isOpen,
  toggle,
  title,
  subtitle,
  t,
  socialLocationId,
  placeData,
  onApply,
  trackEvent,
}) => {
  // const [isChecked, setIsChecked] = useState(false);
  const [suggestedCategories, setSuggestedCategories] = useState({
    primaryCategory: "",
    categories: [],
  });
  const [isLoading, setIsLoading] = useState(false);

  const { categories = [] } = placeData || {};
  const primaryCategory = categories[0] || "";
  const secondaryCategories = categories.slice(1) || [];

  useEffect(() => {
    console.log("Primary Category: ", primaryCategory);
    console.log("Secondary Categories: ", secondaryCategories);
    console.log("Suggested Categories: ", suggestedCategories);
  }, [isLoading]);

  useEffect(() => {
    const fetchSuggestedCategories = async () => {
      if (!socialLocationId) return;

      setIsLoading(true);
      try {
        const response = await getPlaceImprovementsSuggestions(
          socialLocationId
        );

        const improvementData =
          response.data.data.categoriesImprovementOpportunity;
        setSuggestedCategories({
          primaryCategory: improvementData.primaryCategory,
          categories: improvementData.categories || [],
        });
      } catch (error) {
        console.error("Error fetching suggested categories:", error);
        setSuggestedCategories({ primaryCategory: "", categories: [] });
      } finally {
        setIsLoading(false);
      }
    };

    fetchSuggestedCategories();
  }, [isOpen, socialLocationId]);

  const handlePostImprovements = async () => {

    setIsLoading(true);

    try {
      await onApply(suggestedCategories.categories);
      toggle();
    } catch (error) {
      console.error("Error posting improvements:", error);
    }
    finally{
      setIsLoading(false);
    }
  };

  const handleRemoveCategory = (index) => {
    if (suggestedCategories.categories.length <= 1) return;

    //Evento de Mixpanel gbp_audit_category_erased
    const categoryToRemove = suggestedCategories.categories[index];
    trackEvent("gbp_audit_category_erased", { categoriaEliminada: categoryToRemove }, false, [trackers.MIXPANEL]);
   
    setSuggestedCategories((prevState) => {
      const updatedCategories = [...prevState.categories];
      updatedCategories.splice(index, 1); 
      return { ...prevState, categories: updatedCategories };
    });
  };

  const renderSuggestedCategories = () => {
    if (isLoading) {
      return <p>{t("categories.modal.loading")}</p>;
    }

   const isPrimaryCategoryMatching =
     suggestedCategories.primaryCategory.toLowerCase() ===
     primaryCategory.toLowerCase();

    return (
      <>
        <h3 className="font-bold m-0">{t("categories.modal.title3")}</h3>
        <p className="font-normal">{t("categories.modal.title4")}</p>
        <p className="d-flex-row gap-2 mb-0-important">
          {" "}
          <span
            className={
              isPrimaryCategoryMatching ? "chips-secondary" : "chips-success"
            }
          >
            {suggestedCategories.primaryCategory}
          </span>
        </p>

        <p className="font-normal">{t("categories.modal.title5")}</p>
        {suggestedCategories.categories && suggestedCategories.categories.length > 0 ? (
          suggestedCategories.categories.map((category, index) => {
            const isSecondaryMatching = secondaryCategories.some(
              (secCat) => secCat.toLowerCase() === category.toLowerCase()
            );
            return (
              <p key={index} className="d-flex-row gap-2 mb-0-important">
                {suggestedCategories.categories.length > 1 && (
                  <div className="circle-categories" onClick={() => handleRemoveCategory(index)}>
                    <div className="cross-categories-1"></div>
                    <div className="cross-categories-2"></div>
                  </div>
                )}
                <span
                  className={
                    isSecondaryMatching ? "chips-secondary" : "chips-success"
                  }
                >
                  {category}
                </span>
              </p>
            );
          })
        ) : (
          <p className="mb-0-important font-bold">
            {t("categories.modal.noSecondaryCategories")}
          </p>
        )}
      </>
    );
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-categories">
      <ModalHeader toggle={toggle} className="modal-categories-header">
        <div className="font-bold-600 mb-0 mt-0-important">{title}</div>
      </ModalHeader>
      <ModalBody className="modal-pt-0">
        <p className="subtitle-modal mb-0-important mt-2">{subtitle}</p>
        <div className="spacer"></div>
        <div className="flex-row-modal gap-4">
          <div className="w-50 gap-2 d-flex-column bg-current font-bold-600">
            <h3 className="font-bold m-0">{t("categories.modal.title2")}</h3>
            <p className="font-normal">{t("categories.modal.title4")}</p>
            <p>{primaryCategory}</p>
            <p className="font-normal">{t("categories.modal.title5")}</p>
            {secondaryCategories.length > 0 ? (
              secondaryCategories.map((category, index) => (
                <p className="mb-0-important font-bold" key={index}>
                  {category}
                </p>
              ))
            ) : (
              <p className="mb-0-important font-bold">
                {t("categories.modal.noSecondaryCategories")}
              </p>
            )}
          </div>
          <div className="suggestions-and-advice-container">
            <div className="gap-2 d-flex-column bg-suggestions font-bold-600">
              {renderSuggestedCategories()}
            </div>
            {suggestedCategories.categories.length === 1 && (
              <p className="additional-text">{t("categories.modal.title7")}</p>
            )}
          </div>
        </div>
        <div className="mt-40">
          <div className="d-flex-column justify-content-start">
            <h3>{t("categories.modal.title6")}</h3>
            <div className="d-flex-row gap-2 align-items-center">
              <div className="checkbox-input-example"></div>
              <label className="mb-0 checkbox-label-example">
                {t("categories.modal.checkbox")}
              </label>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
      <button
          className="card-button-modal-2"
          onClick={toggle}
        > 
        {t("categories.modal.button2")}
        </button>
        {isLoading && 
          <Spinner color={"grey-spinner"} small={"false"} />
        }
        
        <button
          className="card-button-modal-1"
          onClick={handlePostImprovements}
          disabled = {isLoading}
        > 
          {t("categories.modal.button1")}
        </button>
      </ModalFooter>
    </Modal>
  );
};

ModalCategories.translations = {
  ...translations,
};

export default withEventTracking(ModalCategories);

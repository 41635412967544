import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'botbit-ui-components';
import { UxFileUpload } from 'botbit-ui-components';
import { uploadFile } from '../../../../../api/companyApi';
import { updateGoogleBusinessProfile } from '../../../../../api/googleBusinessProfileApi';
import { useSelector } from 'react-redux';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import translate from '../../../../../config/translations/translate';
import { translations } from '../../../../../config/translations/GoogleBusinessProfile/GoogleBusinessProfile';

const MySwal = withReactContent(Swal);

let setModalOpen;
let publicHandleApply;
let currentType = "ADDITIONAL";

export const openPhotosModal = (locationId, handleApply, type = "ADDITIONAL") => {
  publicHandleApply = handleApply;
  currentType = type;
  if (setModalOpen) {
    setModalOpen(locationId);
  }
};

const PhotosModalContainer = ({t}) => {
  const [isPhotosModalOpen, setIsPhotosModalOpen] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const company = useSelector((state) => state.application.selectedCompany);
  
  setModalOpen = setIsPhotosModalOpen;

  const handleFileSelect = (file) => {
   
    const fileUrl = URL.createObjectURL(file);
    const img = new Image();
    img.src = fileUrl;
  
    img.onload = () => {
      if (currentType === 'COVER') {
        const width = img.width;
        const height = img.height;
        const aspectRatio = width / height;
        const expectedRatio = 16 / 9; 
  
        if (Math.abs(aspectRatio - expectedRatio) > 0.01) {
          MySwal.fire({
            type: 'error',
            title: 'Error',
            text: t('modalPhotos.photos.errors.aspectRatio')
          });
          setIsUploading(false);
          URL.revokeObjectURL(fileUrl);
          return;
        }
      }
  
      const currentFileType = {
        'ADDITIONAL': 'GOOGLEIMAGE_ADDITIONAL',
        'PROFILE': 'GOOGLEIMAGE_PROFILE',
        'COVER': 'GOOGLEIMAGE_COVER'
      }[currentType] || 'GOOGLEIMAGE_ADDITIONAL';
  
      setIsUploading(true);
      uploadFile(file, company.id, currentFileType)
        .then((res) => {
          const imageUrl = res.data.data[0];
          setUploadedImageUrl(imageUrl);
        
        })
        .catch((error) => {
          MySwal.fire({
            type: 'error',
            title: 'Error',
            text: t('modalPhotos.photos.errors.uploadError')
          });
        })
        .finally(() => {
          setIsUploading(false);
        });
    };
  
    
    img.onerror = () => {
      MySwal.fire({
        type: 'error',
        title: 'Error',
        text: t('modalPhotos.photos.errors.loadError')
      });
    };
  };
  

  const handleApplyChanges = () => {
    if (!uploadedImageUrl) return;
    
    setIsSaving(true);
    let key;
    switch (currentType) {
        case 'PROFILE':
            key = 'logo';
            break;
        case 'COVER':
            key = 'cover';
            break;
        case 'ADDITIONAL':
            key = 'photoUrl';
            break;
        default:
            key = 'photoUrl';
    }
    //TODO: Proximamente hacer refactor a esto para no pasar null asi.
    publicHandleApply(key, uploadedImageUrl, currentType, null) 
      .then((updateRes) => {
        setIsPhotosModalOpen(undefined);
      })
      .catch((error) => {
        console.error('Error al guardar:', error);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const modalConfig = {
    ADDITIONAL: {
      title: t('modalPhotos.photos.additional.title'),
      maxDimensions: "10000x10000",
      uploadText: t('modalPhotos.photos.additional.uploadText'),
    },
    PROFILE: {
      title: t('modalPhotos.photos.profile.title'),
      maxDimensions: "10000x10000",
      uploadText: t('modalPhotos.photos.profile.uploadText'),
    },
    COVER: {
      title: t('modalPhotos.photos.cover.title'),
      maxDimensions: "10000x10000",
      uploadText: t('modalPhotos.photos.cover.uploadText'),
    }
  };

  return (
    <Modal 
      isOpen={isPhotosModalOpen !== undefined} 
      toggle={() => !isUploading && !isSaving && setIsPhotosModalOpen(undefined)}
      size="lg"
    >
      <ModalHeader toggle={() => !isUploading && !isSaving && setIsPhotosModalOpen(undefined)}>
        {modalConfig[currentType].title}
      </ModalHeader>
      <ModalBody>
        <UxFileUpload
          id={`${currentType}-upload`}
          acceptedFileTypes=".png,.jpg,.jpeg"
          maxFileSize={5}
          maxFileDimensions={modalConfig[currentType].maxDimensions}
          uploadText={modalConfig[currentType].uploadText}
          dragDropText={t('modalPhotos.photos.dragDropText')}
          onFileSelect={handleFileSelect}
          alertFunction={(message) => MySwal.fire({
            type: 'error',
            title: 'Error',
            text: message
          })}
        />
        
        {isUploading && (
          <div className="text-center mt-3">
            {t('modalPhotos.photos.uploading')}
          </div>
        )}

        {uploadedImageUrl && !isUploading && (
          <div className="mt-4">
            <div className="text-center mb-3">
              <img 
                src={uploadedImageUrl} 
                alt="Preview" 
                style={{
                  maxWidth: '100%',
                  maxHeight: '300px',
                  objectFit: 'contain'
                }}
              />
            </div>
            
            <div className="text-center">
              <button 
                className="btn btn-primary"
                onClick={handleApplyChanges}
                disabled={isSaving}
              >
                {isSaving ? t('modalPhotos.photos.saving') : t('modalPhotos.photos.apply')}
              </button>
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

PhotosModalContainer.translations = {
  ...translations
};

export default translate(PhotosModalContainer); 
import React from 'react';

export const GoogleMapsIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 22.2034V4.83388C0 4.77393 0.00291168 4.71433 0.00890622 4.65472C0.0147295 4.59512 0.0234645 4.53586 0.0351111 4.47711C0.0467577 4.41837 0.0613159 4.3603 0.0787858 4.3031C0.0960845 4.24572 0.116295 4.18937 0.139074 4.13405C0.162025 4.07873 0.187545 4.02461 0.215805 3.97186C0.244065 3.9191 0.274723 3.86772 0.30795 3.81788C0.341348 3.76821 0.376973 3.72008 0.414824 3.67384C0.452847 3.62742 0.492925 3.58324 0.535401 3.54076C0.577706 3.49846 0.621894 3.45838 0.668138 3.42036C0.714553 3.38233 0.76251 3.34671 0.81235 3.31348C0.86202 3.28008 0.913402 3.24942 0.966154 3.22116C1.01891 3.1929 1.07303 3.16738 1.12818 3.14443C1.1835 3.12148 1.23985 3.10127 1.29705 3.08397C1.35443 3.0665 1.41232 3.05212 1.47107 3.0403C1.52982 3.02865 1.5889 3.01992 1.64851 3.01409C1.70811 3.0081 1.76771 3.00519 1.82766 3.00519H19.1871L0 22.2034Z" fill="#1EA361"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M0 22.5679V22.2032L19.1871 3.00519H19.5514C19.6112 3.00519 19.6709 3.0081 19.7305 3.01392C19.7901 3.01975 19.8492 3.02865 19.908 3.0403C19.9666 3.05195 20.0246 3.0665 20.0818 3.0838C20.1392 3.10127 20.1955 3.12148 20.2507 3.14426C20.306 3.16721 20.3601 3.1929 20.4129 3.22099C20.4656 3.24925 20.517 3.28008 20.5667 3.31331C20.6165 3.34654 20.6645 3.38216 20.7107 3.42018C20.7572 3.45821 20.8013 3.49846 20.8436 3.54076C20.886 3.58307 20.9262 3.62742 20.9642 3.67367C21.0021 3.72008 21.0377 3.76804 21.0709 3.81788C21.1043 3.86772 21.135 3.91893 21.1632 3.97186C21.1913 4.02461 21.217 4.07873 21.2398 4.13405C21.2628 4.18937 21.283 4.24572 21.3003 4.30293C21.3177 4.3603 21.3321 4.41837 21.3439 4.47711C21.3556 4.53586 21.3643 4.59495 21.3701 4.65455C21.3761 4.71416 21.379 4.77393 21.379 4.83388V5.19355L2.19196 24.3918H1.83365C1.77371 24.3918 1.71411 24.3889 1.6545 24.3829C1.5949 24.377 1.53581 24.3683 1.47706 24.3567C1.41832 24.3448 1.36043 24.3305 1.30305 24.313C1.24584 24.2957 1.18949 24.2755 1.13417 24.2525C1.07902 24.2296 1.0249 24.2041 0.972149 24.1758C0.919396 24.1475 0.868014 24.1169 0.818345 24.0835C0.768504 24.0503 0.720548 24.0146 0.674133 23.9766C0.627889 23.9386 0.5837 23.8985 0.541396 23.8562C0.49892 23.8137 0.458842 23.7695 0.420819 23.7231C0.382968 23.6769 0.347343 23.6288 0.313944 23.5791C0.280717 23.5292 0.250059 23.4779 0.221799 23.4251C0.193539 23.3724 0.168019 23.3182 0.145069 23.2629C0.122289 23.2076 0.102079 23.1512 0.0847804 23.0939C0.0673105 23.0367 0.0527523 22.9786 0.0411057 22.9199C0.0294591 22.8611 0.0207241 22.8018 0.0149008 22.7422C0.00890621 22.6826 0.00599457 22.623 0.00599457 22.5631" fill="#FEDB43"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M2.19922 24.3977L10.6961 15.8958L19.1931 24.3977H2.19922Z" fill="#5384C4"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M19.1936 24.3963L10.6965 15.8872L12.8897 13.6927L21.3927 22.2005V22.5652C21.3927 23.5748 20.5735 24.3939 19.5639 24.3939H19.1996L19.1936 24.3963Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M21.3919 22.2016L12.8828 13.6997L21.3799 5.19788L21.3919 22.2016Z" fill="#C2C2C1"/>
      <path d="M3.81957 5.36817C4.90716 4.87062 6.26861 5.07323 7.15118 5.88867L6.31229 6.7202C5.42903 5.84242 3.76853 6.25862 3.35456 7.41985C2.81283 8.56208 3.71287 10.058 4.98629 10.0802C5.82518 10.1774 6.65089 9.61849 6.82576 8.78816C6.24463 8.78936 5.66333 8.78388 5.08802 8.79227L5.07723 7.71496L8.00327 7.75452C8.0764 8.46565 8.02605 9.22097 7.64377 9.84526C7.04448 10.9621 5.62856 11.4496 4.43068 11.1678C2.9495 10.8609 1.84085 9.34034 2.04346 7.83416C2.14708 6.77175 2.86438 5.80885 3.83036 5.36885" fill="#EFEFEF"/>
      <mask id="mask0_9751_5314" maskType="luminance" maskUnits="userSpaceOnUse" x="12" y="0" width="12" height="19">
        <path d="M12.0293 0H23.9993V18.8457H12.0293V0Z" fill="white"/>
      </mask>
      <g mask="url(#mask0_9751_5314)">
        <path d="M17.652 -0.0078125H18.3472C20.0849 0.0916974 21.7728 0.938303 22.8232 2.33059C23.5112 3.21076 23.8947 4.30914 23.981 5.41231V5.82422C23.9408 7.97439 22.5656 9.73937 21.3744 11.4122C20.0381 13.302 18.8026 15.3616 18.4597 17.6909C18.3681 18.0855 18.4777 18.6382 18.0068 18.8164C17.8959 18.73 17.7671 18.6586 17.6891 18.5375C17.5495 17.2353 17.2241 15.9492 16.6694 14.7603C16.0401 13.3812 15.1623 12.1221 14.2712 10.8972C13.7289 10.1621 13.2167 9.40196 12.7936 8.59132C12.1344 7.37648 11.8809 5.9412 12.1524 4.57769C12.3754 3.50158 12.9313 2.50443 13.7175 1.73335C14.7674 0.706398 16.1864 0.084504 17.652 -0.00712741M17.5167 3.78025C16.6957 3.96385 16.0053 4.69159 15.9358 5.54299C15.8495 6.33565 16.3133 7.13978 17.0229 7.48986C17.7922 7.89749 18.8026 7.7716 19.4455 7.19013C20.0459 6.65884 20.2641 5.7415 19.9506 4.9992C19.5791 4.06987 18.4789 3.54115 17.516 3.78812" fill="#DC4B3E"/>
      </g>
      <path d="M17.5175 3.77942C18.4799 3.53963 19.5812 4.06116 19.9521 4.99049C20.2656 5.73279 20.0469 6.65013 19.4471 7.18142C18.8041 7.77009 17.7932 7.90077 17.0244 7.48115C16.3143 7.13107 15.851 6.32694 15.9373 5.53429C16.0069 4.68289 16.6966 3.95566 17.5182 3.77154" fill="#802C27"/>
    </svg>
  )
}
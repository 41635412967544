

export const processParameters = (...params) => {
    const paramsObj = new URLSearchParams();
    params.forEach(([value, key]) => {
        if (value) {
            let parsedValue = value;
            if (Object.prototype.toString.call(value) === '[object Date]') {
                parsedValue = parsedValue.toISOString();
            }
            paramsObj.append(key, parsedValue);
        }
    })
    return paramsObj;
}

import React from 'react';
import { UxDataGroupTabs } from 'botbit-ui-components'; 
import ReviewsChart from './ReviewsChart';
import ReviewsByStars from './ReviewsByStars';

const ReputationCharts = ({ storeId, selectedPeriod}) => {

  return (
    <div className="reputation-charts-container">
      <UxDataGroupTabs 
        tabs={[
          {
            id: 'reviews-over-time',
            label: 'Evolución de Reviews',
            content: (
              <div className="chart-section">
                <ReviewsChart 
                  storeId={storeId} 
                  selectedPeriod={selectedPeriod} 
                />
              </div>
            )
          },
          {
            id: 'reviews-by-stars',
            label: 'Reviews por Estrellas',
            content: (
              <div className="chart-section">
                <ReviewsByStars 
                  storeId={storeId}
                  selectedPeriod={selectedPeriod}
                />
              </div>
            )
          }
        ]}
      />
    </div>
  );
};

export default ReputationCharts;
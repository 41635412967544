import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js';
import { createStarImage } from '../reputationTable/RepTableIcons';
import { getReviewsInsightsEvolutionGoogle } from '../../../api/dashboardV2Api';
import translate from "../../../config/translations/translate";

ChartJS.elements.Rectangle.prototype.draw = function() {
  const ctx = this._chart.ctx;
  const vm = this._view;
  let left, right, top, bottom;

  left = vm.x - vm.width / 2;
  right = vm.x + vm.width / 2;
  top = vm.y;
  bottom = vm.base;

  ctx.beginPath();
  ctx.fillStyle = vm.backgroundColor;
  ctx.strokeStyle = vm.borderColor;
  ctx.lineWidth = vm.borderWidth;

  const radius = 10;
  
  ctx.moveTo(left, bottom);
  ctx.lineTo(left, top + radius);
  ctx.quadraticCurveTo(left, top, left + radius, top);
  ctx.lineTo(right - radius, top);
  ctx.quadraticCurveTo(right, top, right, top + radius);
  ctx.lineTo(right, bottom);
  ctx.lineTo(left, bottom);

  ctx.fill();
  if (vm.borderWidth) {
    ctx.stroke();
  }
};

const ReviewsChart = ({ storeId, selectedPeriod, t }) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const fetchEvolutionData = async () => {
      try {
        const response = await getReviewsInsightsEvolutionGoogle(storeId, selectedPeriod);
        
        if (response.data.data && response.data.data.length > 0) {
          setChartData(response.data.data);
          console.log('Evolution chart data set:', response.data.data);
        }
      } catch (error) {
        console.error('Error fetching evolution data:', error);
      }
    };

    if (storeId) {
      fetchEvolutionData();
    }
  }, [storeId, selectedPeriod]);

  const starImage = new Image();
  starImage.src = createStarImage();

  const maxReviews = Math.max(...chartData.map(item => item.reviews || 0));
  
  let yMaxReviews;
  if (maxReviews === 0) {
    yMaxReviews = 5; 
  } else if (maxReviews <= 5) {
    yMaxReviews = 5;
  } else if (maxReviews <= 10) {
    yMaxReviews = 10;
  } else if (maxReviews <= 50) {
    yMaxReviews = Math.ceil(maxReviews / 10) * 10; 
  } else if (maxReviews <= 100) {
    yMaxReviews = Math.ceil(maxReviews / 20) * 20; 
  } else {
    yMaxReviews = Math.ceil(maxReviews / 100) * 100;
  }

  // Función para formatear fechas según el período
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    
    // Si es un período mensual o mayor, muestro el mes y el año
    if (['LAST_3_MONTHS', 'LAST_6_MONTHS', 'LAST_12_MONTHS', 'THIS_YEAR'].includes(selectedPeriod)) {
      const monthNames = [
        'january', 'february', 'march', 'april', 'may', 'june', 'july',
        'august', 'september', 'october', 'november', 'december'
      ];
      const monthKey = monthNames[date.getMonth()];
      const month = t(`months.${monthKey}`);
      const year = date.getFullYear();
      
      return month.charAt(0).toUpperCase() + month.slice(1) + ' ' + year;
    }
    
    // Para períodos diarios, muestro la fecha completa
    return date.toLocaleDateString(undefined, { 
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    scales: {
      xAxes: [{
        gridLines: {
          display: false
        }
      }],
      yAxes: [{
        id: 'estrellas',
        position: 'left',
        ticks: {
          beginAtZero: true,
          max: 5,
          stepSize: 1,
          fontFamily: "'Open Sans', sans-serif",
          fontSize: 12,
          fontColor: '#1F2D3D'
        },
        gridLines: {
          color: '#F8F9FA',
          drawBorder: false,
          zeroLineColor: '#E0E0E0'
        }
      }, {
        id: 'reviews',
        position: 'right',
        ticks: {
          beginAtZero: true,
          max: yMaxReviews,
          stepSize: Math.ceil(yMaxReviews / 6),
          fontFamily: "'Open Sans', sans-serif",
          fontSize: 12,
          fontColor: '#1F2D3D'
        },
        gridLines: {
          display: false
        }
      }]
    },
    elements: {
      point: {
        pointStyle: starImage,
        radius: 12,
        hoverRadius: 14,
        backgroundColor: 'transparent',
        borderColor: 'transparent'
      }
    }
  };

  const chartDataConfig = {
    labels: chartData.map(item => formatDate(item.date)),
    datasets: [
      {
        type: 'bar',
        label: t('reviews'),
        data: chartData.map(item => item.reviews),
        backgroundColor: '#85A8CC',
        yAxisID: 'reviews',
        borderRadius: 10,
        borderSkipped: false,
        barThickness: 24,      
        maxBarThickness: 24,
        order: 2     
      },
      {
        type: 'line',
        label: t('average'),
        data: chartData.map(item => item.score),
        borderColor: '#F0B747',
        backgroundColor: 'transparent',
        yAxisID: 'estrellas',
        borderWidth: 1,
        fill: false,
        tension: 0.4,
        order: 1     
      }
    ]
  };

  return (
    <div style={{ 
      backgroundColor: '#F8F9FA',
      padding: '20px'
    }}>
      <div style={{ 
        position: 'relative',
        height: '308px'
      }}>
        <Bar data={chartDataConfig} options={options} />
      </div>
    </div>
  );
};

ReviewsChart.translations = {
  reviews: {
    es: "Reseñas",
    en: "Reviews"
  },
  average: {
    es: "Promedio",
    en: "Average"
  },
  months: {
    january: {
      es: "enero",
      en: "January"
    },
    february: {
      es: "febrero",
      en: "February"
    },
    march: {
      es: "marzo",
      en: "March"
    },
    april: {
      es: "abril",
      en: "April"
    },
    may: {
      es: "mayo",
      en: "May"
    },
    june: {
      es: "junio",
      en: "June"
    },
    july: {
      es: "julio",
      en: "July"
    },
    august: {
      es: "agosto",
      en: "August"
    },
    september: {
      es: "septiembre",
      en: "September"
    },
    october: {
      es: "octubre",
      en: "October"
    },
    november: {
      es: "noviembre",
      en: "November"
    },
    december: {
      es: "diciembre",
      en: "December"
    }
  },
  noDataAvailable: {
    es: "No hay datos disponibles",
    en: "No data available"
  }
};

const TranslatedReviewsChart = translate(ReviewsChart);
export { TranslatedReviewsChart as ReviewsChartComponent };
export default TranslatedReviewsChart;
import * as Yup from "yup";
import { SocialLocationPostCallToActionType } from "./SocialLocationPostCallToActionType";

function mediaValidation(t) {
  return Yup.array()
    .of(
      Yup.object().shape({
        sourceUrl: Yup.string().required(
          t("panels.socialLocationPost.form.errors.media.sourceUrl")
        ),
      })
    )
    .max(10, t("panels.socialLocationPost.form.errors.media.maxImages"));
}

function callToActionValidations(t) {
  return Yup.object().shape({
    actionType: Yup.string().required(
      t("panels.socialLocationPost.form.errors.callToAction.actionType")
    ),
    url: Yup.string().when("actionType", {
      is: SocialLocationPostCallToActionType.CALL,
      then: Yup.string(),
      otherwise: Yup.string().when("actionType", {
        is: SocialLocationPostCallToActionType.ACTION_TYPE_UNSPECIFIED,
        then: Yup.string(),
        otherwise: Yup.string()
          .url(
            t("panels.socialLocationPost.form.errors.callToAction.urlNotValid")
          )
          .required(
            t("panels.socialLocationPost.form.errors.callToAction.url")
          ),
      }),
    }),
  });
}
export const eventValidationSchema = (t) =>
  Yup.object().shape({
    summary: Yup.string().required(
      t("panels.socialLocationPost.form.errors.summary")
    ),
    topicType: Yup.string().required(
      t("panels.socialLocationPost.form.errors.topicType")
    ),
    event: Yup.object().shape({
      schedule: Yup.object().shape({
        startDate: Yup.string().required(
          t("panels.socialLocationPost.form.errors.event.schedule.startDate")
        ),
        startTime: Yup.object().shape({
          hours: Yup.number(),
        }),
        endDate: Yup.string()
          .required(t("panels.socialLocationPost.form.errors.event.schedule.endDate"))
          .test(
            "is-after-start-datetime",
            t("panels.socialLocationPost.form.errors.event.schedule.endDateTimeBeforeStart"),
            function(endDate) {
              const { startDate, startTime, endTime } = this.parent;
              
              if (!startDate || !startTime || !endDate || !endTime) return true;
              
              const startDateTime = new Date(startDate);
              startDateTime.setHours(startTime.hours || 0);
              
              const endDateTime = new Date(endDate);
              endDateTime.setHours(endTime.hours || 0);
              
              return endDateTime >= startDateTime;
            }
          ),
        endTime: Yup.object().shape({
          hours: Yup.number(),
        }),
      }),
      title: Yup.string()
        .required(t("panels.socialLocationPost.form.errors.event.title"))
        .max(58, t("panels.socialLocationPost.form.errors.event.titleMax")),
    }),
    media: mediaValidation(t),
    callToAction: callToActionValidations(t),
  });

export const offerValidationSchema = (t) =>
  Yup.object().shape({
    summary: Yup.string().required(
      t("panels.socialLocationPost.form.errors.summary")
    ),
    topicType: Yup.string().required(
      t("panels.socialLocationPost.form.errors.topicType")
    ),
    event: Yup.object().shape({
      schedule: Yup.object().shape({
        startDate: Yup.string().required(
          t("panels.socialLocationPost.form.errors.event.schedule.startDate")
        ),

        endDate: Yup.string().required(
          t("panels.socialLocationPost.form.errors.event.schedule.endDate")
        )
        .test(
          "is-after-start-date",
          t("panels.socialLocationPost.form.errors.event.schedule.endDateBeforeStart"),
          function(endDate) {
            const { startDate } = this.parent;
            if (!startDate || !endDate) return true;
            return new Date(endDate) >= new Date(startDate);
          }
        ),
      }),
      title: Yup.string()
        .required(t("panels.socialLocationPost.form.errors.event.title"))
        .max(58, t("panels.socialLocationPost.form.errors.event.titleMax")),
    }),
    media: mediaValidation(t),
    callToAction: callToActionValidations(t),
    offer: Yup.object().shape({
      couponCode: Yup.string(),
      termsConditions: Yup.string(),
      redeemOnlineUrl: Yup.string()
        .url(
          t("panels.socialLocationPost.form.errors.callToAction.urlNotValid")
        ),
    }),
  });

export const updateValidationSchema = (t) =>
  Yup.object().shape({
    summary: Yup.string().required(
      t("panels.socialLocationPost.form.errors.summary")
    ),
    topicType: Yup.string().required(
      t("panels.socialLocationPost.form.errors.topicType")
    ),
    callToAction: callToActionValidations(t),
    media: mediaValidation(t),
  });

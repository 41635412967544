import React, { useState, useEffect } from 'react';
import {
  Table,
  UxPerformanceIndicator,
  UxPagination,
  UxTooltip
  //no me estaba andando el tooltip.
} from "botbit-ui-components";
import { getReviewsRankingData } from '../../../api/dashboardV2Api';
import './ReputationTable.css';
import { StarIcon, QuestionMarkIcon, ChartArrowIcon, QuestionMarkBoldIcon } from './RepTableIcons';
import translate from "../../../config/translations/translate";
import { ReputationChartModal } from '../Modals/localSeoStoreChartModal/reputationChartModal/ReputationChartModal';

const ORDER_TYPE_MAP = {
  'reviews': {
    asc: 'TOTAL_REVIEWS_ASC',
    desc: 'TOTAL_REVIEWS_DESC'
  },
  'averageScore': {
    asc: 'AVERAGE_SCORE_ASC',
    desc: 'AVERAGE_SCORE_DESC'
  },
  'oneStar': {
    asc: 'TOTAL_1_STAR_REVIEWS_ASC',
    desc: 'TOTAL_1_STAR_REVIEWS_DESC'
  },
  'twoStars': {
    asc: 'TOTAL_2_STAR_REVIEWS_ASC',
    desc: 'TOTAL_2_STAR_REVIEWS_DESC'
  },
  'threeStars': {
    asc: 'TOTAL_3_STAR_REVIEWS_ASC',
    desc: 'TOTAL_3_STAR_REVIEWS_DESC'
  },
  'fourStars': {
    asc: 'TOTAL_4_STAR_REVIEWS_ASC',
    desc: 'TOTAL_4_STAR_REVIEWS_DESC'
  },
  'fiveStars': {
    asc: 'TOTAL_5_STAR_REVIEWS_ASC',
    desc: 'TOTAL_5_STAR_REVIEWS_DESC'
  },
  'googleMapsScore': {
    asc: 'MAPS_ACUMULATED_SCORE_ASC',
    desc: 'MAPS_ACUMULATED_SCORE_DESC'
  }
};



const ReputationTable = ({ companyId, t, period}) => {
  const [totalPages, setTotalPages] = useState(0);
  const [selectedPage, setSelectedPage] = useState(0);
  const filterValues = { timePeriod: period, page: selectedPage, pageSize: 6 }
  const [selectedStore, setSelectedStore] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  
  const getTableData = async (page, currentOrder = { name: 'reviews', direction: 'desc' }, filters) => {

    const mappedOrderType = ORDER_TYPE_MAP[currentOrder.name][currentOrder.direction];

    try {
      const response = await getReviewsRankingData(
        companyId,
        filters.timePeriod,
        mappedOrderType,
        filters.page,
        filters.pageSize
      );

      
      // le agrego el ranking por front.
      if (response && response.data && response.data.data && response.data.data.items) {
        response.data.data.items = response.data.data.items.map((item, index) => ({
          ...item,
          ranking: (filters.page * filters.pageSize) + index + 1
        }));
      }

      //total de paginas.
      setTotalPages(Math.ceil(response.data.data.totalSize / response.data.data.pageSize));
      
      return response;
    } catch (error) {
      console.error('Error en getTableData:', error);
      throw error;
    }
  };
  
  //falta el paginador para esta.
  const handlePageChange = (newPage) => {
    // la pagina que me da el paginador, hay que restarle 1 porque la api es 0-based.
    setSelectedPage(newPage - 1);
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const handleCreateStoreObject = (row) => {
    const store = {
      id: row.storeId,
      name: row.storeName
    };
    setSelectedStore(store);
  };


  const columns = [
    {dataField: 'ranking',
     title: ' ',
     size: 4,
     formatter: (cellValue, row) => <span className="reputation-table-various-spans-number-bold">#{row.ranking}</span>,
    },
    {
      
      //el data field tiene que coincidir con el key de data. 
      //el formatter me re-renderiza cada elemento de la tabla, el cellvalue es el valor y el row es el objeto completo de la fila
      dataField: 'storeName',
      title: <div className="reputation-table-title-container">
        <span 
          className="reputation-table-title-span"
          dangerouslySetInnerHTML={{ __html: t('columns.storeRanking') }} 
        />{/* OJO: aqui viene html en la traduccion, y dangerouslySetInnerHTML> es mas peligroso que el salto de linea */}
      </div>,
      size: 16,
      formatter: (cellValue, row) => {
        const storeName = row.storeName;
        
        return (
            <span className="reputation-table-various-spans-number-bold text-left"> {storeName} </span>
        )
      },
    },
    {
      dataField: 'reviews',
      title: (
        <div className="reputation-table-title-container">
          <span 
            className="reputation-table-title-span"
            dangerouslySetInnerHTML={{ __html: t('columns.generatedReviews') }}
            // OJO: aqui viene html en la traduccion, y dangerouslySetInnerHTML> es mas peligroso que el salto de linea
          /> 
        </div>
      ),
      size: 16,
      sortable: true,
      formatter: (cellValue, row) => (
        <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
          <span className="reputation-table-various-spans-number">{row.generatedReviews.value}</span>
          {row.generatedReviews.variationPercentage && (
            <UxPerformanceIndicator value={row.generatedReviews.variationPercentage} />
          )}
        </div>
      ),
      tooltip: {
        title: t('columns.generatedReviews.tooltip.title'),
        content: t('columns.generatedReviews.tooltip.content')
      }
      
    },
    {
      dataField: 'fiveStars',
      title: (
        <div className="reputation-table-title-container">
          <StarIcon />
          <span className="reputation-table-title-span">5</span>
        </div>
      ),
      size: 6,
      sortable: true,
      formatter: (cellValue, row) => <span className="reputation-table-various-spans-stars-number">{row.normalizedScoreCount[4]['5']}</span>,
    },
    {
      dataField: 'fourStars',
      title: (
        <div className="reputation-table-title-container">
          <StarIcon />
          <span className="reputation-table-title-span">4</span>
        </div>
      ),
      size: 6,
      formatter: (cellValue, row) => <span className="reputation-table-various-spans-stars-number">{row.normalizedScoreCount[3]['4']}</span>,
      sortable: true,
    },
    {
      dataField: 'threeStars',
      title: (
        <div className="reputation-table-title-container">
          <StarIcon />
          <span className="reputation-table-title-span">3</span>
        </div>
      ),
      size: 6,
      formatter: (cellValue, row) => <span className="reputation-table-various-spans-stars-number">{row.normalizedScoreCount[2]['3']}</span>,
      sortable: true,
    },
    {
      dataField: 'twoStars',
      title: (
        <div className="reputation-table-title-container">
          <StarIcon />
          <span className="reputation-table-title-span">2</span>
        </div>
      ),
      size: 6,
      formatter: (cellValue, row) => <span className="reputation-table-various-spans-stars-number">{row.normalizedScoreCount[1]['2']}</span>,
      sortable: true,
    },
    {
      dataField: 'oneStar',
      title: (
        <div className="reputation-table-title-container">
          <StarIcon />
          <span className="reputation-table-title-span">1</span>
        </div>
      ),
      size: 8,
      formatter: (cellValue, row) => <span className="reputation-table-various-spans-stars-number">{row.normalizedScoreCount[0]['1']}</span>,
      sortable: true,
    },
    {
      dataField: 'averageScore',
      title: <div className="reputation-table-title-container">
        <span 
          className="reputation-table-title-span"
          dangerouslySetInnerHTML={{ __html: t('columns.averageScore') }}
        />
        
      </div>,
      size: 14,
      sortable: true,
      formatter: (cellValue, row) => <span className="reputation-table-various-spans-number-bold-bigger">
        {Number(row.scoreAverageInThePeriod).toFixed(1)}
      </span>,
      tooltip: {
        title: t('columns.averageScore.tooltip.title'),
        content: t('columns.averageScore.tooltip.content')
      }
    },
    {
      dataField: 'googleMapsScore',
      title: <div className="reputation-table-title-container">
        <span 
          className="reputation-table-title-span"
          dangerouslySetInnerHTML={{ __html: t('columns.googleMaps') }}
        />
       
      </div>,
      size: 14,
      sortable: true,
      formatter: (cellValue, row) => <span className="reputation-table-various-spans-number-rating-maps">{row.scoreAverageShowedByGoogleMaps}</span>,
      tooltip: {
        title: t('columns.googleMaps.tooltip.title'),
        content: t('columns.googleMaps.tooltip.content')
      }
    },
    {
      dataField: 'details',
      title: '',
      size: 4,
      formatter: (cellValue, row) => {
        const handleRedirect = () => {
          handleCreateStoreObject(row);
          setIsModalOpen(true);
        };
        
        return (
          <button className="reputation-table-details-button" onClick={handleRedirect}>
            <ChartArrowIcon />
          </button>
        );
      },
    }

  ];

  return (
    <div className="reputation-table-container">
      <div className="reputation-table-header-container">
        <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
          <h2 className="reputation-table-title">{t('title')}</h2>
          <div className="reputation-table-title-tooltip-container">
            <QuestionMarkBoldIcon/>
            <UxTooltip title="Tooltip incorporado a la Table" content="Tooltip incorporado a la Table" />
          </div>
        </div>
      </div>
      <div className="reputation-table-body-and-pagination-container">
          <Table
            pagination={false}
            getData={getTableData}
            //importante: la tabla se actualiza apenas cambia o detecta un valor en filterValues, por eso no usamos useEffect, lo maneja internamente.
            filterValues={filterValues}
            columns={columns}
            showMobileColumnTitles={false}
          />
        <div className="pagination-container">
          <UxPagination
              totalPages={totalPages}
              // las paginas de la api son 0-based, por eso le sumo 1. (el paginador es 1-based)
            currentPage={filterValues.page + 1}
                onPageChange={handlePageChange}
              />
        </div>
      </div>
      <div className="reputation-table-modal-container">
        {selectedStore && (
          <ReputationChartModal
            store={selectedStore}
            isOpen={isModalOpen}
            toggle={toggleModal}
            selectedPeriod={period}
            t={t}
          />
        )}
      </div>
    </div>
  );
};

ReputationTable.translations = {
  title: {
    es: "Ranking sucursales por review",
    en: "Store ranking by review"
  },
  timePeriod: {
    label: {
      es: "Período de tiempo",
      en: "Time period"
    },
    options: {
      LAST_7_DAYS: {
        es: "Últimos 7 días",
        en: "Last 7 days"
      },
      LAST_30_DAYS: {
        es: "Últimos 30 días",
        en: "Last 30 days" 
      },
      THIS_MONTH: {
        es: "Este mes",
        en: "This month"
      },
      LAST_3_MONTHS: {
        es: "Últimos 3 meses",
        en: "Last 3 months"
      },
      LAST_6_MONTHS: {
        es: "Últimos 6 meses",
        en: "Last 6 months"
      },
      LAST_12_MONTHS: {
        es: "Últimos 12 meses", 
        en: "Last 12 months"
      },
      THIS_YEAR: {
        es: "Este año",
        en: "This year"
      }
    }
  },
  columns: {
    storeRanking: {
      es: "Ranking<br/>Sucursales",
      en: "Store<br/>Ranking"
    },
    generatedReviews: {
      es: "Reviews<br/>Generados",
      en: "Generated<br/>Reviews",
      tooltip: {
        title: {
          es: "Reviews generados",
          en: "Generated reviews"
        },
        content: {
          es: "Reviews generados en el periodo seleccionado",
          en: "Reviews generated in the selected period"
        }
      }
    },
    averageScore: {
      es: "Score Promedio<br/>Periodo",
      en: "Average Score<br/>Period",
      tooltip: {
        title: {
          es: "Score promedio periodo",
          en: "Average period score"
        },
        content: {
          es: "Score promedio de reviews generados en el periodo seleccionado",
          en: "Average score of reviews generated in the selected period"
        }
      }
    },
    googleMaps: {
      es: "Google Maps<br/>Periodo Acumulado",
      en: "Google Maps<br/>Period Accumulated",
      tooltip: {
        title: {
          es: "Google Maps Periodo Acumulado",
          en: "Google Maps Accumulated Period"
        },
        content: {
          es: "Score de Reviews histórico que muestra Google Maps",
          en: "Historical review score shown on Google Maps"
        }
      }
    }
  }
};

export default translate(ReputationTable);
import React, { useState, useEffect, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js';
import { createStarImage } from '../reputationTable/RepTableIcons';
import { getReviewsDataByStore } from '../../../api/dashboardV2Api';

ChartJS.elements.Rectangle.prototype.draw = function() {
  const ctx = this._chart.ctx;
  const vm = this._view;
  let left, right, top, bottom;

  left = vm.x - vm.width / 2;
  right = vm.x + vm.width / 2;
  top = vm.y;
  bottom = vm.base;

  ctx.beginPath();
  ctx.fillStyle = vm.backgroundColor;
  ctx.strokeStyle = vm.borderColor;
  ctx.lineWidth = vm.borderWidth;

  const radius = 10;
  
  ctx.moveTo(left, bottom);
  ctx.lineTo(left, top + radius);
  ctx.quadraticCurveTo(left, top, left + radius, top);
  ctx.lineTo(right - radius, top);
  ctx.quadraticCurveTo(right, top, right, top + radius);
  ctx.lineTo(right, bottom);
  ctx.lineTo(left, bottom);

  ctx.fill();
  if (vm.borderWidth) {
    ctx.stroke();
  }
};

const ReviewsByStars = ({ storeId, selectedPeriod }) => {
  const [reviewsStarsData, setReviewsStarsData] = useState(null);
  const chartRef = useRef(null);
  const starImage = createStarImage();

  useEffect(() => {
    const fetchStarsData = async () => {
      try {
        const response = await getReviewsDataByStore(storeId, selectedPeriod);
        console.log('response reviews by stars', response);
        if (response.data && response.data.data) {
          setReviewsStarsData(response.data);
          console.log('Stars chart data set:', response.data);
        }
      } catch (error) {
        console.error('Error fetching stars data:', error);
      }
    };

    if (storeId) {
      fetchStarsData();
    }
  }, [storeId, selectedPeriod]);

  if (!reviewsStarsData || !reviewsStarsData.data || !reviewsStarsData.data.normalizedScoreCount) {
    return <div>Loading...</div>; 
  }

  const starsData = {
    '5': reviewsStarsData.data.normalizedScoreCount[4]['5'] || 0,
    '4': reviewsStarsData.data.normalizedScoreCount[3]['4'] || 0,
    '3': reviewsStarsData.data.normalizedScoreCount[2]['3'] || 0,
    '2': reviewsStarsData.data.normalizedScoreCount[1]['2'] || 0,
    '1': reviewsStarsData.data.normalizedScoreCount[0]['1'] || 0
  };

  const maxReviews = Math.max(...Object.values(starsData));
  
  let yMaxReviews;
  if (maxReviews === 0) {
    yMaxReviews = 5; 
  } else if (maxReviews <= 5) {
    yMaxReviews = 5;
  } else if (maxReviews <= 10) {
    yMaxReviews = 10;
  } else if (maxReviews <= 50) {
    yMaxReviews = Math.ceil(maxReviews / 10) * 10; 
  } else if (maxReviews <= 100) {
    yMaxReviews = Math.ceil(maxReviews / 20) * 20; 
  } else {
    yMaxReviews = Math.ceil(maxReviews / 100) * 100;
  }

  const chartData = {
    labels: ['5', '4', '3', '2', '1'],
    datasets: [{
      data: [
        starsData['5'],
        starsData['4'],
        starsData['3'],
        starsData['2'],
        starsData['1']
      ],
      backgroundColor: '#85A8CC',
      borderColor: '#85A8CC',
      borderWidth: 0,
      borderRadius: 10,
      barThickness: 24,      
      maxBarThickness: 24     
    }]
  };

  const calculateStepSize = (max) => {
    if (max <= 10) return 2;
    if (max <= 20) return 5;
    if (max <= 50) return 10;
    if (max <= 100) return 20;
    if (max <= 500) return 100;
    return Math.ceil(max / 5 / 100) * 100;
  };

  const stepSize = calculateStepSize(yMaxReviews);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          stepSize: stepSize,
          max: yMaxReviews,
          fontFamily: "'Open Sans', sans-serif",
          fontSize: 12,
          fontStyle: '400',
          fontColor: '#1F2D3D',
          padding: 30
        },
        gridLines: {
          color: '#F8F9FA',
          drawBorder: false,
          zeroLineColor: '#E0E0E0'
        }
      }],
      xAxes: [{
        gridLines: {
          display: false,
          drawBorder: false
        },
        ticks: {
          fontFamily: "'Open Sans', sans-serif",
          fontSize: 12,
          fontColor: '#1F2D3D',
          padding: 30 
        },
      }]
    },
    legend: {
      display: false
    },
    tooltips: {
      enabled: false
    },
    elements: {
      rectangle: {
        borderWidth: 0,
        borderRadius: 10,
        borderSkipped: 'bottom'
      }
    }
  };

  const customPlugin = {
    afterDraw: (chart) => {
      const ctx = chart.ctx;
      const xAxis = chart.scales['x-axis-0'];
      const yAxis = chart.scales['y-axis-0'];
      
      ctx.textAlign = 'center';
      ctx.textBaseline = 'bottom';
      ctx.font = "12px 'Open Sans'";
      ctx.fillStyle = '#1F2D3D';
      
      chart.data.datasets[0].data.forEach((value, index) => {
        const x = xAxis.getPixelForValue(index);
        const y = yAxis.getPixelForValue(value);
        
        ctx.fillText(value, x, y - 10);
        
        const img = new Image();
        img.src = starImage;
        
        const starSize = 15;
        const starY = xAxis.top + 15;
        ctx.drawImage(img, x - starSize/2, starY, starSize, starSize);
      });
    }
  };

  return (
    <div style={{ 
      backgroundColor: '#F8F9FA',
      padding: '40px'
    }}>
      <div style={{ 
        position: 'relative',
        height: '280px'
      }}>
        <Bar 
          ref={chartRef}
          data={chartData} 
          options={options} 
          plugins={[customPlugin]}
        />
      </div>
    </div>
  );
};

export default ReviewsByStars;
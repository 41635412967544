import React, { useState } from 'react';
import {
  Table,
  UxPerformanceIndicator,
  UxPagination,
} from "botbit-ui-components";
import { getLocalSEOTableData } from '../../../api/dashboardV2Api';
import './localSEOTable.css';
import {
    GoogleMapsIcon, 
    NavigationIcon,
    CallIcon,
    VisitsIcon,
    WebsiteClicksIcon, 
    ChartArrowIcon, 
} from './localSEOTableIcons';
import translate from "../../../config/translations/translate";
import { LocalSeoStoreChartModal } from '../Modals/localSeoStoreChartModal/LocalSeoStoreChartModal';


const localSEOTable = ({companyId, period, t}) => {

    const [totalPages, setTotalPages] = useState(0);

    const [totalLocations, setTotalLocations] = useState(0);

    const [selectedPage, setSelectedPage] = useState(0);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedStore, setSelectedStore] = useState(null);

    const filterValues = { timePeriod: period, page: selectedPage, pageSize: 6 }

    const getTableData = async (page, currentOrder, filters) => {
        try {
            const response = await getLocalSEOTableData(
                companyId,
                filters.page,
                filters.timePeriod,            
                filters.pageSize
            );

           
            // seteo el total de paginas y el total de locales
            setTotalPages(response.data.data.totalPages);
            setTotalLocations(response.data.data.totalLocations);

            return response;
        } catch (error) {
            console.error('Error en getTableData:', error);
            throw error;
        }
    }

    const handlePageChange = (newPage) => {
        setSelectedPage(newPage - 1);
    };
    

    const toggleModal = () => setIsModalOpen(!isModalOpen);

    const handleCreateStoreObject = (row) => {
        const store = {
          id: row.storeId,
          name: row.name
        };
        setSelectedStore(store);
      };

    const columns = [
        {
            dataField: 'storeName',
            title: 
                <div className="local-seo-table-title-container">
                    <span className="local-seo-table-title-span-store-name">
                        { t('columns.stores') + ` (${totalLocations})`}
                    </span>
                        
                     
                </div>,
            size: 22,
            formatter: (cellValue, row) => {
                console.log("row local seo table", row);
                const storeName = row.name;
                return (
                    <span className="local-seo-table-various-spans-number-bold"> {storeName} </span>
                )
            }
        },
        {
           
            dataField: 'ranking',
            title: 
                <div className="local-seo-table-title-container">
                    <span className="local-seo-table-title-span"> {t('columns.ranking')} </span> 
                </div>,
            size: 7,
            formatter: (cellValue, row) => (
                <span className="local-seo-table-various-spans-number">
                    {Number(row.ranking).toFixed(1)}
                </span>
            ),
            
        },
        {
            dataField: 'gbpAuditScore',
            title: 
                <div className="local-seo-table-title-container">
                    <span className="local-seo-table-title-span">
                        {t('columns.gbpAuditScore')}
                    </span>
                </div>,
            size: 8,
            formatter: (cellValue, row) => <span className="local-seo-table-various-spans-number"><b>{row.gbpAuditScore}/</b>100</span>,
            
        },
        {
            dataField: 'navigation',
            title: 
                <div className="local-seo-table-title-container">
                    
                    <span className="local-seo-table-title-span">
                        <NavigationIcon/>
                        {t('columns.navigation')}
                    </span>

                </div>,
            size: 12,
            formatter: (cellValue, row) => (
                <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                    <span className="local-seo-table-various-spans-number">{row.navigation.value}</span>
                    {(row.navigation.variationPercentage !== 0) && (
                        <UxPerformanceIndicator value={row.navigation.variationPercentage} />
                    )}
                </div>
            )
        },
        {
            dataField: 'navigation12Months',
            title: 
                <div className="local-seo-table-title-container">
                    <span className="local-seo-table-title-span">
                        <NavigationIcon/>
                        <span className="navigation-text">
                            {t('columns.navigation12Months')}
                        </span>  
                    </span>
                </div>,
            size: 12,
            formatter: (cellValue, row) => {
                let navigationLastYearValue = 0;
                let navigationLastYearVariationPercentage = 0;
                if(row.navigationLastYear != null) {
                    navigationLastYearValue = row.navigationLastYear.value;
                    navigationLastYearVariationPercentage = row.navigationLastYear.variationPercentage;
                }
                return (
                    <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                        <span className="local-seo-table-various-spans-number">{navigationLastYearValue}</span>
                    </div>
                )
            }
            
            
            
        },
        {
            dataField: 'calls',
            title: 
                <div className="local-seo-table-title-container">
                    <span className="local-seo-table-title-span">
                        <CallIcon/>
                        {t('columns.calls')}
                    </span>
                </div>,
            size: 12,
            formatter: (cellValue, row) => (
                <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                    <span className="local-seo-table-various-spans-number">{row.calls.value}</span>
                    {(row.calls.variationPercentage !== 0) && (
                        <UxPerformanceIndicator value={row.calls.variationPercentage } />
                    )}
                </div>
            )
            
        },
        {
            dataField: 'visits',
            title: 
                <div className="local-seo-table-title-container">
                    <span className="local-seo-table-title-span">
                        <VisitsIcon/>
                        {t('columns.visits')}
                    </span>
                </div>,
            size: 14,
            formatter: (cellValue, row) => (
                <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                    <span className="local-seo-table-various-spans-number">{row.visits.value}</span>
                    {(row.visits.variationPercentage !== 0) && (
                        <UxPerformanceIndicator value={row.visits.variationPercentage} />
                    )}
                </div>
            )
            
            
        },
        {
            dataField: 'websiteClicks',
            title: 
                <div className="local-seo-table-title-container">
                    <span className="local-seo-table-title-span">
                        <WebsiteClicksIcon/>
                        <span className="navigation-text">
                            {t('columns.websiteClicks')}
                        </span>  
                    </span>
                </div>,
            size: 12,
            formatter: (cellValue, row) => (
                <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                    <span className="local-seo-table-various-spans-number">{row.websiteClicks.value}</span>
                    {(row.websiteClicks.variationPercentage !== 0) && (
                        <UxPerformanceIndicator value={row.websiteClicks.variationPercentage} />
                    )}
                </div>
            )
            
        },
        {
            dataField: 'details',
            title: '',
            size: 3,    
            formatter: (cellValue, row) => {
                const handleRedirect = () => {
                    console.log('row de la store', row);
                    handleCreateStoreObject(row);
                    setIsModalOpen(true);
                };

                return (
                    <button type="button" className="local-seo-table-details-button" onClick={handleRedirect}>
                        <ChartArrowIcon />
                    </button>
                )
            }
            
            
        },
      

    ]

    return (
        <>
            <div className='local-seo-table-container'>
                <div className='local-seo-table-header-container'>
                    <div style={{display: 'flex', alignItems: 'center', gap: '10px', width: 227, height:57}}>
                        <GoogleMapsIcon />
                        <h2 className="local-seo-table-title">{t('title')}</h2>
                        
                    </div>
                    
                </div>
                <div className="local-seo-table-body-and-pagination-container">
                    <Table
                        pagination={false}
                        getData={getTableData}
                        filterValues={filterValues}
                        columns={columns}
                        showMobileColumnTitles={false}
                        dataPath={"data.data.locationRankingAndMetrics"}
                    />
                    <div className="pagination-container">
                        <UxPagination
                            totalPages={totalPages}
                            // las paginas de la api son 0-based, por eso le sumo 1. (el paginador es 1-based)
                            currentPage={filterValues.page + 1}
                            onPageChange={handlePageChange}
                            />
                    </div>
                    
                </div>
                <div className="local-seo-table-modal-container">
                    <LocalSeoStoreChartModal
                        store={selectedStore}
                        isOpen={isModalOpen}
                        toggle={toggleModal}
                        selectedPeriod={period}
                        t={t}
                    />
                </div>
            </div>
        </>
    )
}

localSEOTable.translations = {
    title: {
        en: "Google Maps Ranking",
        es: "Ranking Google Maps"
    },
    timePeriod: {
        label: {
          es: "Período de tiempo",
          en: "Time period"
        },
        options: {
            TODAY: {
                es: "Hoy",
                en: "Today"
            },
            YESTERDAY: {
                es: "Ayer",
                en: "Yesterday"
            },
            LAST_24_HOURS: {
                es: "Últimas 24 horas",
                en: "Last 24 hours"
            },
            LAST_7_DAYS: {
                es: "Últimos 7 días",
                en: "Last 7 days"
            },
            LAST_30_DAYS: {
                es: "Últimos 30 días",
                en: "Last 30 days" 
            },
            THIS_MONTH: {
                es: "Este mes",
                en: "This month"
            },
            LAST_3_MONTHS: {
                es: "Últimos 3 meses",
                en: "Last 3 months"
            },
            LAST_6_MONTHS: {
                es: "Últimos 6 meses",
                en: "Last 6 months"
            },
            LAST_12_MONTHS: {
                es: "Últimos 12 meses", 
                en: "Last 12 months"
            },
            THIS_YEAR: {
                es: "Este año",
                en: "This year"
            }
        }
    },
    columns: {
        stores: {
          es: "Locales",
          en: "Stores"
        },
        ranking: {
          es: "Ranking promedio",
          en: "Average Ranking",
        },
        gbpAuditScore: {
          es: "Puntaje GBP Audit",
          en: "GBP Audit Score",
        },
        navigation: {
            es: "Navegación",
            en: "Navigation",
        },
        navigation12Months: {
          es: "Navegación (12 meses atrás)",
          en: "Navigation (12 months ago)",
        }, 
        calls: {
            es: "Llamadas",
            en: "Calls",
        },
        visits: {
            es: "Visitas",
            en: "Visits",
        },
        websiteClicks: {
            es: "Website Clicks",
            en: "Website Clicks"
        }
    }
}

export default translate(localSEOTable);


import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import translate from "../../config/translations/translate";
import ReputationTable from './reputationTable/ReputationTable'
import LocalSEOTable from './localSEOTable/localSEOTable';
import { LocalSeoStoreView } from './localSeoStoreView/LocalSeoStoreView';
import './DashboardV2Container.scss';
import { DashboardPeriodSelector } from './dashboardPeriodSelector/DashboardPeriodSelector';
import { DashboardStoreSelector } from './dashboardStoreSelector/DashboardStoreSelector';
import {UxSectionHeaderH3} from "botbit-ui-components";
import { LocalSEOTableIcon, ReputationTableIcon } from './DashboardV2ContainerIcons';
import { ReputationStoreView } from './reputationStoreView/ReputationStoreView';
import GlobalMetrics from './globalMetricsContainer/globalMetrics';
import ActionableSideBar from './actionablesSideBar/ActionablesSideBar'
import { GreenVerticalBarIcon, RedVerticalBarIcon, ArrowIcon } from './actionablesSideBar/ActionablesSideBarIcons';
import { getActionablesSideBarData } from '../../api/dashboardV2Api';

//la ruta para ver este dashboard es /dashboardV2?companyId=455 por ejemplo.
const DashboardV2Container = ({t}) => {
  const selectedCompany = useSelector(state => state.application.selectedCompany);
  const admin = useSelector(state => state.login.admin);
  const availableStores = useSelector(state => state.application.availableStores);
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState('LAST_7_DAYS');
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [actionablesScoreBoardData, setActionablesScoreBoardData] = useState({});
  const [localSEOActionablesList, setLocalSEOActionablesList] = useState([]);
  const [reputationActionablesList, setReputationActionablesList] = useState([]);


  const timePeriodOptions = [
    { value: 'LAST_7_DAYS', label: t('timePeriod.options.LAST_7_DAYS') },
    { value: 'LAST_30_DAYS', label: t('timePeriod.options.LAST_30_DAYS') },
    { value: 'THIS_MONTH', label: t('timePeriod.options.THIS_MONTH') },
    { value: 'LAST_3_MONTHS', label: t('timePeriod.options.LAST_3_MONTHS') },
    { value: 'LAST_6_MONTHS', label: t('timePeriod.options.LAST_6_MONTHS') },
    { value: 'LAST_12_MONTHS', label: t('timePeriod.options.LAST_12_MONTHS') },
    { value: 'THIS_YEAR', label: t('timePeriod.options.THIS_YEAR') }
  ];


  useEffect(() => {
    const filteredStores = availableStores.filter(store => store.companyId === selectedCompany.id);
    setStores(filteredStores);
    setSelectedStore(filteredStores[0]);
   

  }, [availableStores, selectedCompany])


  const handleStoreChange = (event) => {
    const storeId = parseInt(event.target.value);
    const store = stores.find(store => store.id === storeId);
    setSelectedStore(store);
  };


  const resetAndToggleModal = () => {
    setSelectedStore(null);
    setIsModalOpen(!isModalOpen);
  };

  const isCompanyAccess = admin.objectAccess === "COMPANY" || admin.objectAccess === "ALL";
  

  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
  };

  /* 
  esta función recibe la lista entera de accionables y los separa en dos listas de acuerdo al valor de feature,
  además cambia algunos nombres de campos del objeto para que coincida con lo que espera el componente UxCallToActionList
  */
  const processActionablesData = (items) => {
    const localSEO = [];
    const reputation = [];


    items.forEach((item) => {
      
      const transformedItem = {
        title: item.description.es, 
        subtitle: item.linkLabel.es,
        percentage: `+${Math.trunc(item.percentageOfTotalScore)}%`,
        leftIcon: <GreenVerticalBarIcon/>,
        icon: <ArrowIcon />,
        internalRedirect: '',
        externalLink: '',
        openVideoHandler: '',
        ...item,
      };

      if (item.feature === "LOCAL_SEO") {
        localSEO.push(transformedItem);
        console.log("LISTA LOCAL SEO:", localSEO)
      } else {
        reputation.push(transformedItem);
      }
    });

    setLocalSEOActionablesList(localSEO);
    setReputationActionablesList(reputation);
  };

  useEffect(() => {
    const fetchActionablesData = async () => {
      try {
        const response = await getActionablesSideBarData(selectedCompany.id, 'es');
        console.log('RESPUESTA DE LA API:', response);
        console.log("ID DE LA COMPAÑIA", selectedCompany.id);
        setActionablesScoreBoardData(response.data.data.scoreBoard)
        processActionablesData(response.data.data.actionables);

      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    };
  
    fetchActionablesData();
  }, []);

  

  
  return (
    <div className={`dashboard-v2-grid-container ${isCompanyAccess ? 'company-access' : 'store-access'}`}>
      <div className="dashboard-v2-titles-and-tables-and-charts-container">
        <div className="dashboard-v2-header-title-and-kpis-container">
            <div className="dashboard-v2-header-title-container">
              <h2>{t('dashboard.metrics.title')}</h2>
              <h2>{selectedCompany.name}</h2>
            </div>
          <div className="dashboard-v2-header-subtitle-container">
            <h5>{t('dashboard.metrics.subtitle')}</h5>
          </div>
        </div>
        
        {/* caso 1: el admin tiene acceso a la company */}
        {isCompanyAccess && stores.length > 0 && (
          <>
          <div className="dashboard-v2-global-metrics-container">
            <div className="dashboard-v2-global-metrics-header">
              <h2>{t('dashboard.globalMetrics.title')}</h2>
              <div className="dashboard-v2-global-metrics-period-selector-container">
                <DashboardPeriodSelector 
                  timePeriodOptions={timePeriodOptions}
                  t={t}
                selectedPeriod={selectedPeriod}
                handlePeriodChange={handlePeriodChange}
                />
              </div>
            </div>
            <div className="dashboard-v2-global-metrics-content-container">
              <GlobalMetrics companyId={selectedCompany.id} period={selectedPeriod}/>
            </div>
          </div>
            <div className="dashboard-v2-local-seo-table-and-title-container">
              <UxSectionHeaderH3
                icon={<LocalSEOTableIcon />}
                title={t('localSeo.title')}
                subtitle={t('localSeo.subtitle')}
              />
              <div className="dashboard-v2-table-and-period-selector-container">
                  <DashboardPeriodSelector 
                    timePeriodOptions={timePeriodOptions}
                    t={t}
                    selectedPeriod={selectedPeriod}
                    handlePeriodChange={handlePeriodChange}
                  />
                <div className="dashboard-v2-table-container">
                  <LocalSEOTable companyId={selectedCompany.id} period={selectedPeriod}/>
                </div> 
              </div> 
            </div>
            <div className="dashboard-v2-reputation-table-and-title-container">
              <UxSectionHeaderH3
                          icon={<ReputationTableIcon />}
                          title={t('reputation.title')}
                          subtitle={t('reputation.subtitle')}
                          
                      />
              <div className="dashboard-v2-table-and-period-selector-container">
                  <div className="dashboard-v2-reputation-table-period-selector-container">
                    <DashboardPeriodSelector 
                      timePeriodOptions={timePeriodOptions}
                      t={t}
                      selectedPeriod={selectedPeriod}
                      handlePeriodChange={handlePeriodChange}
                    />
                  </div>
                  <div className="dashboard-v2-table-container">
                    <ReputationTable companyId={selectedCompany.id} period={selectedPeriod}/>
                  </div>
              </div>
            </div>
          </>
        )}

        {/* caso 2: el admin no tiene acceso a company */}
        {!isCompanyAccess && selectedStore && (
          <>
          <div className="dashboard-v2-global-metrics-container">
            <div className="dashboard-v2-global-metrics-header">
              <h2>{t('dashboard.globalMetrics.title')}</h2>
                {stores.length > 1 && (
                  <div className="dashboard-v2-global-metrics-store-selector-container">
                    <DashboardStoreSelector
                      stores={stores}
                      selectedStore={selectedStore}
                      handleStoreChange={handleStoreChange}
                      t={t}
                    />
                  </div>
                )}
                <div className="dashboard-v2-global-metrics-period-selector-container">
                  <DashboardPeriodSelector 
                    timePeriodOptions={timePeriodOptions}
                    t={t}
                    selectedPeriod={selectedPeriod}
                    handlePeriodChange={handlePeriodChange}
                  />
              </div>
            </div>
            <div className="dashboard-v2-global-metrics-content-container">
              <GlobalMetrics storeId={selectedStore.id} period={selectedPeriod}/>
            </div>
          </div>
          <div className="dashboard-v2-local-seo-charts-and-title-container">
            <UxSectionHeaderH3
                icon={<LocalSEOTableIcon />}
                title={t('localSeo.title')}
                subtitle={t('localSeo.subtitle')}
              />
              <div className="local-seo-store-view-and-period-selector-and-store-selector-container">
                  {/* 2.1: se agrega selector de store (solo se muestra si hay más de una tienda)*/}
                  {stores.length > 1 && (
                    <DashboardStoreSelector
                      stores={stores}
                      selectedStore={selectedStore}
                      handleStoreChange={handleStoreChange}
                      t={t}
                    />
                  )}
                  {/* 2.2: no se agrega el selector de store */}
                  {/* el selector de periodo y la vista de store es comun para 2.1 y 2.2 */}
                  <DashboardPeriodSelector 
                    timePeriodOptions={timePeriodOptions}
                    t={t}
                    selectedPeriod={selectedPeriod}
                    handlePeriodChange={handlePeriodChange}
                  />
                  <div className="local-seo-store-view-container">
                    <LocalSeoStoreView 
                      store={stores.length === 1 ? stores[0] : selectedStore}
                      selectedPeriod={selectedPeriod}
                    />
                  </div>
              </div>
          </div>
          <div className="dashboard-v2-local-seo-charts-and-title-container">
              <UxSectionHeaderH3
                          icon={<ReputationTableIcon />}
                          title={t('reputation.title')}
                          subtitle={t('reputation.subtitle')}
                      />
              <div className="local-seo-store-view-and-period-selector-and-store-selector-container">
              
                    {stores.length > 1 && (
                    
                      <DashboardStoreSelector
                        stores={stores}
                        selectedStore={selectedStore}
                        handleStoreChange={handleStoreChange}
                        t={t}
                      />
                    )}
                  
                    <DashboardPeriodSelector 
                      timePeriodOptions={timePeriodOptions}
                      t={t}
                      selectedPeriod={selectedPeriod}
                      handlePeriodChange={handlePeriodChange}
                    />
                
                  <div className="local-seo-store-view-container">
                    <ReputationStoreView 
                      store={stores.length === 1 ? stores[0] : selectedStore}
                      selectedPeriod={selectedPeriod}
                    />
                  </div>
              </div>
          </div>
          </>
        )}
      </div>
      
      <div className="dashboard-v2-actionables-container">
         <ActionableSideBar 
            
            localSEOScoreAchieved={Math.trunc(actionablesScoreBoardData.localSeoPercentage)}
            reputationScoreAchieved={Math.trunc(actionablesScoreBoardData.reputationPercentage)}
            localSEOActionablesList={localSEOActionablesList}
            reputationActionablesList={reputationActionablesList}
         />
      </div>
    </div>
  )
}

DashboardV2Container.translations = {
  locationSelector: {
    label: {
      es: "Selecciona una tienda",
      en: "Select a store",
    },
  },
  timePeriod: {
    label: {
      es: "Período de tiempo",
      en: "Time period",
    },
    options: {
      LAST_7_DAYS: {
        es: "Últimos 7 días",
        en: "Last 7 days",
      },
      LAST_30_DAYS: {
        es: "Últimos 30 días",
        en: "Last 30 days",
      },
      THIS_MONTH: {
        es: "Este mes",
        en: "This month",
      },
      LAST_3_MONTHS: {
        es: "Últimos 3 meses",
        en: "Last 3 months",
      },
      LAST_6_MONTHS: {
        es: "Últimos 6 meses",
        en: "Last 6 months",
      },
      LAST_12_MONTHS: {
        es: "Últimos 12 meses",
        en: "Last 12 months",
      },
      THIS_YEAR: {
        es: "Este año",
        en: "This year",
      },
    }
  },
  localSeo: {
    title: {
      es: "LOCAL SEO",
      en: "LOCAL SEO"
    },
    subtitle: {
      es: "Mediante tus palabras claves, puedes posicionarte en google para que tus usuarios te encuentren primero!",
      en: "Through your keywords, you can position yourself on Google so your users find you first!"
    }
  },
  reputation: {
    title: {
      es: "Reputación",
      en: "Reputation"
    },
      subtitle: {
        es: "Que dicen mis clientes sobre mi marca? Mejora el feedback sobre tu producto o servicio!",
        en: "What do my customers say about my brand? Improve the feedback on your product or service!"
    }
  },
  dashboard: {
    metrics: {
      title: {
        es: "Metricas de ",
        en: "Metrics for "
      },
      subtitle: {
        es: "Tus metricas globales para poder tomar acciones en Ray y posicionar tu marca.",
        en: "Your global metrics to take actions in Ray and position your brand."
      }
    },
    globalMetrics: {
      title: {
        es: "Métricas globales configuradas en base a:",
        en: "Global metrics configured based on:"
      }
    }
  }
  
}



export default translate(DashboardV2Container)
import React from "react";
import { UxFileUpload } from "botbit-ui-components";
import { envConfig } from "../../config/config.js";
import { useSelector } from "react-redux";
import { Field } from "formik";
import { Error } from "./Error.js";
import { uploadFile } from "../../api/companyApi.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);
const noImageUrl = envConfig.staticImgUrl + "/stores/no-image.png";

const validateImageDimensions = (file) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    
    img.onload = () => {
      URL.revokeObjectURL(img.src);
            
      if (img.naturalWidth < 400 || img.naturalHeight < 300) {
        reject({
          message: 'Image dimensions must be at least 400x300 pixels',
          type: 'VALIDATION_ERROR'
        });
        return false;
      }
      resolve(file);
    };
    
    img.onerror = () => {
      URL.revokeObjectURL(img.src);
      reject({
        message: 'Error loading image',
        type: 'LOAD_ERROR'
      });
    };
  });
};

function ImageFieldComponent({ field, form, images, t, mapFieldValue, getPreviewSrc }) {
  const company = useSelector((state) => state.application.selectedCompany);
  const imgSrc = getPreviewSrc(field);
  
  const handleFileSelect = async (file) => {
    try {
      await validateImageDimensions(file);
      
      const uploadResult = await uploadFile(file, company.id);      
      form.setFieldValue(
        field.name,
        mapFieldValue(uploadResult.data.data)
      );
      
      return true;
      
    } catch (error) {
      MySwal.fire({
        icon: 'error',
        title: t("panels.socialLocationPost.form.errors.imageValidation.title"),
        text: t("panels.socialLocationPost.form.errors.imageValidation.message"),
        confirmButtonText: t("panels.socialLocationPost.form.errors.imageValidation.confirm")
      });
      
      return false;
    }
  };

  return (
    <>
  
      { 
        <UxFileUpload 
          id="image" 
          maxFileDimensions={"10000x10000"}
          onFileSelect={(file) => 
            uploadFile(file, company.id, 'GOOGLEIMAGE_POST').then((res) => {
              form.setFieldValue(
                field.name,
                mapFieldValue(res.data.data)
              );
            })
          }
        />
      }
    </>
  );
}

export const ImageField = props => <ImageFieldComponent {...props} />;

export const FormikPostImageField = ({ label, images, errors, t }) => {
  return (
    <Field name="media">
      {({
        field,
        form,
      }) => (
        <>
          <ImageFieldComponent
            field={field}
            form={form}
            images={images}
            label={label}
            t={t}
            mapFieldValue={(res) => res.map((selectedImage) => ({ sourceUrl: selectedImage }))}
            getPreviewSrc={field => field.value ? field.value[0].sourceUrl : undefined}
          />
          <Error errors={errors} isSubmitting={form.isSubmitting} />
        </>
      )}
    </Field>
  );
};


export const FormikLocalPageImageField = ({ label, images, errors, t }) => {
  return (
    <Field name="bannerPhoto">
      {({
        field, // { name, value, onChange, onBlur }
        form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      }) => (
        <>
          <ImageFieldComponent
            field={field}
            form={form}
            images={images}
            label={label}
            mode="edit"
            t={t}
            mapFieldValue={(res) => res.map((selectedImage) => (selectedImage.image))[0]}
            getPreviewSrc={field => field.value ? field.value : noImageUrl}
          />
          <Error errors={errors} isSubmitting={form.isSubmitting} />
        </>
      )}
    </Field>
  );
};

export const FormikLocalPageLandingBannerImageField = ({ label, images, errors, t }) => {
  return (
    <Field name="bannerStyle.image">
      {({
        field, // { name, value, onChange, onBlur }
        form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      }) => (
        <>
          <ImageFieldComponent
            field={field}
            form={form}
            images={images}
            label={label}
            mode="edit"
            t={t}
            mapFieldValue={(res) => res.map((selectedImage) => (selectedImage.image))[0]}
            getPreviewSrc={field => field.value ? field.value : noImageUrl}
          />
          <Error errors={errors} isSubmitting={form.isSubmitting} />
        </>
      )}
    </Field>
  );
};
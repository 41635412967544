import React from 'react'
import translate from '../../../config/translations/translate';
import './DashboardPeriodSelector.scss';
export const DashboardPeriodSelector = ({timePeriodOptions, t, selectedPeriod, handlePeriodChange}) => {
  return (
    <div className="period-selector">
      <div className="period-selector-label-container">
        <label className="period-selector-label">{t('timePeriod.label')}</label>
        <select 
          className="period-selector-select" 
          value={selectedPeriod}
          onChange={handlePeriodChange}
        >
          {timePeriodOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

import React from "react";

export const BigRayIcon = () => (
    <svg width="32" height="68" viewBox="0 0 32 68" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M21.4582 16.3861L5.28241 45.5184H13.1413C14.6898 45.5184 15.8566 46.9423 15.5688 48.4807L13.7938 57.9669L26.5669 36.9143H18.6605C17.0041 36.9143 15.8169 35.2985 16.2952 33.6951L21.4582 16.3861ZM24.2287 3.45893C25.674 0.855982 29.6008 2.54516 28.7483 5.40303L20.5081 33.0286H29.0191C30.9503 33.0286 32.1348 35.1683 31.125 36.8327L13.1618 66.4396C11.7269 68.8046 8.11823 67.3954 8.62836 64.6691L11.4847 49.4041H2.9378C1.04913 49.4041 -0.141073 47.3484 0.783488 45.6833L24.2287 3.45893Z" fill="#1276E6"/>
    </svg>

)

export const LittleRayIcon = () => (
    <svg width="14" height="29" viewBox="0 0 14 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M9.33921 6.98822L2.40675 19.4123H5.77482C6.43849 19.4123 6.93856 20.0195 6.81519 20.6756L6.05447 24.7212L11.5287 15.7429H8.14023C7.43033 15.7429 6.92153 15.0538 7.12651 14.37L9.33921 6.98822ZM10.5266 1.47515C11.146 0.365066 12.8289 1.08545 12.4636 2.30425L8.93204 14.0857H12.5796C13.4073 14.0857 13.9149 14.9983 13.4821 15.7081L5.78363 28.3345C5.16866 29.3432 3.6221 28.7422 3.84073 27.5795L5.06486 21.0694H1.40191C0.592486 21.0694 0.0823974 20.1927 0.478638 19.4826L10.5266 1.47515Z" fill="#1276E6"/>
    </svg>

)

export const LocalSEOIcon = () => (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 9.54076V23.4592C0.5 24.3819 1.02152 25.2344 1.86699 25.6947L14.633 32.654C15.4785 33.1153 16.5215 33.1153 17.367 32.654L30.133 25.6947C30.9785 25.2334 31.5 24.3809 31.5 23.4592V9.54076C31.5 8.61808 30.9785 7.76559 30.133 7.30525L17.367 0.346007C16.5215 -0.115336 15.4785 -0.115336 14.633 0.346007L1.86699 7.30525C1.02152 7.76659 0.5 8.61908 0.5 9.54076Z" fill="#23B3BB"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M17.0403 11.9167C17.0403 11.6003 17.3096 11.3438 17.6418 11.3438H22.4535C22.7857 11.3438 23.055 11.6003 23.055 11.9167V16.5C23.055 16.8164 22.7857 17.0729 22.4535 17.0729C22.1213 17.0729 21.8521 16.8164 21.8521 16.5V13.5216L17.5058 18.5815C17.3982 18.7068 17.24 18.7829 17.0703 18.7909C16.9006 18.799 16.7352 18.7383 16.615 18.6238L13.504 15.6604L9.10625 21.4203C8.91087 21.6762 8.5347 21.7327 8.26605 21.5466C7.99741 21.3605 7.93801 21.0022 8.13339 20.7463L12.9451 14.4443C13.0486 14.3088 13.2091 14.2232 13.3845 14.2101C13.5599 14.197 13.7325 14.2576 13.8568 14.3761L16.9957 17.366L21.1843 12.4896H17.6418C17.3096 12.4896 17.0403 12.2331 17.0403 11.9167Z" fill="white"/>
    </svg>    
);

export const ReputationIcon = () => (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 4C0.5 1.79086 2.29086 0 4.5 0H19.5C26.1274 0 31.5 5.37258 31.5 12V29C31.5 31.2091 29.7091 33 27.5 33H12.5C5.87258 33 0.5 27.6274 0.5 21V4Z" fill="#DF5492"/>
        <path d="M13.4817 16.5001C15.3916 16.5001 16.9399 14.8941 16.9399 12.9131C16.9399 10.9321 15.3916 9.32617 13.4817 9.32617C11.5717 9.32617 10.0234 10.9321 10.0234 12.9131C10.0234 14.8941 11.5717 16.5001 13.4817 16.5001Z" fill="white"/>
        <path d="M7.71793 23.674C7.71793 23.674 6.56519 23.674 6.56519 22.4783C6.56519 21.2827 7.71793 17.6957 13.4817 17.6957C19.2454 17.6957 20.3981 21.2827 20.3981 22.4783C20.3981 23.674 19.2454 23.674 19.2454 23.674H7.71793Z" fill="white"/>
        <path d="M19.2454 11.1197C19.2454 10.7895 19.5034 10.5218 19.8218 10.5218H24.4327C24.7511 10.5218 25.0091 10.7895 25.0091 11.1197C25.0091 11.4498 24.7511 11.7175 24.4327 11.7175H19.8218C19.5034 11.7175 19.2454 11.4498 19.2454 11.1197Z" fill="white"/>
        <path d="M19.8218 14.1088C19.5034 14.1088 19.2454 14.3764 19.2454 14.7066C19.2454 15.0368 19.5034 15.3044 19.8218 15.3044H24.4327C24.7511 15.3044 25.0091 15.0368 25.0091 14.7066C25.0091 14.3764 24.7511 14.1088 24.4327 14.1088H19.8218Z" fill="white"/>
        <path d="M22.1273 17.6957C21.8089 17.6957 21.5509 17.9634 21.5509 18.2936C21.5509 18.6237 21.8089 18.8914 22.1273 18.8914H24.4327C24.7511 18.8914 25.0091 18.6237 25.0091 18.2936C25.0091 17.9634 24.7511 17.6957 24.4327 17.6957H22.1273Z" fill="white"/>
        <path d="M22.1273 21.2827C21.8089 21.2827 21.5509 21.5504 21.5509 21.8805C21.5509 22.2107 21.8089 22.4783 22.1273 22.4783H24.4327C24.7511 22.4783 25.0091 22.2107 25.0091 21.8805C25.0091 21.5504 24.7511 21.2827 24.4327 21.2827H22.1273Z" fill="white"/>
    </svg>

)

export const GreenVerticalBarIcon = () => (
	<svg width="4" height="42" viewBox="0 0 4 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" width="3" height="42" rx="1.5" fill="#23B3BB"/>
    </svg>

);

export const RedVerticalBarIcon = () => (
    <svg width="4" height="42" viewBox="0 0 4 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" width="3" height="42" rx="1.5" fill="#DF5492"/>
    </svg>
)

export const ArrowIcon = () => (
	<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M5.14645 1.64645C5.34171 1.45118 5.65829 1.45118 5.85355 1.64645L11.8536 7.64645C12.0488 7.84171 12.0488 8.15829 11.8536 8.35355L5.85355 14.3536C5.65829 14.5488 5.34171 14.5488 5.14645 14.3536C4.95118 14.1583 4.95118 13.8417 5.14645 13.6464L10.7929 8L5.14645 2.35355C4.95118 2.15829 4.95118 1.84171 5.14645 1.64645Z" fill="#9291A5" stroke="#9291A5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

);

export const ChartIcon = () => (
    <svg width="117" height="116" viewBox="0 0 117 116" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M58.5 112.23C58.5 114.312 56.8103 116.013 54.7326 115.878C46.2236 115.324 37.9248 112.899 30.4355 108.758C21.8443 104.008 14.5994 97.1549 9.3793 88.8408C4.15924 80.5268 1.13491 71.0238 0.589559 61.222C0.114157 52.6773 1.5362 44.1493 4.7346 36.2449C5.51558 34.3148 7.78149 33.5319 9.65678 34.4366C11.5321 35.3413 12.3047 37.5907 11.5425 39.5283C8.88779 46.2768 7.71343 53.533 8.11792 60.8031C8.59237 69.3307 11.2235 77.5983 15.765 84.8315C20.3064 92.0648 26.6095 98.0269 34.0839 102.16C40.4561 105.683 47.5018 107.778 54.7334 108.319C56.8097 108.475 58.5 110.148 58.5 112.23Z" fill="#23B3BB"/>
<path d="M10.6797 32.4243C8.84371 31.4424 8.14098 29.1503 9.24011 27.382C13.2254 20.9701 18.4311 15.3873 24.574 10.9572C31.7378 5.79083 39.987 2.32883 48.6922 0.835268C57.3975 -0.6583 66.3288 -0.143971 74.8051 2.33902C82.0733 4.46814 88.8422 7.99651 94.7368 12.7132C96.3626 14.0141 96.464 16.4093 95.0603 17.9471C93.6566 19.4849 91.2802 19.5812 89.6419 18.2962C84.6044 14.345 78.8514 11.3812 72.6854 9.57495C65.3111 7.41474 57.5408 6.96728 49.9672 8.26668C42.3937 9.56609 35.2169 12.578 28.9844 17.0728C23.773 20.831 19.337 25.543 15.9046 30.9473C14.7884 32.7049 12.5157 33.4063 10.6797 32.4243Z" fill="#DF5492"/>
<path d="M96.8464 19.6536C98.3187 18.1813 100.716 18.1737 102.09 19.7384C108.641 27.2024 113.181 36.2416 115.245 45.9993C117.569 56.9893 116.652 68.4177 112.605 78.8964C108.558 89.375 101.555 98.453 92.447 105.028C84.3603 110.865 74.9229 114.506 65.0551 115.628C62.9863 115.864 61.2164 114.247 61.116 112.167C61.0156 110.087 62.6232 108.334 64.6896 108.079C73.0981 107.04 81.1315 103.897 88.0339 98.9141C95.9579 93.1941 102.05 85.2963 105.571 76.1798C109.092 67.0634 109.89 57.1207 107.868 47.5594C106.107 39.2307 102.272 31.5037 96.7445 25.0823C95.3862 23.5042 95.3741 21.1259 96.8464 19.6536Z" fill="#D6D6DD"/>
<path fillRule="evenodd" clipRule="evenodd" d="M63.4582 38.3861L47.2824 67.5184H55.1413C56.6898 67.5184 57.8566 68.9423 57.5688 70.4807L55.7938 79.9669L68.5669 58.9143H60.6605C59.0041 58.9143 57.8169 57.2985 58.2952 55.6951L63.4582 38.3861ZM66.2287 25.4589C67.674 22.856 71.6008 24.5452 70.7483 27.403L62.5081 55.0286H71.0191C72.9503 55.0286 74.1348 57.1683 73.125 58.8327L55.1618 88.4396C53.7269 90.8046 50.1182 89.3954 50.6284 86.6691L53.4847 71.4041H44.9378C43.0491 71.4041 41.8589 69.3484 42.7835 67.6833L66.2287 25.4589Z" fill="#1276E6"/>
</svg>

)

export const QuestionMarkBoldIcon = () => (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 15.5C4.13401 15.5 1 12.366 1 8.5C1 4.63401 4.13401 1.5 8 1.5C11.866 1.5 15 4.63401 15 8.5C15 12.366 11.866 15.5 8 15.5ZM8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5Z" fill="#1A1A1A"/>
        <path d="M5.25511 6.28615C5.24752 6.42237 5.3599 6.53271 5.49634 6.53271H6.32082C6.45889 6.53271 6.56868 6.42013 6.58723 6.28331C6.67618 5.62718 7.1265 5.14893 7.92922 5.14893C8.61477 5.14893 9.24318 5.4917 9.24318 6.31689C9.24318 6.95166 8.86867 7.24365 8.27834 7.68799C7.60549 8.17676 7.07229 8.74805 7.11037 9.6748L7.11334 9.89161C7.11521 10.0283 7.22658 10.1382 7.36332 10.1382H8.17434C8.31241 10.1382 8.42434 10.0263 8.42434 9.88818V9.78271C8.42434 9.06543 8.69729 8.85596 9.43361 8.29736C10.043 7.83398 10.6778 7.31982 10.6778 6.24072C10.6778 4.72998 9.40188 4 8.00539 4C6.73831 4 5.34964 4.59061 5.25511 6.28615ZM6.81203 12.0488C6.81203 12.582 7.23732 12.9756 7.82131 12.9756C8.43068 12.9756 8.84963 12.582 8.84963 12.0488C8.84963 11.4966 8.43068 11.1094 7.82131 11.1094C7.23732 11.1094 6.81203 11.4966 6.81203 12.0488Z" fill="#1A1A1A"/>
    </svg>

)
import React, { useState, useEffect } from 'react';
import { MDBCard, MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon } from 'mdbreact';
import { useSelector } from 'react-redux';
import { getCompany, deleteCompany } from '../../api/companyApi';
import { Actions, RenderMode } from "botbit-ui-components";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import CompanyContainer from './CompanyContainer.js';
import { Modal as MDBModal, ModalHeader as MDBModalHeader } from "botbit-ui-components";
import { useHistory } from "react-router";
import ReactCountryFlag from 'react-country-flag';
import translate from "../../config/translations/translate";
import { translations } from "../../config/translations/Companies/Company";
import { withRouter } from "react-router-dom";
import withEventTracking, {trackers} from "../../config/withEventTracking";
import "./Company.scss"
import { envConfig } from '../../config/config';

const MySwal = withReactContent(Swal);

const ContainerSection = ({ title, children }) => {
	return (
		<MDBRow>
			<div className="company-container-section">
				<h4 className="company-container-section_title">{title}</h4>
				<hr />
				<div className="company-container-section_content">
					{children}
				</div>
			</div>
		</MDBRow>
	)
}

const CompanyField = ({ label, value }) => {
	return (
		<MDBRow>
			<MDBCol size="12" md="4">
				<b>{label}:</b>
			</MDBCol>
			<MDBCol size="12" md="8" className="mb-2">
				<span>{value ? value : "--"}</span>
			</MDBCol>
		</MDBRow>
	)
}

const spreadFieldsInTwoColumns = (formFields, isUserMotu = false) => {
	const fields = formFields.filter(field => field.onlyMOTU ? isUserMotu : true);
	if (fields.length > 3) {
		return (
			<MDBRow>
				<MDBCol size="12" md="6">
					{fields
						.filter((field) => (fields.indexOf(field) < (fields.length / 2)))
						.map(({ label, value }, index) => (
							<CompanyField label={label} value={value} key={index} />
						))
					}
				</MDBCol>
				<MDBCol size="12" md="6">
					{fields
						.filter((field) => (fields.indexOf(field) >= (fields.length / 2)))
						.map(({ label, value }, index) => (
							<CompanyField label={label} value={value} key={index} />
						))
					}
				</MDBCol>
			</MDBRow>
		)
	} else {
		return (<MDBRow>
			<MDBCol size="12" md="6">
				{fields
					.map(({ label, value }, index) => (
						<CompanyField label={label} value={value} key={index} />
					))
				}
			</MDBCol>
		</MDBRow>)
	}
}

const Company = ({ t, trackEvent }) => {
	const [fullCompany, setFullCompany] = useState();
	const [actions, setActions] = useState();
	const [companyFormModalOpen, setCompanyFormModalOpen] = useState(false);
	const [readOnly, setReadOnly] = useState(false);
	const [show, setShow] = useState(true);
	const selectedCompany = useSelector(state => state.application.selectedCompany);
	const isUserMotu = useSelector(state => state.login.admin.objectAccess) === "ALL";
	const hasCompanyEditPermission = useSelector(state => state.login.admin.operations.includes("COMPANIES__EDIT"))
	const token = useSelector(state => state.login.token);
	const admin = useSelector(state => state.login.admin)

	const history = useHistory()

	useEffect(() => {
		if (!show) setShow(true);
	})
	
	useEffect(() => {
		// Evento de mixpanel Configurations_company_view
		trackEvent("Configurations_company_view", {}, false, [trackers.MIXPANEL]);
	}, [])
	
	useEffect(() => {
		if (!companyFormModalOpen) {
			getCompany(selectedCompany.id)
				.then(res => {
					setFullCompany({ ...res.data.data });
				});
		}
	}, [selectedCompany, show, companyFormModalOpen])

	useEffect(() => {
		if (fullCompany) setActions(getActions());
	}, [fullCompany, show])

	const refreshComponent = () => {
		setShow(false);
		setFullCompany(undefined);
		setCompanyFormModalOpen(false);
	}

	const isNumber = (number) => number || number === 0

	const getValueFormShowCustomQuestions = (showCustomQuestionsWhenScoreIsLessOrEqualThan, npsScoreScale) => {
		if (!isNumber(showCustomQuestionsWhenScoreIsLessOrEqualThan)) {
			return t('showCustomQuestionsWhenScoreIsLessOrEqualThan.always')
		}
		if (npsScoreScale === "STARS") {
			if (showCustomQuestionsWhenScoreIsLessOrEqualThan === 0) {
				return t('showCustomQuestionsWhenScoreIsLessOrEqualThan.never')
			}
			if (showCustomQuestionsWhenScoreIsLessOrEqualThan === 5) {
				return t('showCustomQuestionsWhenScoreIsLessOrEqualThan.always')
			}
			return `${showCustomQuestionsWhenScoreIsLessOrEqualThan} ${t('showCustomQuestionsWhenScoreIsLessOrEqualThan.less')}`
		} else {
			if (showCustomQuestionsWhenScoreIsLessOrEqualThan === 0) {
				return t('showCustomQuestionsWhenScoreIsLessOrEqualThan.never')
			}
			if (showCustomQuestionsWhenScoreIsLessOrEqualThan === 10) {
				return t('showCustomQuestionsWhenScoreIsLessOrEqualThan.always')
			}
			return `${showCustomQuestionsWhenScoreIsLessOrEqualThan} ${t('showCustomQuestionsWhenScoreIsLessOrEqualThan.less')}`
		}
	}

	const getActions = () => {
		let actions = [];
		actions.push(
			{
				key: 5,
				color: "",
				icon: "qrcode",
				label: t("qrcode"),
				onClick: () => history.push("/companies/setQr")
			}
		);

		if (hasCompanyEditPermission) {
			actions.push({
				key: 1,
				color: "",
				icon: "edit",
				label: t("editCompany"),
				onClick: () => showUpdateCompanyModal()
			})
		}

		if (isUserMotu) {
			actions.push(
				{
					key: 2,
					color: "",
					icon: "store",
					label: t("addStore"),
					onClick: () => handleAddStore()
				},
				{
					key: 3,
					color: "",
					icon: "list",
					label: t("seeStores"),
					onClick: () => handleViewStores()
				},
				{
					key: 4,
					color: "",
					icon: "trash",
					label: t("deleteCompany"),
					onClick: () => handleDelete()
				}
			);
		}

		return actions;
	}

	// Funciones de las Actions

	const showCreateCompanyModal = () => {
		setCompanyFormModalOpen(true);
		setFullCompany({})
	}

	const showUpdateCompanyModal = (readOnly = false) => {
		setCompanyFormModalOpen(true);
		setReadOnly(readOnly)
	}

	const hideCompanyModal = () => {
		setCompanyFormModalOpen(false);
	}

	const handleDelete = () => {

		MySwal.fire({
			title: t("confirmation"),
			text: t("deleteTheCompany") + selectedCompany.name,
			type: 'warning',
			showCancelButton: true,
			confirmButtonText: t("connfirm"),
			confirmButtonColor: '#1ab394'
		}).then((result) => {
			if (result.value) {
				deleteCompany(selectedCompany.id)
					.then((res) => {
						MySwal.fire({
							title: t("deleted"),
							text: t("deletedText"),
							type: 'success',
							confirmButtonColor: '#1ab394'
						});
					});
			}
		});
	}

	const handleAddStore = () => {
		window.location.href = `${envConfig.frontendPhpUrl}/addStore.php?companyId=${fullCompany.id}&fullCompany=${encodeURI(fullCompany.name)}&countryId=${fullCompany.country}&sso=${token}`
	}

	const handleViewStores = () => {
		window.location.href = `${envConfig.frontendPhpUrl}/listStores.php?companyId=${fullCompany.id}&fullCompany=${encodeURI(fullCompany.name)}&countryId=${fullCompany.country}&sso=${token}`
	}

	return (
		<div className="company-container mt-2">
			<div className="company-container_head">
				<h1 className="page-title">{t("title")}</h1>
				<div className="company-container_actions-row">
					{isUserMotu && <MDBBtn className="btn btn-primary" color="primary" onClick={showCreateCompanyModal}>
						<MDBIcon icon="plus" fas="true" />{"  "}
						{t("createCompany")}
					</MDBBtn>}
					{fullCompany && actions && (
						<span className="company-container_actions"><Actions renderMode={RenderMode.HEADER} className="company-container_header-actions btn-outline-secondary" actions={actions} /></span>
					)}
				</div>
			</div>
			{show && <MDBCard>
				{fullCompany && actions && (
					<>
						<MDBModal isOpen={companyFormModalOpen} fullscreen toggle={hideCompanyModal}>
							<MDBModalHeader toggle={hideCompanyModal}>
								{
									fullCompany && fullCompany.name ?
										fullCompany.name :
										""
								}
							</MDBModalHeader>
							{/* <MDBModalBody> */}
							<CompanyContainer readOnly={readOnly} admin={admin} companyId={fullCompany ? fullCompany.id : undefined} onSubmit={refreshComponent} inline></CompanyContainer>
							{/* </MDBModalBody> */}
						</MDBModal>
					</>
				)}
				{fullCompany && (
					<>
						<MDBContainer className={companyFormModalOpen ? "d-none" : ""}>
							<MDBRow>
								<div className="company-container_header">
									<div className="company-container_logo mb-2">
										<img src={fullCompany.logo} alt="Company Logo" />
									</div>
									<div className="company-container_title mb-2">
										<h2>
											<ReactCountryFlag
												styleProps={{ width: '1.2rem', height: '1.2rem' }}
												code={fullCompany.country === null ? '' : fullCompany.country}
												svg

											/>
											{" " + fullCompany.id} - {fullCompany.name}
										</h2>
									</div>
								</div>
							</MDBRow>
							<>
								<ContainerSection title={t("basicData")}>
									{spreadFieldsInTwoColumns([
										{
											label: t("brand"),
											value: undefined
										},
										{
											label: t("name"),
											value: fullCompany.name
										},
										{
											label: t("language"),
											value: fullCompany.locale
										},
										{
											label: t("country"),
											value: fullCompany.country
										},
										{
											label: t("category"),
											value: fullCompany.category
										},
										{
											label: t("segment"),
											value: fullCompany.companySegmentId,
											onlyMOTU: true,
										},
									], isUserMotu)}
								</ContainerSection>
								<ContainerSection title={t("campaignDeliveryData")}>
									{spreadFieldsInTwoColumns([
										{
											label: t("senderName"),
											value: fullCompany.companyNameForCampaigns
										},
										{
											label: t("fromEmail"),
											value: fullCompany.fromEmail
										},
										{
											label: t("replyTo"),
											value: fullCompany.replyTo
										}
									])}
								</ContainerSection>
								<ContainerSection title={t("socialNetworks")}>
									{spreadFieldsInTwoColumns([
										{
											label: "Facebook",
											value: fullCompany.facebook
										},
										{
											label: "Youtube",
											value: fullCompany.youtube
										},
										{
											label: "Pinterest",
											value: fullCompany.pinterest
										},
										{
											label: "Instagram",
											value: fullCompany.instagram
										},
										{
											label: "Twitter",
											value: fullCompany.twitter
										},
										{
											label: "Restorando",
											value: fullCompany.restorando
										}
									])}
								</ContainerSection>
								<ContainerSection title={t("manualRegistration")}>
									{spreadFieldsInTwoColumns([
										{
											label: t("minDaysBetweenSameUserFeedbacks"),
											value: fullCompany.minDaysBetweenSameUserFeedbacks,
											onlyMOTU: false
										},
										{
											label: t("registrationBgColor"),
											value: <><div className="company-container_color-box" style={{ backgroundColor: fullCompany.registrationBgColor }}></div></>,
											onlyMOTU: false
										},
										{
											label: t("storeSelectorLabel"),
											value: fullCompany.storeSelectorLabel,
											onlyMOTU: false
										}
									], isUserMotu)}
								</ContainerSection>

								<ContainerSection title={t("otherData")}>
									<MDBRow>
										<MDBCol size="12" md="6">
											<div className="company-container_image-container">
												<label>{t("bgForMobile")}</label>
												<a href={fullCompany.bgMobile} target="_blank" rel="noopener noreferrer">
													<img src={fullCompany.bgMobile} alt="bg-mobile" />
												</a>
											</div>
										</MDBCol>
										<MDBCol size="12" md="6">
											<div className="company-container_image-container">
												<label>{t("bgForDesktop")}</label>
												<a href={fullCompany.bgDesktop} target="_blank" rel="noopener noreferrer">
													<img src={fullCompany.bgDesktop} alt="bg-desktop" />
												</a>
											</div>
										</MDBCol>
									</MDBRow>
									{spreadFieldsInTwoColumns([
										{
											label: t("urlPrefix"),
											value: fullCompany.urlPrefix,
											onlyMOTU: true
										},
										{
											label: t("hotbotId"),
											value: fullCompany.hotBotId,
											onlyMOTU: true
										},
										{
											label: t("customNpsQuestion"),
											value: fullCompany.customNpsQuestion,
											onlyMOTU: false
										},
										{
											label: t("npsScoreScale.label"),
											value: t("npsScoreScale." + fullCompany.npsScoreScale),
											onlyMOTU: false
										},
										{
											label: `${t("showCustomQuestionsWhenScoreIsLessOrEqualThan.label")} (${t('npsScoreScale.' + (fullCompany.npsScoreScale || 'NUMBERS'))})`,
											value: `${getValueFormShowCustomQuestions(fullCompany.showCustomQuestionsWhenScoreIsLessOrEqualThan, fullCompany.npsScoreScale)}`,
											onlyMOTU: false
										},
										{
											label: `${t("askUserDataForRegistration.label")}`,
											value: fullCompany.askUserDataForRegistration === null ? t('askUserDataForRegistration.true') : t(`askUserDataForRegistration.${fullCompany.askUserDataForRegistration}`),
											onlyMOTU: false
										}
									], isUserMotu)}
								</ContainerSection>

							</>
						</MDBContainer>
					</>
				)}
			</MDBCard>}
		</div>
	);
}

Company.translations = {
	...translations
}

export default withRouter(withEventTracking(translate(Company)));
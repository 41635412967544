import React from 'react';


export const GoogleMapsIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 22.2034V4.83388C0 4.77393 0.00291168 4.71433 0.00890622 4.65472C0.0147295 4.59512 0.0234645 4.53586 0.0351111 4.47711C0.0467577 4.41837 0.0613159 4.3603 0.0787858 4.3031C0.0960845 4.24572 0.116295 4.18937 0.139074 4.13405C0.162025 4.07873 0.187545 4.02461 0.215805 3.97186C0.244065 3.9191 0.274723 3.86772 0.30795 3.81788C0.341348 3.76821 0.376973 3.72008 0.414824 3.67384C0.452847 3.62742 0.492925 3.58324 0.535401 3.54076C0.577706 3.49846 0.621894 3.45838 0.668138 3.42036C0.714553 3.38233 0.76251 3.34671 0.81235 3.31348C0.86202 3.28008 0.913402 3.24942 0.966154 3.22116C1.01891 3.1929 1.07303 3.16738 1.12818 3.14443C1.1835 3.12148 1.23985 3.10127 1.29705 3.08397C1.35443 3.0665 1.41232 3.05212 1.47107 3.0403C1.52982 3.02865 1.5889 3.01992 1.64851 3.01409C1.70811 3.0081 1.76771 3.00519 1.82766 3.00519H19.1871L0 22.2034Z" fill="#1EA361"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M0 22.5679V22.2032L19.1871 3.00519H19.5514C19.6112 3.00519 19.6709 3.0081 19.7305 3.01392C19.7901 3.01975 19.8492 3.02865 19.908 3.0403C19.9666 3.05195 20.0246 3.0665 20.0818 3.0838C20.1392 3.10127 20.1955 3.12148 20.2507 3.14426C20.306 3.16721 20.3601 3.1929 20.4129 3.22099C20.4656 3.24925 20.517 3.28008 20.5667 3.31331C20.6165 3.34654 20.6645 3.38216 20.7107 3.42018C20.7572 3.45821 20.8013 3.49846 20.8436 3.54076C20.886 3.58307 20.9262 3.62742 20.9642 3.67367C21.0021 3.72008 21.0377 3.76804 21.0709 3.81788C21.1043 3.86772 21.135 3.91893 21.1632 3.97186C21.1913 4.02461 21.217 4.07873 21.2398 4.13405C21.2628 4.18937 21.283 4.24572 21.3003 4.30293C21.3177 4.3603 21.3321 4.41837 21.3439 4.47711C21.3556 4.53586 21.3643 4.59495 21.3701 4.65455C21.3761 4.71416 21.379 4.77393 21.379 4.83388V5.19355L2.19196 24.3918H1.83365C1.77371 24.3918 1.71411 24.3889 1.6545 24.3829C1.5949 24.377 1.53581 24.3683 1.47706 24.3567C1.41832 24.3448 1.36043 24.3305 1.30305 24.313C1.24584 24.2957 1.18949 24.2755 1.13417 24.2525C1.07902 24.2296 1.0249 24.2041 0.972149 24.1758C0.919396 24.1475 0.868014 24.1169 0.818345 24.0835C0.768504 24.0503 0.720548 24.0146 0.674133 23.9766C0.627889 23.9386 0.5837 23.8985 0.541396 23.8562C0.49892 23.8137 0.458842 23.7695 0.420819 23.7231C0.382968 23.6769 0.347343 23.6288 0.313944 23.5791C0.280717 23.5292 0.250059 23.4779 0.221799 23.4251C0.193539 23.3724 0.168019 23.3182 0.145069 23.2629C0.122289 23.2076 0.102079 23.1512 0.0847804 23.0939C0.0673105 23.0367 0.0527523 22.9786 0.0411057 22.9199C0.0294591 22.8611 0.0207241 22.8018 0.0149008 22.7422C0.00890621 22.6826 0.00599457 22.623 0.00599457 22.5631" fill="#FEDB43"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M2.19922 24.3977L10.6961 15.8958L19.1931 24.3977H2.19922Z" fill="#5384C4"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M19.1936 24.3963L10.6965 15.8872L12.8897 13.6927L21.3927 22.2005V22.5652C21.3927 23.5748 20.5735 24.3939 19.5639 24.3939H19.1996L19.1936 24.3963Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M21.3919 22.2016L12.8828 13.6997L21.3799 5.19788L21.3919 22.2016Z" fill="#C2C2C1"/>
      <path d="M3.81957 5.36817C4.90716 4.87062 6.26861 5.07323 7.15118 5.88867L6.31229 6.7202C5.42903 5.84242 3.76853 6.25862 3.35456 7.41985C2.81283 8.56208 3.71287 10.058 4.98629 10.0802C5.82518 10.1774 6.65089 9.61849 6.82576 8.78816C6.24463 8.78936 5.66333 8.78388 5.08802 8.79227L5.07723 7.71496L8.00327 7.75452C8.0764 8.46565 8.02605 9.22097 7.64377 9.84526C7.04448 10.9621 5.62856 11.4496 4.43068 11.1678C2.9495 10.8609 1.84085 9.34034 2.04346 7.83416C2.14708 6.77175 2.86438 5.80885 3.83036 5.36885" fill="#EFEFEF"/>
      <mask id="mask0_9751_5314" masktype="luminance" maskUnits="userSpaceOnUse" x="12" y="0" width="12" height="19">
        <path d="M12.0293 0H23.9993V18.8457H12.0293V0Z" fill="white"/>
      </mask>
      <g mask="url(#mask0_9751_5314)">
        <path d="M17.652 -0.0078125H18.3472C20.0849 0.0916974 21.7728 0.938303 22.8232 2.33059C23.5112 3.21076 23.8947 4.30914 23.981 5.41231V5.82422C23.9408 7.97439 22.5656 9.73937 21.3744 11.4122C20.0381 13.302 18.8026 15.3616 18.4597 17.6909C18.3681 18.0855 18.4777 18.6382 18.0068 18.8164C17.8959 18.73 17.7671 18.6586 17.6891 18.5375C17.5495 17.2353 17.2241 15.9492 16.6694 14.7603C16.0401 13.3812 15.1623 12.1221 14.2712 10.8972C13.7289 10.1621 13.2167 9.40196 12.7936 8.59132C12.1344 7.37648 11.8809 5.9412 12.1524 4.57769C12.3754 3.50158 12.9313 2.50443 13.7175 1.73335C14.7674 0.706398 16.1864 0.084504 17.652 -0.00712741M17.5167 3.78025C16.6957 3.96385 16.0053 4.69159 15.9358 5.54299C15.8495 6.33565 16.3133 7.13978 17.0229 7.48986C17.7922 7.89749 18.8026 7.7716 19.4455 7.19013C20.0459 6.65884 20.2641 5.7415 19.9506 4.9992C19.5791 4.06987 18.4789 3.54115 17.516 3.78812" fill="#DC4B3E"/>
      </g>
      <path d="M17.5175 3.77942C18.4799 3.53963 19.5812 4.06116 19.9521 4.99049C20.2656 5.73279 20.0469 6.65013 19.4471 7.18142C18.8041 7.77009 17.7932 7.90077 17.0244 7.48115C16.3143 7.13107 15.851 6.32694 15.9373 5.53429C16.0069 4.68289 16.6966 3.95566 17.5182 3.77154" fill="#802C27"/>
    </svg>
  )
}


export const NavigationIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.12111 1.93557C6.517 1.73762 6.983 1.73762 7.37889 1.93557L11.1242 3.80823C11.2034 3.84782 11.2966 3.84782 11.3758 3.80823L14.2774 2.35745C15.2124 1.88994 16.3125 2.56985 16.3125 3.61523V12.9786C16.3125 13.5112 16.0116 13.9981 15.5351 14.2363L11.8789 16.0645C11.483 16.2624 11.017 16.2624 10.6211 16.0645L6.87578 14.1918C6.7966 14.1522 6.7034 14.1522 6.62422 14.1918L3.72264 15.6426C2.78763 16.1101 1.6875 15.4302 1.6875 14.3848V5.02148C1.6875 4.48884 1.98844 4.0019 2.46486 3.7637L6.12111 1.93557ZM6.75 4.50001C7.06066 4.50001 7.3125 4.75185 7.3125 5.06251V11.25C7.3125 11.5607 7.06066 11.8125 6.75 11.8125C6.43934 11.8125 6.1875 11.5607 6.1875 11.25V5.06251C6.1875 4.75185 6.43934 4.50001 6.75 4.50001ZM11.8125 6.75001C11.8125 6.43935 11.5607 6.18751 11.25 6.18751C10.9393 6.18751 10.6875 6.43935 10.6875 6.75001V12.9375C10.6875 13.2482 10.9393 13.5 11.25 13.5C11.5607 13.5 11.8125 13.2482 11.8125 12.9375V6.75001Z" fill="#13589D"/>
    </svg>

  )
}

export const CallIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M13.625 6.3125C13.625 6.62316 13.3732 6.875 13.0625 6.875L9.6875 6.875C9.37684 6.875 9.125 6.62316 9.125 6.3125V2.9375C9.125 2.62684 9.37684 2.375 9.6875 2.375C9.99816 2.375 10.25 2.62684 10.25 2.9375V4.9545L13.7898 1.41475C14.0094 1.19508 14.3656 1.19508 14.5852 1.41475C14.8049 1.63442 14.8049 1.99058 14.5852 2.21025L11.0455 5.75L13.0625 5.75C13.3732 5.75 13.625 6.00184 13.625 6.3125Z" fill="#13589D"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M0.125 2.375C0.125 1.13236 1.13236 0.125 2.375 0.125H3.40372C4.049 0.125 4.61148 0.564168 4.76798 1.19018L5.59733 4.50757C5.73456 5.05647 5.52945 5.63416 5.07682 5.97364L4.1066 6.7013C4.00585 6.77686 3.98395 6.88751 4.01225 6.96476C4.86388 9.28961 6.71039 11.1361 9.03524 11.9877C9.11249 12.0161 9.22314 11.9941 9.2987 11.8934L10.0264 10.9232C10.3658 10.4705 10.9435 10.2654 11.4924 10.4027L14.8098 11.232C15.4358 11.3885 15.875 11.951 15.875 12.5963V13.625C15.875 14.8676 14.8676 15.875 13.625 15.875H11.9375C5.41364 15.875 0.125 10.5864 0.125 4.0625V2.375Z" fill="#13589D"/>
    </svg>

  )
}

export const VisitsIcon = () => {
  return (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 9.25C10.2426 9.25 11.25 8.24264 11.25 7C11.25 5.75736 10.2426 4.75 9 4.75C7.75736 4.75 6.75 5.75736 6.75 7C6.75 8.24264 7.75736 9.25 9 9.25Z" fill="#13589D"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M0.992558 6.58502C2.108 3.23178 5.27093 0.8125 9.00039 0.8125C12.7281 0.8125 15.8898 3.22954 17.0067 6.58034C17.0969 6.85114 17.097 7.14413 17.0069 7.41498C15.8915 10.7682 12.7285 13.1875 8.99907 13.1875C5.27135 13.1875 2.10963 10.7705 0.992804 7.41966C0.902548 7.14887 0.902462 6.85587 0.992558 6.58502ZM12.9375 7C12.9375 9.17462 11.1746 10.9375 9 10.9375C6.82538 10.9375 5.0625 9.17462 5.0625 7C5.0625 4.82538 6.82538 3.0625 9 3.0625C11.1746 3.0625 12.9375 4.82538 12.9375 7Z" fill="#13589D"/>
    </svg>

  )
}

export const WebsiteClicksIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9 1.125C9.31066 1.125 9.5625 1.37684 9.5625 1.6875V3.375C9.5625 3.68566 9.31066 3.9375 9 3.9375C8.68934 3.9375 8.4375 3.68566 8.4375 3.375V1.6875C8.4375 1.37684 8.68934 1.125 9 1.125ZM4.22703 3.10203C4.4467 2.88236 4.80286 2.88236 5.02252 3.10203L6.21577 4.29527C6.43544 4.51494 6.43544 4.8711 6.21577 5.09077C5.9961 5.31044 5.63994 5.31044 5.42027 5.09077L4.22703 3.89752C4.00736 3.67785 4.00736 3.3217 4.22703 3.10203ZM13.773 3.10203C13.9926 3.3217 13.9926 3.67785 13.773 3.89752L12.5797 5.09077C12.3601 5.31044 12.0039 5.31044 11.7842 5.09077C11.5646 4.8711 11.5646 4.51494 11.7842 4.29527L12.9775 3.10203C13.1971 2.88236 13.5533 2.88236 13.773 3.10203ZM8.66129 6.47384C8.89516 6.41118 9.14289 6.50519 9.27629 6.70724L13.1967 12.6449C13.322 12.8348 13.3207 13.0814 13.1932 13.2699C13.0658 13.4584 12.8374 13.5515 12.6145 13.5059L11.0415 13.1841L11.8251 16.1083C11.9055 16.4083 11.7274 16.7168 11.4273 16.7972C11.1272 16.8776 10.8188 16.6995 10.7384 16.3994L9.95487 13.4753L8.75378 14.5404C8.58356 14.6913 8.33917 14.7249 8.13457 14.6254C7.92997 14.5259 7.80545 14.3129 7.81908 14.0858L8.24539 6.98347C8.25989 6.74179 8.42742 6.53651 8.66129 6.47384ZM2.25 7.875C2.25 7.56434 2.50184 7.3125 2.8125 7.3125H4.5C4.81066 7.3125 5.0625 7.56434 5.0625 7.875C5.0625 8.18566 4.81066 8.4375 4.5 8.4375H2.8125C2.50184 8.4375 2.25 8.18566 2.25 7.875ZM12.9375 7.875C12.9375 7.56434 13.1893 7.3125 13.5 7.3125H15.1875C15.4982 7.3125 15.75 7.56434 15.75 7.875C15.75 8.18566 15.4982 8.4375 15.1875 8.4375H13.5C13.1893 8.4375 12.9375 8.18566 12.9375 7.875ZM6.21577 10.6592C6.43544 10.8789 6.43544 11.2351 6.21577 11.4547L5.02252 12.648C4.80285 12.8676 4.4467 12.8676 4.22703 12.648C4.00736 12.4283 4.00736 12.0721 4.22703 11.8525L5.42027 10.6592C5.63994 10.4396 5.9961 10.4396 6.21577 10.6592Z" fill="#13589D"/>
    </svg>

  )
}



export const ChartArrowIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9294_2622)">
        <rect width="20" height="20" rx="3" fill="#13589D"/>
        <path 
          fillRule="evenodd" 
          clipRule="evenodd" 
          d="M5 10C5 10.4142 5.33579 10.75 5.75 10.75H12.3879L10.2302 12.7094C9.93159 12.9965 9.92228 13.4713 10.2094 13.7698C10.4965 14.0684 10.9713 14.0777 11.2698 13.7906L14.7698 10.5406C14.9169 10.3992 15 10.204 15 10C15 9.79599 14.9169 9.60078 14.7698 9.45938L11.2698 6.20938C10.9713 5.92228 10.4965 5.93159 10.2094 6.23017C9.92228 6.52875 9.93159 7.00353 10.2302 7.29063L12.3879 9.25L5.75 9.25C5.33579 9.25 5 9.58579 5 10Z" 
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_9294_2622">
          <rect width="20" height="20" rx="3" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export const StarIcon = () => {
  return (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path 
        d="M3.83753 13.5124C3.49939 13.6862 3.11652 13.3824 3.1846 12.9944L3.91061 8.8561L0.828568 5.91904C0.540535 5.64456 0.689635 5.14269 1.0755 5.08788L5.36167 4.47899L7.27277 0.693267C7.44493 0.352244 7.91127 0.352244 8.08343 0.693267L9.99453 4.47899L14.2807 5.08788C14.6666 5.14269 14.8157 5.64456 14.5276 5.91904L11.4456 8.8561L12.1716 12.9944C12.2397 13.3824 11.8568 13.6862 11.5187 13.5124L7.6781 11.5384L3.83753 13.5124Z" 
        fill="#F0B747"
      />
    </svg>
  );
};

export const QuestionMarkIcon = () => {
  return (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.6781 11.25C3.77861 11.25 1.4281 8.8995 1.4281 6C1.4281 3.10051 3.77861 0.75 6.6781 0.75C9.5776 0.75 11.9281 3.10051 11.9281 6C11.9281 8.8995 9.5776 11.25 6.6781 11.25ZM6.6781 12C9.99181 12 12.6781 9.31371 12.6781 6C12.6781 2.68629 9.99181 0 6.6781 0C3.36439 0 0.678101 2.68629 0.678101 6C0.678101 9.31371 3.36439 12 6.6781 12Z" fill="#9291A5"/>
      <path d="M4.61943 4.33961C4.61374 4.44177 4.69803 4.52454 4.80035 4.52454H5.41872C5.52227 4.52454 5.60461 4.4401 5.61852 4.33748C5.68524 3.84539 6.02298 3.48669 6.62502 3.48669C7.13918 3.48669 7.61049 3.74377 7.61049 4.36267C7.61049 4.83875 7.32961 5.05774 6.88686 5.39099C6.38222 5.75757 5.98232 6.18604 6.01088 6.8811L6.01311 7.04371C6.01451 7.14625 6.09804 7.22864 6.20059 7.22864H6.80885C6.91241 7.22864 6.99635 7.14469 6.99635 7.04114V6.96204C6.99635 6.42407 7.20107 6.26697 7.75331 5.84802C8.21034 5.50049 8.68642 5.11487 8.68642 4.30554C8.68642 3.17249 7.72951 2.625 6.68214 2.625C5.73183 2.625 4.69033 3.06796 4.61943 4.33961ZM5.78712 8.66162C5.78712 9.06152 6.10609 9.35669 6.54408 9.35669C7.00111 9.35669 7.31532 9.06152 7.31532 8.66162C7.31532 8.24744 7.00111 7.95703 6.54408 7.95703C6.10609 7.95703 5.78712 8.24744 5.78712 8.66162Z" fill="#9291A5"/>
    </svg>
  );
};

export const QuestionMarkBoldIcon = () => {
    return (<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z" fill="#1A1A1A"/>
        <path d="M5.25511 5.78615C5.24752 5.92237 5.3599 6.03271 5.49634 6.03271H6.32082C6.45889 6.03271 6.56868 5.92013 6.58723 5.78331C6.67618 5.12718 7.1265 4.64893 7.92922 4.64893C8.61477 4.64893 9.24318 4.9917 9.24318 5.81689C9.24318 6.45166 8.86867 6.74365 8.27834 7.18799C7.60549 7.67676 7.07229 8.24805 7.11037 9.1748L7.11334 9.39161C7.11521 9.52833 7.22658 9.63818 7.36332 9.63818H8.17434C8.31241 9.63818 8.42434 9.52625 8.42434 9.38818V9.28271C8.42434 8.56543 8.69729 8.35596 9.43361 7.79736C10.043 7.33398 10.6778 6.81982 10.6778 5.74072C10.6778 4.22998 9.40188 3.5 8.00539 3.5C6.73831 3.5 5.34964 4.09061 5.25511 5.78615ZM6.81203 11.5488C6.81203 12.082 7.23732 12.4756 7.82131 12.4756C8.43068 12.4756 8.84963 12.082 8.84963 11.5488C8.84963 10.9966 8.43068 10.6094 7.82131 10.6094C7.23732 10.6094 6.81203 10.9966 6.81203 11.5488Z" fill="#1A1A1A"/>
    </svg>
  );
};

import React from "react";
import Icons from "../Icons/Icons";

const AlertLocalInsignia = ({ t, openModalInsignia }) => {
  return (
    <div className="container-alert-insignia">
      <div className="mini-container gap-4">
        <div className="container-icon">
          {Icons.svgLocalInsigniaIcon({ width: 24, height: 24 })}
        </div>
        <p className="mb-0 alert-title-insignia">
          {t("alertInsigniaNoApplied.textNormal")}{" "}
          <span className="font-bold">
            {t("alertInsigniaNoApplied.textBold")}
          </span>
          {t("alertInsigniaNoApplied.textNormal2")}
          <span className="font-bold">
            {t("alertInsigniaNoApplied.textBold2")}
          </span>{" "}
          {t("alertInsigniaNoApplied.textNormal3")}
        </p>
      </div>
      <button className="button-alert-insignia" onClick={openModalInsignia}>
        {t("alertInsigniaNoApplied.textButton")}
      </button>
    </div>
  );
};

export default AlertLocalInsignia;

// Versión con ambos estados (para futuro uso)
// const AlertLocalInsignia = ({ insigniaWasApplied, t, openModalInsignia }) => {
//   return (
//     <div
//       className={`${
//         insigniaWasApplied
//           ? "container-alert-insignia-success"
//           : "container-alert-insignia"
//       }`}
//     >
//       <div className="mini-container gap-4">
//         <div className="container-icon">
//           {Icons.svgLocalInsigniaIcon({ width: 24, height: 24 })}
//         </div>
//         {insigniaWasApplied ? (
//           <p className="mb-0 alert-title-insignia">
//             {t("alertInsigniaApplied.textNormal")}{" "}
//             <span className="font-bold">
//               {t("alertInsigniaApplied.textBold")}
//             </span>{" "}
//             <br></br>
//             {t("alertInsigniaApplied.textNormal2")}
//             <span className="font-bold">
//               {t("alertInsigniaApplied.textBold2")}
//             </span>
//           </p>
//         ) : (
//           <p className="mb-0 alert-title-insignia">
//             {t("alertInsigniaNoApplied.textNormal")}{" "}
//             <span className="font-bold">
//               {t("alertInsigniaNoApplied.textBold")}
//             </span>
//             {t("alertInsigniaNoApplied.textNormal2")}
//             <span className="font-bold">
//               {t("alertInsigniaNoApplied.textBold2")}
//             </span>{" "}
//             {t("alertInsigniaNoApplied.textNormal3")}
//           </p>
//         )}
//       </div>
//       {!insigniaWasApplied && (
//         <button className="button-alert-insignia" onClick={openModalInsignia}>
//           {t("alertInsigniaNoApplied.textButton")}
//         </button>
//       )}
//     </div>
//   );
// };

// export default AlertLocalInsignia;

import React, {useEffect} from 'react';
import { Form, Formik, Field } from 'formik';
import { MDBBtn, MDBContainer, MDBInput } from 'mdbreact';
import { translations } from "../../config/translations/Admins/AdminInvite";
import translate from "../../config/translations/translate";
import "./AdminInvite.scss"
import { inviteAdmin } from '../../api/administratorApi';
import * as Yup from "yup";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useSelector } from 'react-redux';
import withEventTracking, {trackers} from '../../config/withEventTracking';

const MySwal = withReactContent(Swal);

const UnWrappedAdminInvite = ({ t, embedded, toggle, triggerOpenModal, trackEvent }) => {
    const selectedCompany = useSelector(state => state.application.selectedCompany);
    const objectAccess = useSelector(state => state.login.admin.objectAccess)
    const adminOperations = useSelector(state => state.login.admin.operations)
    const companyGoal = useSelector(state => state.application.selectedCompany.goal);

    const schema = Yup.object().shape({
        email: Yup.array()
            .transform(function (value, originalValue) {
                if (this.isType(value) && value !== null) {
                    return value;
                }
                return originalValue ? originalValue.split(/[\s,]+/) : [];
            })
            .of(Yup.string().email(({ value }) => t("invalidEmail", { value })))
            .required(t("fieldIsRequired")),
        role: Yup.string().matches(/STAFF|EMPLOYER/i, t("fieldIsRequired")).required(t("fieldIsRequired"))
    })

    let companyHasConversationGoal = companyGoal === "organizeConversations";
    let adminHasAssignerOperation = adminOperations.includes("CONVERSATION__ASSIGN") || objectAccess === "ALL";
    let adminHasReplierOperation = adminOperations.includes("CONVERSATION__REPLY") || objectAccess === "ALL";

    useEffect(() => {
        // Evento de mixpanel Configurations_invite_user
        trackEvent("Configurations_invite_user", {}, false, [trackers.MIXPANEL]);
    }, [])

    return (
        <div className={`admin-invite${embedded ? " embedded" : " page-view"}`}>
            <div className="admin-invite_container">
                {!embedded && <h2 className="admin-invite_title">
                    {t("title")}
                </h2>}
                {<Formik
                    initialValues={{
                        email: "",
                        role: "STAFF",
                        conversationRole: "CONVERSATION_NONE"
                    }}
                    validationSchema={schema}
                    onSubmit={(values, actions) => {
                        const { email, role, conversationRole } = values;
                        const emailsArr = email
                            .split(",")
                            .map(email => email.trim())
                            .filter(email => email !== "");

                        for (var invitation = 0, len = emailsArr.length; invitation < len; invitation++) {
                            trackEvent("user_invited", { role: role }, invitation === 0);
                        }
                        const body = {
                            companyId: selectedCompany.id,
                            emails: [...emailsArr],
                            role,
                            conversationRole
                        }
                        inviteAdmin(body)
                            .then(res => {
                                if (res.status === 200) {
                                    if (embedded) toggle();
                                    MySwal.fire({
                                        // title: t("swalTitle"),
                                        type: "success",
                                        text: t("swalText"),
                                        confirmButtonText: t("inviteUsers"),
                                        showCloseButton: true
                                    })
                                        .then(result => {
                                            if (result.value) {
                                                actions.resetForm();
                                                if (embedded) triggerOpenModal();
                                            }
                                        })
                                }
                            })
                    }}
                >
                    {({ values, setFieldValue }) => (
                        <Form data-testid="admin-invite-form">
                            <p className="helperMsg">{t("generalHelperMsg")}</p>
                            <Field name="email">
                                {({ field, form }) => (
                                    <>
                                        <MDBInput
                                            name="email"
                                            data-intercom-target="invite_users_emails"
                                            id="email"
                                            // type="email"
                                            value={field.value}
                                            label={t("email")}
                                            getValue={value => {
                                                setFieldValue("email", value)
                                            }}
                                        />
                                        {form.errors[field.name] && form.touched[field.name] && <span className="error-msg">{form.errors[field.name]}</span>}
                                        <p className="helperMsg">{t("emailHelperMsg")}</p>

                                    </>
                                )}
                            </Field>
                            {(objectAccess === "ALL" || objectAccess === "COMPANY") &&
                                <Field name="role">
                                    {({ field, form }) => (
                                        <>
                                            <MDBContainer className="role-container" data-intercom-target="invite_users-profile">
                                                <MDBInput
                                                    id="staff"
                                                    checked={field.value === "STAFF"}
                                                    label={t("staff")}
                                                    type="radio"
                                                    onClick={() => {
                                                        setFieldValue("role", "STAFF")
                                                    }}
                                                />
                                                <MDBInput
                                                    id="employer"
                                                    checked={field.value === "EMPLOYER"}
                                                    label={t("employer")}
                                                    type="radio"
                                                    onClick={() => {
                                                        setFieldValue("role", "EMPLOYER")
                                                    }}
                                                />
                                            </MDBContainer>
                                            {form.errors[field.name] && form.touched[field.name] && <span className="error-msg">{form.errors[field.name]}</span>}
                                            <p className="helperMsg">{t("roleHelperMsg")} <a href="https://help.botbit.io/es/articles/5016474-como-invitar-usuarios" target="_blank" rel="noopener noreferrer">{t("clickHere")}</a></p>

                                        </>
                                    )}
                                </Field>}
                                {(companyHasConversationGoal && (adminHasAssignerOperation || adminHasReplierOperation)) &&
                                <Field name="conversationRole">
                                    {({ field, form }) => (
                                        <>
                                            <MDBContainer className="role-container" data-intercom-target="invite_users-conversation-profile">
                                                <MDBInput
                                                    id="conversationRoleNone"
                                                    checked={field.value === "CONVERSATION_NONE" || !field.value}
                                                    label={t("conversationRoleNone")}
                                                    type="radio"
                                                    onClick={() => {
                                                        setFieldValue(field.name, "CONVERSATION_NONE")
                                                    }}
                                                />
                                                { adminHasReplierOperation && <MDBInput
                                                    id="conversationRoleReplier"
                                                    checked={field.value === "CONVERSATION_REPLIER"}
                                                    label={t("conversationRoleReplier")}
                                                    type="radio"
                                                    onClick={() => {
                                                        setFieldValue(field.name, "CONVERSATION_REPLIER")
                                                    }}
                                                />}
                                                { adminHasAssignerOperation && <MDBInput
                                                    id="conversationRoleAutoasigner"
                                                    checked={field.value === "CONVERSATION_AUTOASSIGNER"}
                                                    label={t("conversationRoleAutoasigner")}
                                                    type="radio"
                                                    onClick={() => {
                                                        setFieldValue(field.name, "CONVERSATION_AUTOASSIGNER")
                                                    }}
                                                />}
                                                { adminHasAssignerOperation && <MDBInput
                                                    id="conversationRoleAsigner"
                                                    checked={field.value === "CONVERSATION_ASSIGNER"}
                                                    label={t("conversationRoleAsigner")}
                                                    type="radio"
                                                    onClick={() => {
                                                        setFieldValue(field.name, "CONVERSATION_ASSIGNER")
                                                    }}
                                                />}
                                            </MDBContainer>
                                            {form.errors[field.name] && form.touched[field.name] && <span className="error-msg">{form.errors[field.name]}</span>}
                                            <p className="helperMsg">{t("conversationRoleHelperMsg")}</p>

                                        </>
                                    )}
                                </Field>}
                            <MDBBtn
                                className="submit-btn"
                                color="primary"
                                type="submit"
                                data-intercom-target="invite_users_button"
                            >
                                {t("submit")}
                            </MDBBtn>
                        </Form>
                    )}
                </Formik>}
            </div>
        </div>
    );
}

UnWrappedAdminInvite.translations = { ...translations }

export const UnTrackedAdminInvite = translate(UnWrappedAdminInvite);

export default withEventTracking(UnTrackedAdminInvite);

export const formatNumber = (n, decimals = 1) => {
    const type = typeof n;
    if (type === 'number') {
        return n.toFixed(decimals);
    }
    if (type === 'string') {
        return parseFloat(n).toFixed(decimals);
    }
    return n;

}
